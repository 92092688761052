import { Box } from "@mui/material";
import React, { useState } from "react";
import RenewDrawer from "../Common/RenewDrawer";

const LockBoxNew = ({
  sx,
  componentName,
  purchaseCustomText = "Purchase the hub to gain access.",
  reportType = "",
  report_id,
  title,
  toShowText,
  children,
  onclick = false,
  fromHub = undefined,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const propsBasedOnReportType = {
    sample: {
      report_id: report_id,
      title: title,
      drawerOpen: drawerOpen,
      setDrawerOpen: setDrawerOpen,
      typeModal: "request sample",
      fromHub: fromHub,
      componentName: componentName,
    },
    contact: {
      title: "Talk To Us",
      drawerOpen: drawerOpen,
      setDrawerOpen: setDrawerOpen,
      typeModal: "contact",
      fromHub: fromHub,
      componentName: componentName,
    },
    library: {
      report_id: report_id,
      title: title,
      drawerOpen: drawerOpen,
      setDrawerOpen: setDrawerOpen,
      typeModal: "request",
      componentName: componentName,
    }
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 2px 20px 0px rgb(0 0 0 / 28%)",
          borderRadius: "8px",
          background: "#ffffff",
          textAlign: "center",
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: "1rem",
          zIndex: "100",
          width: "auto",
          color: "black",
          ...sx,
        }}
      >
        {children}
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>Get access to unlock {componentName} </div>
          <div
            style={{
              background: "#d32f2f14",
              color: "#d32f2f",
              padding: "2px",
              borderRadius: "5px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!onclick) {
                setDrawerOpen(true);
              } else if (onclick() === false) {
                setDrawerOpen(true);
              } else {
                onclick();
              }
            }}
          >
            Click Here
          </div>
        </div>
      </Box>
      <RenewDrawer {...(propsBasedOnReportType[reportType] || propsBasedOnReportType["contact"])} />
    </>
  );
};

export default LockBoxNew;
