import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useState, useEffect } from "react";
import UserCard from "./UserCard";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Loader from "../Common/Loader";
import { Drawer, TextField, Select, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import API from "../../api/Api";
import { GoShieldCheck } from "react-icons/go";
import { RiSearchLine } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: "24px 32px",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

function UserManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: "Users",
        paragraph: null,
        backButtonData: null,
      },
    });
  }, []);
  const [data, setData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [cName, setCname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [formReady, setFormReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeAccount, setActiveAccount] = useState([]);
  const [canPurchase, setCanPurchase] = useState(true);

  const [isEdit, setIsEdit] = useState(false);

  const { Base_URL } = API;
  const { setNotify, config, userInfo } = useAuth();

  const [errorText, setErrorText] = useState("");


  // const {  } = useAuth();

  const filterData = data?.alias_accounts?.filter((el) =>
    el?.name.toLowerCase().includes(inputVal.toLowerCase().trim())
  );
  async function getUsers() {
    setLoading(true);

    try {
      const response = await axios.get(`${Base_URL}/api/users`, config);

      setData(response?.data.data);
      const accounts = response?.data.data?.alias_accounts?.filter(
        (item) => item.active !== false
      );
      setActiveAccount(accounts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    // Check if all required fields are filled in
    if(isEdit){
      if (email && username && isValid && role) {
        setFormReady(true);
      } else {
        setFormReady(false);
      }
    }else{
      if (email && isValid && role) {
        setFormReady(true);
      } else {
        setFormReady(false);
      }
    }
 
  }, [username, email, role, isValid, isEdit, password, cName]);

  const toggleDrawer = () => {
    setOpen(false);
    setIsEdit(false);
    setUsername("");
    setEmail("");
    setRole("");
    setPhoneNumber("");
    setCountryCode("+91");
    setCname("");
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    // Basic email format validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isEmailValid = emailPattern.test(inputEmail);

    // Domain validation
    const inputDomain = inputEmail.split("@")[1];
    const isDomainValid = userInfo.allowed_domains.some((domain) => domain === "@" + inputDomain);

    if (!isEmailValid) {
      setIsValid(false);
      setErrorText("Please enter a valid email address.");
    } else if (!isDomainValid) {
      setIsValid(false);
      setErrorText(`Email should belong to the ${userInfo.allowed_domains.join(", ")} domain(s).`);
    } else {
      setIsValid(true);
      setErrorText("");
    }
  };

  async function addUser() {
    // handleClose()
    const bodyParameters = {
      email: email,
      name: username,
      report_purchasing_access: canPurchase,
      // mobile: {
      //   country_code: userInfo?.mobile.country_code,
      //   phone_number: userInfo?.mobile.phone_number,
      // },
      account_type: role.toLowerCase(),
      // admin_password: password,
      // company_name: cName,
    };

    try {
      const response = await axios.post(
        `${Base_URL}/api/users/add-alias`,
        bodyParameters,
        config
      );

      if (response?.data.code === 200) {
        handleOpen();
      }
    } catch (error) {
      console.error(error);
      setNotify({
        isOpen: true,
        message: error.response.data?.message || "Some Error occurred",
        type: "error",
        position: "right",
      });
    } finally {
      toggleDrawer();
    }
  }

  const submitEdit = async (e) => {
    e.preventDefault();
    const bodyParameters = {
      email: email,
      name: username,
      report_purchasing_access: canPurchase,
      // mobile: {
      //   country_code: userInfo?.mobile.country_code,
      //   phone_number: userInfo?.mobile.phone_number,
      // },
      account_type: role.toLowerCase(),
    };

    try {
      const response = await axios.patch(
        `${Base_URL}/api/users/action-alias`,
        bodyParameters,
        config
      );

      //   setData(response?.data.data
      if (response?.data.code === 200) {
        setNotify({
          isOpen: true,
          message: response.data?.message,
          type: "success",
          position: "right",
        });
        getUsers();
        // toggleDrawer()
      }
    } catch (error) {
      console.error(error);
      setNotify({
        isOpen: true,
        message: error.response.data?.message || "Some Error occurred",
        type: "error",
        position: "right",
      });
    } finally {
      toggleDrawer();
    }
  };

  const sendInvitation = async (e)=>{
    e.preventDefault();

    const bodyParameters = {
      email: email,
      permissions: role.toLowerCase(),
      url: window.location.origin,
      invitedBy:userInfo?.id,
      invitedByEmail: userInfo?.email,
      report_purchasing_access: canPurchase,
    };

    try {
      const response = await axios.post(
        `${Base_URL}/api/users/invite-alias`,
        bodyParameters,
        config
      );

      if (response?.data.code === 200) {
        handleOpen();
      }
    } catch (error) {
      console.error(error);
      setNotify({
        isOpen: true,
        message: error.response.data?.message || "Some Error occurred",
        type: "error",
        position: "right",
      });
    } finally {
      toggleDrawer();
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addUser();
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    getUsers();
  };

  const handleEditModal = (name, email, role, code, number, canPurchase) => {
    setOpen(true);
    setIsEdit(true);
    setUsername(name);
    setEmail(email);
    setRole(role);
    setPhoneNumber(number);
    setCountryCode(code);
    setCanPurchase(canPurchase);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box maxWidth={"1536px"} mx="auto">
      <Box maxWidth={"1536px"} m={6}>
        {/* <Typography mb={5} fontWeight={'bold'} mt={4} variant="h5">Users</Typography> */}
        {/* <EmailModal open={open} handleClose={handleClose} getUsers={getUsers} /> */}
        <Box display={"flex"} justifyContent={"space-between"} gap={"24px"}>
          <FormControl fullWidth>
            {/* <InputLabel htmlFor="outlined-adornment-amount">Search User</InputLabel>
            <OutlinedInput
              onChange={(e) => setInputVal(e.target.value)}
              value={inputVal}
              sx={{ height: '50px' }}
              id="outlined-adornment-amount"
              startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
              label="Search Users"
            /> */}
            <TextField
              // label="Search"
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              }}
              placeholder="Search Users"
              variant="outlined"
              fullWidth
              onChange={(e) => setInputVal(e.target.value)}
              value={inputVal}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearchLine
                      style={{ fontSize: "24px", color: "#002F75" }}
                    />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
          </FormControl>
          <Button
            variant="contained"
            sx={{
              fontWeight: "bold",
              width: "200px",
              height: "50px",
              backgroundColor: "#189CDE",
              textTransform: "capitalize",
            }}
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
          >
            Add New User
          </Button>
        </Box>
        <Box my={"48px"}>
          {activeAccount?.length >= 1 ? (
            <UserCard
              rows={filterData}
              data={data}
              getUsers={getUsers}
              handleEditModal={handleEditModal}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              You don't have any alias accounts.
            </Box>
          )}
        </Box>

        <Drawer anchor="right" open={open} onClose={toggleDrawer}>
          <div style={{ width: "444px", height: "88%", position: "relative" }}>
            <Stack
              p={"24px"}
              sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)" }}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "800",
                  lineHeight: "25.6px",
                  color: "#191919",
                }}
              >
                {isEdit ? "Edit User" : "Add New User"}
              </Typography>
              <IconButton onClick={toggleDrawer}>
                <CloseIcon />
              </IconButton>
            </Stack>
          { isEdit ? <form
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                height: "100%",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={isEdit ? "column-reverse" : "column"}
              >
                <Box>
                  <Typography
                    sx={{ my: "10px", fontWeight: "600", fontSize: "14px" }}
                  >
                    Name
                  </Typography>
                  <TextField
                    // label="Username"
                    autoComplete="off"
                    placeholder="Enter Name"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                  />
                </Box>
                {/* <Box>
                  <Typography
                    sx={{ my: "10px", fontWeight: "600", fontSize: "14px" }}
                  >
                    Company Name
                  </Typography>
                  <TextField
                    // label="Username"
                    disabled
                    autoComplete="off"
                    placeholder="Enter Company Name"
                    name="username"
                    value={userInfo?.company_name}
                    onChange={(e) => setCname(e.target.value)}
                    fullWidth
                  />
                </Box> */}
                <Box>
                  <Typography
                    sx={{
                      mt: "20px",
                      mb: "7px",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Email
                  </Typography>
                  <TextField
                    autoComplete="off"
                    disabled={isEdit}
                    sx={{
                      background: isEdit ? "#E4E4E4" : "#fff",
                      color: isEdit ? "#878787" : "#000",
                    }}
                    variant="outlined"
                    placeholder="Enter Email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                    error={!isValid}
                    helperText={errorText}
                  />
                </Box>
              </Box>

              <Typography
                sx={{
                  mt: "20px",
                  mb: "7px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Permission
              </Typography>

              <FormControl fullWidth>
                {/* <InputLabel>User Role</InputLabel> */}
                <Select
                  // name="role"
                  displayEmpty
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem sx={{ display: "none" }} value="" disabled>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#878787",
                      }}
                    >
                      Select Permission
                    </Typography>
                  </MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="view only">View Only</MenuItem>
                  <MenuItem value="view & download">View & Download</MenuItem>
                </Select>
              </FormControl>

            <FormControlLabel
              control={<Switch />}
              label="Allowed to Purchase Reports"
              checked={canPurchase}
              onClick={(e) => setCanPurchase(e.target.checked)}
          />

              
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                {/* <hr style={{
                  width: '100%',
                  position: 'absolute',
                  bottom: '9px',
                  right: '0px',
                  border: "none",
                  boxShadow: "0px -2px 8px rgba(0, 0, 0, 1)",
                  height: '1px',
                  marginBottom: "-10px"
                  // backgroundColor: "#333"
                }} /> */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={!formReady}
                  sx={{ textTransform: "capitalize" }}
                  // onClick={handleSubmit}
                  onClick={(e) => {
                    if (isEdit) {
                      submitEdit(e);
                    } else {
                      handleSubmit(e);
                    }
                  }}
                >
                  {isEdit ? "Save" : "Add User"}
                </Button>
              </Box>
            </form>
          :  <form
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            height: "100%",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={isEdit ? "column-reverse" : "column"}
          >
     
      
            <Box>
              <Typography
                sx={{
                  mt: "20px",
                  mb: "7px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Email
              </Typography>
              <TextField
                autoComplete="off"
                disabled={isEdit}
                sx={{
                  background: isEdit ? "#E4E4E4" : "#fff",
                  color: isEdit ? "#878787" : "#000",
                }}
                variant="outlined"
                placeholder="Enter Email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                fullWidth
                error={!isValid}
                helperText={errorText}
              />
            </Box>
          </Box>
          <Typography
            sx={{
              mt: "20px",
              mb: "7px",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            Permission
          </Typography>

          <FormControl fullWidth>
            <Select
              // name="role"
              displayEmpty
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem sx={{ display: "none" }} value="" disabled>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#878787",
                  }}
                >
                  Select Permission
                </Typography>
              </MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="view only">View Only</MenuItem>
              <MenuItem value="view & download">View & Download</MenuItem>
            </Select>
          </FormControl>

        <FormControlLabel
          control={<Switch />}
          label="Allowed to Purchase Reports"
          checked={canPurchase}
          onClick={(e) => setCanPurchase(e.target.checked)}
      />

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
  
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={!formReady}
              sx={{ textTransform: "capitalize" }}
              onClick={(e) => {
                sendInvitation(e);
              }}
            >
              {"Invite User"}
            </Button>
          </Box>
            </form>}
          </div>
        </Drawer>

      </Box>
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={style}
          style={{ height: "376px", padding: "32px 32px 48px 32px" }}
        >
          <Box textAlign={"center"}>
            <GoShieldCheck
              style={{ width: "64px", height: "64px", color: "#189cde" }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "800",
                lineHeight: "25px",
                color: "#191919",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              User Added Successfully!
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "25px",
                color: "#5a5a5a",
                my: 2,
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <span style={{ textTransform: "capitalize" }}>{username} </span>{" "}
              has been successfully added to the system. We've sent a
              verification link to the email address you provided. Once{" "}
              {userInfo?.relationship_manager?.[0]?.username} verifies the
              email, they'll be able to set a new password and access their
              account.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "25px",
                color: "#5a5a5a",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Need any further assistance? Feel free to reach out to our support
              team.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default UserManagement;
