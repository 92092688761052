import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import icons1 from "../../../assets/about/icons1.svg";
import icons2 from "../../../assets/about/icons2.svg";
import icons3 from "../../../assets/about/icons3.svg";
import icons4 from "../../../assets/about/icons4.svg";
import icons5 from "../../../assets/about/icons5.svg";
import icons6 from "../../../assets/about/icons6.svg";
import _ from "lodash";
import { useSelector } from "react-redux";
import LockBox from "../../LockBox";
import { hubTitleWordsModify } from "../../../utils/common";

const PrimaryResearch = ({ researchData, isSubscribed }) => {

  const { heading } = useSelector((state) => state);

  const { primary_insights, key_respondents, primary_research_approach } =
    researchData;

  const [currentResearch, setCurrentResearch] = useState(
    "Primary Research Process"
  );
  const iconCollections = [icons2, icons1, icons3, icons4, icons5, icons6];
  const boxColors = ["#FFEDC7", "#E6E3FC", "#E0EFFA", "#D7F6EB"];
  const tableHeaderCss = {
    fontSize: "14px",
    color: "#2D2D2D",
  };

  return (
    <Box>
      <Box sx={{ textTransform: "capitalize", marginBottom: "2rem" }}>
        {currentResearch === "Primary Research Process" ? (
          <Button
            style={{
              backgroundColor: "#446BA6",
              padding: "8px 24px",
              textTransform: "capitalize",
              marginRight: "1rem",
              borderRadius: "8px",
            }}
            variant="contained"
          >
            Primary Research Process
          </Button>
        ) : (
          //Show only text type button
          <Button
            variant="text"
            sx={{
              color: "#5A5A5A",
              textTransform: "capitalize",
              marginRight: "1rem",
              borderRadius: "4px",
            }}
            onClick={() => {
              setCurrentResearch("Primary Research Process");
            }}
          >
            Primary Research Process
          </Button>
        )}

        {researchData?.experts_speak ? (
          currentResearch === "Experts Speak" ? (
            <Button
              style={{
                backgroundColor: "#446BA6",
                padding: "8px 24px",
                textTransform: "capitalize",
                marginRight: "1rem",
                borderRadius: "8px",
              }}
              variant="contained"
            >
              Experts Speak
            </Button>
          ) : (
            //Show only text type button
            <Button
              variant="text"
              sx={{
                color: "#5A5A5A",
                textTransform: "capitalize",
                marginRight: "1rem",
                borderRadius: "4px",
              }}
              onClick={() => {
                setCurrentResearch("Experts Speak");
              }}
            >
              Experts Speak
            </Button>
          )
        ) : null}
      </Box>
      {currentResearch === "Experts Speak" ? (
        <TableContainer
          sx={{ borderRadius: "8px", position: "relative", minHeight: "450px" }}
        >
          {!isSubscribed && researchData?.experts_speak?.length > 0 && (
            <LockBox
              sx={{
                top: researchData?.experts_speak?.length < 5 ? "55%" : "15%",
                left: "70%",
                zIndex: "5",
              }}
              componentName={"Discussion Summary"}
              fromHub={hubTitleWordsModify(heading)}
            />
          )}
          {!researchData?.experts_speak && !isSubscribed && (
            <LockBox
              sx={{
                top: "50%",
                left: "50%",
                zIndex: "5",
              }}
              componentName={"Discussion Summary"}
              fromHub={hubTitleWordsModify(heading)}
            />
          )}
          <Table
            sx={{
              minWidth: 650,
              border: "1px solid var(--grey-1-background, #ECECEC)",
              borderRadius: "8px",
              minHeight: "450px",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ backgroundColor: "#E0EFFA", ...tableHeaderCss }}
                >
                  Company
                </TableCell>
                <TableCell sx={{ backgroundColor: "#C8E5F9" }} align="left">
                  Expert
                </TableCell>
                <TableCell sx={{ backgroundColor: "#E0EFFA" }} align="left">
                  Expert's Role
                </TableCell>
                <TableCell sx={{ backgroundColor: "#C8E5F9" }} align="left">
                  Discussion Summary
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {researchData?.experts_speak?.map((row, index) => {
                const bgColor = index % 2 === 0 ? "#FFFFFF" : "#F0F7FC";
                let cellData = Object.entries(row).map(([key, value]) => {
                  const isDiscussionSummary =
                    key === "Discussion summary" ||
                    key === "Discussion_Summary" ||
                    key === "discussion_summary" ||
                    key === "discussionSummary";
                  if (key !== "discussion_summary") {
                    return (
                      <TableCell
                        sx={{
                          textTransform: isDiscussionSummary
                            ? "none"
                            : "capitalize",
                          fontWeight: key === "company" ? "bold" : "normal",
                        }}
                        align="left"
                        className={
                          !isSubscribed && isDiscussionSummary ? "blur" : ""
                        }
                      >
                        {isDiscussionSummary ? (
                          <ul>
                            {typeof value === "string"
                              ? value
                              : value?.map((item) => {
                                  return (
                                    <li
                                      key={_.uniqueId()}
                                      style={{ color: "#5A5A5A" }}
                                    >
                                      {item}
                                    </li>
                                  );
                                })}
                          </ul>
                        ) : (
                          _.lowerCase(value)
                        )}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        align="left"
                        className={!isSubscribed ? "blur" : ""}
                      >
                        <ul>
                          {value?.map((item) => {
                            return (
                              <li
                                key={_.uniqueId()}
                                style={{ color: "#5A5A5A" }}
                              >
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </TableCell>
                    );
                  }
                });
                return (
                  <TableRow sx={{ backgroundColor: bgColor }}>
                    {cellData}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>
          <h3 style={{ marginBottom: "1.5rem" }}>Primary Insights</h3>
          <div style={{ display: "flex", marginBottom: "2rem" }}>
            <h4 style={{ color: "#191919" }}>Objective: </h4>{" "}
            <p> Gather first hand insights on the market studied</p>
          </div>
          {primary_insights?.list?.map((item, index) => {
            return (
              <div
                key={_.uniqueId()}
                style={{
                  display: "flex",
                  backgroundColor: "#F0F7FC",
                  padding: "1.5rem",
                  marginBottom: "1.5rem",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRight: `1px solid ${"#189CDE"}`,
                    paddingRight: "24px",
                    color: "#189CDE",
                  }}
                >
                  <span>STEP</span>
                  <div
                    style={{
                      fontSize: "32px",
                      fontWeight: "800",
                    }}
                    id="section1"
                  >
                    0{index + 1}
                  </div>
                </div>
                {/* //Add Horizontal Separator */}

                <div style={{ paddingLeft: "24px" }} id="section2">
                  <h4
                    style={{
                      color: "#191919",
                      paddingBottom: "8px",
                      fontSize: "16px",
                      fontWeight: "800",
                    }}
                  >
                    {item.title}
                  </h4>
                  <p style={{ color: "#5A5A5A" }}>{item.description}</p>
                </div>
              </div>
            );
          })}
          <ul style={{ marginLeft: "1.5rem", marginBottom: "1.5rem" }}>
            {primary_insights?.about_mordor?.map((item, index) => {
              return (
                <li key={_.uniqueId()} style={{ color: "#5A5A5A" }}>
                  {item}
                </li>
              );
            })}
          </ul>

          <h3>Modes of Primary Research</h3>
          {/* Create material Grid for 6 items in single on Large Screen */}
          <Grid
            container
            spacing={2}
            sx={{ width: "100%", marginTop: "1rem", marginLeft: "0.5rem" }}
            columnGap={3}
          >
            {primary_insights?.modes_of_primary_research?.map(
              (childItem, index) => {
                return (
                  <Grid
                    key={_.uniqueId()}
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#F0F7FC",
                      borderRadius: "8px",
                      padding: "1.5rem",
                      marginBottom: "1.5rem",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
                    }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={1.8}
                  >
                    <img
                      width={48}
                      height={48}
                      src={iconCollections[index]}
                      alt="icon"
                      style={{ width: "100%" }}
                    />
                    <p style={{ color: "#5A5A5A" }}>{childItem.title}</p>
                  </Grid>
                );
              }
            )}
          </Grid>

          <h3>Primary Research Approach and Key Respondents</h3>
          <Box>
            <div
              // container
              // rowSpacing={2}
              // columnSpacing={{ xs: 1, sm: 2, md: 4 }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: "1rem",
              }}
            >
              {primary_research_approach?.map((item, index) => {
                const { title, list } = item;
                return (
                  <div
                    key={_.uniqueId()}
                    style={{
                      backgroundColor: boxColors[index],
                      marginBottom: "2rem",
                      padding: "1.8rem",
                      paddingBottom: "1rem",
                      minHeight: "242px",
                      borderRadius: "8px",
                      width: "49%",
                    }}
                    item
                  >
                    <h3
                      style={{
                        textTransform: "capitalize",
                        fontSize: "16px",
                        fontWeight: "800",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {title.toLowerCase()}
                    </h3>
                    <ul
                      style={{
                        listStylePosition: "inside",
                      }}
                    >
                      {list?.map((childItem) => {
                        return (
                          <li
                            key={_.uniqueId()}
                            style={{
                              color: "#5A5A5A",
                              marginLeft: "0.5rem",
                            }}
                          >
                            {childItem}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </Box>
          {key_respondents?.map((item) => {
            const lowerCaseTitle = _.lowerCase(item?.title);
            return (
              <div
                key={_.uniqueId()}
                style={{
                  backgroundColor: "#F0F7FC",
                  marginBottom: "1rem",
                  padding: "2rem",
                  lineHeight: "1.8rem",
                  borderRadius: "8px",
                }}
              >
                <h4
                  style={{
                    color: "#2D2D2D",
                    textTransform: "capitalize",
                    fontFamily: "16px",
                  }}
                >
                  {lowerCaseTitle === "blind open interviews"
                    ? "Blind/ Open Interviews"
                    : lowerCaseTitle}
                </h4>
                <p style={{ color: "#5A5A5A" }}>{item?.description}</p>
              </div>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export default PrimaryResearch;
