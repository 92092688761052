import { useEffect } from "react";
import styles from "./styles.module.css";
import { Tabs, Tab, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ReportBriefDetail from "../../../pages/ReportDetails/ReportBriefDetail";

function ReportLayout({
  children,
  setCurrentTab,
  currentTab,
  fetchData,
}) {
  const dispatch = useDispatch();
  const {
    selectedReportDetail,
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        bannerHeight: "464px",
      },
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainReportLayoutContainer}>
        <section className={styles.topSection}>
          <ReportBriefDetail
            fetchReport={fetchData}
            selectedReportDetail={selectedReportDetail}
          />
        </section>
        <section className={styles.bottomSection}>
          <ReportTabs
            changeTab={setCurrentTab}
            tab={currentTab}
            subscribed={selectedReportDetail?.subscribed}
          />
          {children}
        </section>
      </div>
    </div>
  );
}

export default ReportLayout;

const ReportTabs = ({ changeTab, tab, subscribed }) => {
  const handleChange = (event, newValue) => {
    changeTab(newValue);
  };

  return (
    <Paper className={styles.tabsSection} sx={{ zIndex: "10" }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        centered
        TabIndicatorProps={{
          style: {
            background: "rgba(24, 156, 222, 1)",
          },
        }}
        sx={{
          "& .css-1wf8b0h-MuiTabs-flexContainer": {
            gap: "40px",
            "& > button": {
              textTransform: "none",
              font: "400 16px / 25.6px Avenir Roman",
            },
          },
          "& .css-1aquho2-MuiTabs-indicator": {
            color: "#1a76d2",
            borderColor: "#1a76d2",
            backgroundColor: "#1a76d2",
            height: "2px",
          },
        }}
      >
        <Tab sx={{ textTransform: "capitalize" }} label="Dashboard" />
        <Tab sx={{ textTransform: "capitalize" }} label="Snippet" />
        <Tab sx={{ textTransform: "capitalize" }} label="Company Profile" />
        <Tab
          sx={{ textTransform: "capitalize" }}
          label={`Report ${subscribed === "no" ? "Sample" : ""}`}
        />
        <Tab sx={{ textTransform: "capitalize" }} label="Data Sheet" />
        {/* <Tab label="Visualize" /> */}
      </Tabs>
    </Paper>
  );
};
