import React, { useEffect, useState, useRef , forwardRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import html2PDF from "jspdf-html2canvas";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import styles from "./styles.module.css";
import { setDownloadCredits } from "../../Redux/action";
import API from "../../../api/Api";
import { useAuth } from "../../../context/authContext/Auth";
import mordor_icon from "../../../utils/img/Mi_logo.png";
import gateLogo from "../../../assets/gate.svg";
import searchImg from "../../../assets/search.svg";
import printIcon from "../../../assets/print_icon.svg";
import downloadIcon from "../../../assets/download_icon.svg";
import downloadIconGray from "../../../assets/download_icon_gray.svg";
import LockBox from "../../LockBox";
import Loader from "../../Common/Loader";
import {
  checkAndUpdateDownloadCredit,
  displayNoDownloadCredit,
} from "../../../utils/common";
import { logFileDownloadClick } from "../../../utils/ga";

const removeDuplicatesEntries = (arr) => {
  const seen = new Set(); // To track unique entries
  const modifiedArr = arr.map((company) => {
    // Filter the development_data to remove duplicates
    const uniqueDevelopmentData = company.development_data.filter((entry) => {
      // Create a unique key for each entry based on date, type, and description
      const uniqueKey = `${entry.date}|${entry.type}|${entry.description}|${entry.company_name}`;
      if (seen.has(uniqueKey)) {
        return false; // Duplicate found
      }
      seen.add(uniqueKey); // Add to seen set
      return true; // Keep unique entry
    });
    // Return the company with the modified development_data
    return {
      ...company,
      development_data: uniqueDevelopmentData,
    };
  });
  return modifiedArr;
};

function CompanyRecentDevelopment({ hubId = null, reportId }) {
  const [data, setData] = useState([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [isListPresent, setIsListPresent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [developmentList, setDevelopmentList] = useState([]);
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [selectedDevelopmentList, setSelectedDevelopmentList] = useState([]);

  const pdfRef = useRef();
  const { Base_URL } = API;
  const { config, userInfo } = useAuth();
  const dispatch = useDispatch();
  const { total_credit, remaining_credit } = useSelector((state) => state);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getDevelopmentList(data, isListPresent);
    if (selectedCompanyList?.length) {
      setIsFilterSelected(true);
    } else {
      setIsFilterSelected(false);
    }
  }, [selectedCompanyList]);

  let getData = () => {
    let url = hubId
      ? `${Base_URL}/api/company/recentDevelopmentV2?hubId=${hubId}`
      : `${Base_URL}/api/company/recentDevelopmentV2`;

    setLoading(true);
    axios
      .get(url, config)
      .then((res) => {
        let data = [];
        if (res?.data?.data?.length !== 0) {
          setIsListPresent(true);
          data = res?.data?.data;
          data = removeDuplicatesEntries(data);
        } else {
          setIsListPresent(false);
          data = res?.data?.devlopmentList;
        }
        setData(data);
        getCompanyList(data, isListPresent);
        getDevelopmentList(data, isListPresent);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let getDevelopmentList = (data, isListPresent) => {
    let newDevelopmentList;

    if (isListPresent) {
      if (selectedCompanyList?.length == 0) {
        newDevelopmentList = [];
      } else {
        newDevelopmentList = data?.filter((company) => {
          return selectedCompanyList.includes(company.company_name);
        });

        newDevelopmentList = newDevelopmentList
          ?.map((company) => {
            return company.development_data.map((development) => {
              return development.type;
            });
          })
          .flat(1);
      }
    } else {
      if (selectedCompanyList?.length == 0) {
        newDevelopmentList = [];
      } else {
        newDevelopmentList = data?.filter((company) => {
          return selectedCompanyList.includes(company.company_name);
        });

        newDevelopmentList = newDevelopmentList
          ?.map((company) => {
            return company.type;
          })
          .flat(1);
      }
    }

    newDevelopmentList =
      newDevelopmentList?.length && getUniqueData(newDevelopmentList);
    setDevelopmentList(newDevelopmentList);
  };

  let getCompanyList = (data, isListPresent) => {
    let newCompanyList;
    if (isListPresent) {
      newCompanyList = data?.map((company) => {
        return company.company_name;
      });
    } else {
      newCompanyList = data?.map((list) => {
        return list.company_name;
      });
    }
    newCompanyList = getUniqueData(newCompanyList);
    setCompanyList(newCompanyList);
  };

  let getUniqueData = (arr) => {
    var o = {},
      a = [],
      i,
      e;
    for (i = 0; (e = arr[i]); i++) {
      o[e] = 1;
    }
    for (e in o) {
      a.push(e);
    }
    return a;
  };

  let generatePrint = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: `Recent-Developments`,
    onAfterPrint: async (x) => {
      if (total_credit != null) {
        let credits = await checkAndUpdateDownloadCredit(config);
        dispatch(
          setDownloadCredits(credits.total_credit, credits.remaining_credit)
        );
      }
    },
  });

  let generatePDF = async () => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    let content = await document.getElementById("printableTable");
    html2PDF([content], {
      jsPDF: {
        format: "a4",
      },
      imageType: "image/png",
      margin: {
        top: 18,
        bottom: 14,
        right: 5,
        left: 5,
      },

      watermark: {
        src: mordor_icon,
        handler({ pdf, imgNode, pageNumber, totalPageNumber }) {
          pdf.setTextColor("rgb(90,90,90)");
          pdf.setFontSize(7);

          pdf.text(
            pdf.internal.pageSize.width - 24,
            pdf.internal.pageSize.height - 3,
            `${pageNumber}/${totalPageNumber}`
          );

          pdf.text(5, 12, `Source: Mordor Intelligence`);
          pdf.addImage(
            imgNode,
            "PNG",
            pdf.internal.pageSize.width - 25,
            0,
            20,
            20
          );
        },
      },

      init: function () {
        setPdfLoading(true);
      },
      success: function (pdf) {
        pdf.save("Recent-Developments.pdf");
        setPdfLoading(false);
      },
    });

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);
      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  let filteredData = [];

  if (selectedCompanyList?.length) {
    if (isListPresent) {
      filteredData = [...data]?.filter((company) => {
        if (selectedCompanyList.includes(company.company_name)) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      filteredData = [];
    }
  } else {
    filteredData = [];
  }

  if (selectedDevelopmentList?.length) {
    if (isListPresent) {
      filteredData = filteredData?.map((company) => {
        let compData = { ...company };
        let devData = company.development_data.filter((dev) => {
          return selectedDevelopmentList.includes(dev.type);
        });
        compData.development_data = devData;
        return compData;
      });
    } else {
      filteredData = [];
    }
  }

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <Box
          gap={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              padding: "20px 0 20px 0",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
              margin: "20px",
            }}
          >
            <RDFilter
              name={"Company"}
              list={companyList}
              setData={setSelectedCompanyList}
            />
            <RDFilter
              name={"Development Type"}
              list={developmentList}
              setData={setSelectedDevelopmentList}
            />
          </Box>
          {isListPresent ? (
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                marginBottom: "50px",
                boxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px",
              }}
            >
              {!filteredData?.length ? (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      height: "40vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <img src={gateLogo} />
                    </Box>
                    <Typography>
                      Choose a company to see its recent development
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box className={styles.buttonsContainer}>
                    {userInfo?.account_type !== "view only" && (
                      // (userInfo?.access_type === "full" && userInfo?.viewOnlyFullAccess.includes() || userInfo?.access_type !== "full") &&
                      <>
                        <button
                          onClick={() => {
                            logFileDownloadClick({ fileName: "CompanyRecentDevelopment" }); 
                            generatePDF();
                          }}
                          disabled={pdfLoading}
                          style={{
                            filter: pdfLoading
                              ? "grayscale(1)"
                              : "grayscale(0)",
                            color: pdfLoading
                              ? "rgb(174, 174, 174)"
                              : "rgba(0, 47, 117, 1)",
                            borderColor: pdfLoading
                              ? "rgb(174, 174, 174)"
                              : "rgba(0, 47, 117, 1)",
                            cursor: pdfLoading ? "progress" : "pointer",
                          }}
                        >
                          <img
                            src={pdfLoading ? downloadIconGray : downloadIcon}
                          />
                          Download PDF
                        </button>
                        {total_credit === null && remaining_credit === null ? (
                          <button
                            onClick={() => {
                              generatePrint();
                            }}
                          >
                            <img src={printIcon} />
                            Print
                          </button>
                        ) : null}

                        {total_credit !== null && remaining_credit > 0 ? (
                          <button
                            onClick={() => {
                              window.confirm(`Proceeding further will reduce Download credits by 1, either you print or cancel. 
                  \nDo you want to Proceed ?`) && generatePrint();
                            }}
                          >
                            <img src={printIcon} />
                            Print
                          </button>
                        ) : null}
                      </>
                    )}
                  </Box>

                  <iframe
                    id="ifcontentstoprint"
                    style={{
                      height: "0px",
                      width: "0px",
                      position: "absolute",
                    }}
                  ></iframe>

                  <TableContainer id={"printableTable"}>
                    <Table
                      sx={{
                        minWidth: 650,
                        borderRadius: "5px",
                        overflow: "hidden",
                        fontFamily: "Avenir Roman !important",
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              background: "rgba(224, 239, 250, 1)",
                              width: "20%",
                            }}
                          >
                            <Typography
                              sx={{ fontFamily: "Avenir Roman !important" }}
                              whiteSpace="nowrap"
                            >
                              Company Name
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{
                              background: "rgba(200, 229, 249, 1)",
                              width: "15%",
                            }}
                          >
                            <Typography
                              sx={{ fontFamily: "Avenir Roman !important" }}
                              whiteSpace="nowrap"
                            >
                              Date
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{
                              background: "rgba(224, 239, 250, 1)",
                              width: "20%",
                            }}
                          >
                            <Typography
                              sx={{ fontFamily: "Avenir Roman !important" }}
                              whiteSpace="nowrap"
                            >
                              Type Of Key Development
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{
                              background: "rgba(200, 229, 249, 1)",
                              width: "45%",
                            }}
                          >
                            <Typography
                              sx={{ fontFamily: "Avenir Roman !important" }}
                              whiteSpace="nowrap"
                            >
                              Description
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData?.map((company, i) => {
                          return company?.development_data?.map(
                            (development, index, array) => {
                              return (
                                <TableRow
                                  hover
                                  sx={{ fontFamily: "Avenir Roman !important" }}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      index % 2 == 0
                                        ? "rgba(255, 255, 255, 1)"
                                        : "rgba(240, 247, 252, 1)",
                                    borderTop:
                                      array[index - 1]?.company_name !==
                                      development.company_name
                                        ? "1px solid #9B9898"
                                        : "0px solid #878787",
                                  }}
                                  key={i + index}
                                >
                                  <TableCell
                                    sx={{
                                      fontFamily: "Avenir Roman !important",
                                    }}
                                    style={{
                                      verticalAlign: "top",
                                      border: "none",
                                      color: "rgba(40, 40, 40, 1)",
                                      fontWeight: "bold",
                                      fontFamily: "Avenir Roman !important",
                                    }}
                                  >
                                    {array[index - 1]?.company_name ===
                                    development.company_name
                                      ? ""
                                      : development.company_name}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      fontFamily: "Avenir Roman !important",
                                    }}
                                    style={{
                                      border: "none",
                                      verticalAlign: "top",
                                      color: "rgba(90, 90, 90, 1)",
                                    }}
                                  >
                                    {development.date}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      fontFamily: "Avenir Roman !important",
                                    }}
                                    style={{
                                      border: "none",
                                      fontFamily: "Avenir Roman !important",
                                      verticalAlign: "top",
                                      color: "rgba(90, 90, 90, 1)",
                                    }}
                                  >
                                    {development.type}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      fontFamily: "Avenir Roman !important",
                                    }}
                                    style={{
                                      border: "none",
                                      fontFamily: "Avenir Roman !important",
                                      verticalAlign: "top",
                                      color: "rgba(90, 90, 90, 1)",
                                    }}
                                  >
                                    {development.description}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <PrintableComponent
                    filteredData={filteredData}
                    selectedCompanyList={selectedCompanyList}
                    selectdDevlopmentList={selectedDevelopmentList}
                    developmentList={developmentList}
                    ref={pdfRef}
                  />
                </Box>
              )}
            </Paper>
          ) : (
            AccessDeniedTable(isFilterSelected)
          )}
        </Box>
      )}
    </Box>
  );
}

export default CompanyRecentDevelopment;

export function RDFilter({ name = "", list = [], setData = () => {} }) {
  const [itemList, setItemList] = useState([]);
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [message, setMessage] = useState(
    "Select companies to see development type list"
  );

  useEffect(() => {
    if (list.length) {
      setItemList([...list]);
    } else {
      setItemList([]);
    }
  }, [list]);

  let handleOnSelect = (value) => {
    value = value.trim();
    let selected = [...selectedItems];
    if (selected.includes(value)) {
      selected = selected.filter((elem) => elem !== value);
    } else {
      selected.push(value);
    }
    setSelectedItems(selected);
  };

  let handleTagDelete = (value) => {
    value = value.trim();
    let selected = [...selectedItems];
    if (selected.includes(value)) {
      selected = selected.filter((elem) => elem !== value);
    } else {
      selected.push(value);
    }
    setSelectedItems(selected);
    setData(selected);
  };

  let openOptions = () => {
    setAreOptionsOpen(!areOptionsOpen);
  };

  let handleOnSearch = (event) => {
    let newList = [];
    let query = event.target.value.trim().toLowerCase();
    if (!query) {
      newList = [...list];
    } else {
      newList = [...list].filter((item) => {
        if (item.trim().toLowerCase().includes(query)) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (newList?.length === 0) {
      setMessage("Not Found");
    } else {
      setMessage("Select companies to see development type list");
    }
    setItemList(newList);
  };

  let clearFilters = () => {
    setSelectedItems([]);
    setData([]);
    setAreOptionsOpen(false);
  };

  let applyFilters = () => {
    setAreOptionsOpen(false);
    setData(selectedItems);
  };

  return (
    <Box
      style={{
        flex: "0 1 48%",
        position: "relative",
      }}
    >
      <Typography
        sx={{ fontFamily: "Avenir Roman !important" }}
        style={{ marginBottom: "15px" }}
      >
        {name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid rgba(213, 213, 213, 1)",
          borderRadius: "4px",
          padding: "0 10px ",
          color: "rgba(135, 135, 135, 1)",
          fontSize: "16px",
          height: "50px",
          fontFamily: "Avenir Roman !important",
        }}
      >
        <Box
          className={styles.tagContainer}
          onClick={selectedItems?.length ? null : openOptions}
          style={{
            cursor: selectedItems?.length ? "" : "pointer",
            flex: selectedItems?.length ? "" : "0 1 95%",
          }}
        >
          {selectedItems?.length
            ? selectedItems?.map((elm) => {
                return (
                  <Typography
                    style={{
                      marginRight: "10px",
                    }}
                    className={styles.tag}
                  >
                    <span>{elm}</span>
                    <span
                      className={styles.delete}
                      onClick={() => {
                        handleTagDelete(elm);
                      }}
                    >
                      X
                    </span>
                  </Typography>
                );
              })
            : `Select ${name}`}
        </Box>
        {areOptionsOpen ? (
          <ExpandLess
            onClick={openOptions}
            sx={{
              cursor: "pointer",
              color: "rgba(0, 47, 117, 1)",
            }}
          />
        ) : (
          <ExpandMore
            onClick={openOptions}
            sx={{
              cursor: "pointer",
              color: "rgba(0, 47, 117, 1)",
              marginLeft: "20px",
            }}
          />
        )}
      </Box>
      {areOptionsOpen ? (
        <Box
          sx={{
            boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
            borderRadius: "6px",
            width: "100%",
            position: "absolute",
            background: "#FFFFFF",
            zIndex: "10",
          }}
        >
          <Box
            sx={{
              padding: "15px",
            }}
          >
            <div
              style={{
                border: "1px solid rgba(213, 213, 213, 1)",
                borderRadius: "4px",
                paddingLeft: "12px",
                color: "rgba(135, 135, 135, 1)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                outline: "none",
                height: "50px",
                overflow: "hidden",
              }}
            >
              <img src={searchImg} alt="" style={{ flex: "0 1 3%" }} />
              <input
                placeholder={`Search ${name}`}
                className={styles.searchInput}
                onChange={handleOnSearch}
                disabled={!list.length}
              />
            </div>
          </Box>
          <Box>
            <div className={styles.selectOptions}>
              {itemList.length ? (
                itemList?.sort().map((item) => {
                  return (
                    <FormControlLabel
                      style={{ width: "99.4%" }}
                      onChange={() => {
                        handleOnSelect(item);
                      }}
                      checked={selectedItems.includes(item)}
                      control={<Checkbox />}
                      label={item}
                    />
                  );
                })
              ) : (
                <p
                  style={{
                    color: "rgba(135, 135, 135, 1)",
                    textAlign: "center",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  {message}
                </p>
              )}
            </div>
          </Box>
          <footer className={styles.footer}>
            <button
              className={styles.clearbtn}
              onClick={() => {
                clearFilters();
              }}
            >
              Clear All
            </button>
            <button
              className={styles.applybtn}
              onClick={() => {
                applyFilters();
              }}
            >
              Apply
            </button>
          </footer>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

export function AccessDeniedTable(isFilterSelected) {
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "50px",
        boxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px",
      }}
    >
      {!isFilterSelected ? (
        <Box
          sx={{
            width: "100%",
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <img src={gateLogo} />
          </Box>
          <Typography>
            Choose a company to see its recent development
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <TableContainer>
            <Table
              sx={{
                minWidth: 650,
                borderRadius: "5px",
                overflow: "hidden",
                fontFamily: "Avenir Roman !important",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ background: "rgba(224, 239, 250, 1)", width: "20%" }}
                  >
                    <Typography
                      sx={{ fontFamily: "Avenir Roman !important" }}
                      whiteSpace="nowrap"
                    >
                      Company Name
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ background: "rgba(200, 229, 249, 1)", width: "15%" }}
                  >
                    <Typography
                      sx={{ fontFamily: "Avenir Roman !important" }}
                      whiteSpace="nowrap"
                    >
                      Date
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ background: "rgba(224, 239, 250, 1)", width: "20%" }}
                  >
                    <Typography
                      sx={{ fontFamily: "Avenir Roman !important" }}
                      whiteSpace="nowrap"
                    >
                      Type Of Key Development
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ background: "rgba(200, 229, 249, 1)", width: "45%" }}
                  >
                    <Typography
                      sx={{ fontFamily: "Avenir Roman !important" }}
                      whiteSpace="nowrap"
                    >
                      Description
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ position: "relative" }}>
                <TableRow
                  hover
                  sx={{ fontFamily: "Avenir Roman !important" }}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "rgba(240, 247, 252, 1)",
                    borderTop: "1px solid #9B9898",
                  }}
                  key={1}
                >
                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      verticalAlign: "top",
                      border: "none",
                      color: "rgba(40, 40, 40, 1)",
                      fontWeight: "bold",
                      fontFamily: "Avenir Roman !important",
                    }}
                  >
                    The Kraft Heinz Company
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    20th March 2023
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    Partnership
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    Kraft Heinz Company and BEES announced an expanded
                    partnership to propel the B2B marketplace, with the ambition
                    to unlock 1 million potential new points of sale across
                    LATAM for the Company, specifically to enhance its footprint
                    in Mexico, Colombia and Peru.
                  </TableCell>
                </TableRow>

                <TableRow
                  hover
                  sx={{ fontFamily: "Avenir Roman !important" }}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderTop: "0px solid #878787",
                  }}
                  key={2}
                >
                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      verticalAlign: "top",
                      border: "none",
                      color: "rgba(40, 40, 40, 1)",
                      fontWeight: "bold",
                      fontFamily: "Avenir Roman !important",
                    }}
                  ></TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    1st June 2022
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    Product Innovation
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    Kraft Heinz announced a joint venture with TheNotCompany, a
                    Chilean startup that makes plant-based substitutes. With the
                    ambition to unlock 1 million potential new points of sale
                    across LATAM for the Company, specifically to enhance its
                    footprint in Mexico, Colombia and Peru.
                  </TableCell>
                </TableRow>

                <TableRow
                  hover
                  sx={{ fontFamily: "Avenir Roman !important" }}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "rgba(240, 247, 252, 1)",
                    borderTop: "1px solid #9B9898",
                  }}
                  key={1}
                >
                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      verticalAlign: "top",
                      border: "none",
                      color: "rgba(40, 40, 40, 1)",
                      fontWeight: "bold",
                      fontFamily: "Avenir Roman !important",
                    }}
                  >
                    The Tanmiah Food Company
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    1th July 2023
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    Partnership
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    Tanmiah Food Company and Tyson Foods signed a strategic
                    partnership to expand poultry production capacity. This
                    investment is aligned with Tanmiah's strategic expansion
                    agenda and is expected to result in doubling the company’s
                    production capacity in value-added products.
                  </TableCell>
                </TableRow>

                <TableRow
                  hover
                  sx={{ fontFamily: "Avenir Roman !important" }}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderTop: "0px solid #878787",
                  }}
                  key={2}
                >
                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      verticalAlign: "top",
                      border: "none",
                      color: "rgba(40, 40, 40, 1)",
                      fontWeight: "bold",
                      fontFamily: "Avenir Roman !important",
                    }}
                  ></TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    1st December 2021
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    Agreements
                  </TableCell>

                  <TableCell
                    sx={{ fontFamily: "Avenir Roman !important" }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    Tanmiah Food Company’s wholly owned subsidiary Gulf Brand
                    Fast Food Co. signed a master franchise and development
                    agreement with Popeyes, an international quick service
                    restaurant chain, to diversify across the value chain by
                    offering high-quality food options, including Popeyes
                    Chicken Sandwich made from fresh and locally sourced fresh
                    chicken.
                  </TableCell>
                </TableRow>

                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: "blur(2px)",
                  }}
                >
                  <LockBox
                    sx={{
                      top: "40%",
                      left: "50%",
                      zIndex: "5",
                    }}
                    componentName={"Recent Developments"}
                    purchaseCustomText={"Purchase the hub to gain access."}
                  />
                </Box>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Paper>
  );
}

export const PrintableComponent = forwardRef(({filteredData, selectedCompanyList, selectdDevlopmentList, developmentList}, pdfRef)=>{


  return(
    <div style={{display:"none"}}>
      <div ref={pdfRef} className="print-page">
        <div>
        <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
       <small style={{color:"gray", fontSize:"16px"}}>Source: Mordor Intelligence</small>
       <img width={"34px"} height={"34px"} src={mordor_icon} alt="Logo Of Mordor Intelligence" />
      </Box>
      <h1 
      style={{color:"#449AC6",textAlign:"center", fontSize:"36px", marginBottom:"20px"}}>
      {'Recent Developments'}
      </h1>
      <hr style={{color:'gray', margin:"10px 0"}}/>
      { 
      selectedCompanyList?.length ?
      <>
      <p><span style={{fontWeight:"bold"}}>Companies : </span>
      {selectedCompanyList.join(",  ")}.
      </p>
      <hr style={{color:'gray', margin:"10px 0"}}/>
      </>
      : null
}

{
   selectdDevlopmentList?.length ?
   <>
      <p><span  style={{fontWeight:"bold"}}>Development Types : </span>
      {selectdDevlopmentList.join(", ")}.
      </p>
      <hr style={{color:'gray', margin:"10px 0"}}/>
      </>
      : developmentList?.length ?
      <>
       <p><span  style={{fontWeight:"bold"}}>Development Types : </span>
      {developmentList.join(", ")}.
      </p>
      <hr style={{color:'gray', margin:"10px 0"}}/>
      </>
      : null
}
   
        </div>
        <div style={{marginTop:"40px"}}></div>
    <TableContainer ref={pdfRef} id={"printableTable"}>
    <Table
      sx={{
        minWidth: 650,
        borderRadius: "5px",
        overflow: "hidden",
        fontFamily: "Avenir Roman !important",
      }}
      aria-label="simple table"
    >
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              background: "rgba(224, 239, 250, 1)",
              width: "20%",
            }}
          >
            <Typography
              sx={{ fontFamily: "Avenir Roman !important" }}
              whiteSpace="nowrap"
            >
              Company Name
            </Typography>
          </TableCell>

          <TableCell
            sx={{
              background: "rgba(200, 229, 249, 1)",
              width: "15%",
            }}
          >
            <Typography
              sx={{ fontFamily: "Avenir Roman !important" }}
              whiteSpace="nowrap"
            >
              Date
            </Typography>
          </TableCell>

          <TableCell
            sx={{
              background: "rgba(224, 239, 250, 1)",
              width: "20%",
            }}
          >
            <Typography
              sx={{ fontFamily: "Avenir Roman !important" }}
              whiteSpace="nowrap"
            >
              Type Of Key Development
            </Typography>
          </TableCell>

          <TableCell
            sx={{
              background: "rgba(200, 229, 249, 1)",
              width: "45%",
            }}
          >
            <Typography
              sx={{ fontFamily: "Avenir Roman !important" }}
              whiteSpace="nowrap"
            >
              Description
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredData?.map((company, i) => {
          return company?.development_data?.map(
            (development, index, array) => {
              return (
                <TableRow
                  hover
                  sx={{ fontFamily: "Avenir Roman !important" }}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      index % 2 == 0
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(240, 247, 252, 1)",
                    borderTop:
                      array[index - 1]?.company_name !==
                      development.company_name
                        ? "1px solid #9B9898"
                        : "0px solid #878787",
                  }}
                  key={i}
                >
                  <TableCell
                    sx={{
                      fontFamily: "Avenir Roman !important",
                    }}
                    style={{
                      verticalAlign: "top",
                      border: "none",
                      color: "rgba(40, 40, 40, 1)",
                      fontWeight: "bold",
                      fontFamily: "Avenir Roman !important",
                    }}
                  >
                    {array[index - 1]?.company_name ===
                    development.company_name
                      ? ""
                      : development.company_name}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontFamily: "Avenir Roman !important",
                    }}
                    style={{
                      border: "none",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    {development.date}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontFamily: "Avenir Roman !important",
                    }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    {development.type}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontFamily: "Avenir Roman !important",
                    }}
                    style={{
                      border: "none",
                      fontFamily: "Avenir Roman !important",
                      verticalAlign: "top",
                      color: "rgba(90, 90, 90, 1)",
                    }}
                  >
                    {development.description}
                  </TableCell>
                </TableRow>
              );
            }
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  </div>
  </div>
  )

})