import { memo, useEffect } from "react";
import { useReportsContext, getReports } from "../STATE";
import styles from "../styles.module.css";
import Loader from "../../../components/Common/Loader";
import HubsReportCardForReports from "../../../components/HubsComponents/HubsReportCardForReports";
import SynapseReportsCard from "../../../components/HubsComponents/SynapseReportsCard";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

function Catalogue() {
  const { state, dispatch } = useReportsContext();
  const { userData } = useSelector(state => state);

  const getReportData = async () => {
    dispatch({ type: `SET_${state.currentTab}_LOADING`, payload: "PENDING" });
    await getReports(state, dispatch);
  };

  useEffect(() => {
    getReportData();
  }, []);

  return (
    <div className={styles.myReportsMainContainer}>
      <section>
        {state.catalogueLoading === "PENDING" ? (
          <Loader />
        ) : state.catalogueReports.length === 0 ? (
          <center>No Reports Found</center>
        ) : (
          state.catalogueReports.map((report, i) => {
            if (report.hasOwnProperty("synapse_report_id")) {
              return (
                <Box position="relative">
                  <SynapseReportsCard
                    key={i}
                    {...report}
                    currentTab={state.currentTab}
                    report_id={report.synapse_report_id}
                    getReportData={() => getReportData(report.synapse_report_id)}
                    canPurchase={userData?.report_purchasing_access}
                  />
                </Box>
              );
            } else {
              return (
                <HubsReportCardForReports
                  key={i}
                  {...report}
                  currentTab={state.currentTab}
                  report_id={report.mi_reports_id}
                  getReportData={() => getReportData(report.id)}
                  canPurchase={userData?.report_purchasing_access}
                />
              );
            }
          })
        )}
      </section>
    </div>
  );
}

export default memo(Catalogue);
