import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { useState, useEffect } from "react";
import { Box, CircularProgress, Tooltip, Button } from "@mui/material";
import { authenticateProdgain, formatDate, formatMonth } from "../../utils/common";
import mailImg from "../../assets/mail_icon.svg";
import downloadImg from "../../assets/download_icon.svg";
import styles from "./styles.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAuth } from "../../context/authContext/Auth";
import {
  SET_ONDEMAND_CREDITS,
  SET_LIBRARY_CREDITS,
} from "../../components/Redux/action";
import { Modal, Button as ButtonX } from "antd";
import axios from "axios";
import API, { SYNAPSE_API } from "../../api/Api";
import ChatBox from "../../components/Common/ChatBox";
import RenewDrawer from "../../components/Common/RenewDrawer";

const requestedTagStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "8px 12px",
  borderRadius: "6px",
  backgroundColor: "rgba(0,255,0, .25)",
  color: "rgb(8, 71, 8)",
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  // Capitalize the first letter ignoring the 'and' in the string
  return string.replace(/\w\S*/g, function (txt) {
    if (txt === "and") return txt;
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

function ReportBriefDetail({ hub, fetchReport, reportDetails }) {
  const {
    selectedReportDetail,
    remaining_ondemand_credit,
    remaining_library_credit,
    userData,
  } = useSelector((state) => state);

  const [rDetails, setRDetails] = useState((reportDetails || selectedReportDetail) ?? {});

  useEffect(() => {
   if (reportDetails) {
      setRDetails(reportDetails);
   } else if (selectedReportDetail) {
      setRDetails(selectedReportDetail);
   }
  }, [reportDetails, selectedReportDetail]);

  const navigate = useNavigate();
  const location = useLocation();
  //If location pathname is starting with /hubs then it will be a hub report
  const isHubReport = location.pathname.startsWith("/hubs");

  const cameFrom = (() => {
    if (!location?.state?.cameFrom) return "Reports";
    const directed = {
      MY_REPORTS: "My Reports",
      CATALOGUE: "On-Demand Reports",
      BOOKMARK: "Bookmark Report",
      PURCHASED_HISTORY: "Purchased History",
      REPORTS_LIBRARY: "Reports Library",
    };
    return directed[location.state.cameFrom] ?? "Reports";
  })();

  const handleBack = () => navigate(-1);
  if (!reportDetails && !selectedReportDetail) handleBack();

  const [downloadLoading, setDownloadLoading] = useState("IDLE");
  const { userInfo } = useAuth();

  const [loading, setLoading] = useState(false);
  const [messageData, setMessagesData] = useState([]);
  const [openChat, setOpenChat] = useState(false);

  const setOpeningOfChat = (open) => setOpenChat(open);

  async function getAllMessages() {
    setLoading(true);
    setMessagesData([]);
    SYNAPSE_API.get(`/api/hubs/showChat?reportId=${rDetails.report_id}`)
      .then((response) => {
        if (typeof response.data.data === "object") {
          setMessagesData(response.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const {
    hub_id,
    title,
    study_period = [2017, 2029],
    report_sample_url,
    domain,
    subscribed,
    report_expiry,
    published_year,
    subscribed_on,
    report_id,
    geography,
    regions_covered,
    countries_covered,
    country,
    is_requested_catalogue_report = false,
  } = rDetails;

  const downloadReportSample = async () =>
    await forceDownload(report_sample_url, title, setDownloadLoading);

  const purchaseStatusFactory = {
    yes: (
      <PurchasedReport
        canPurchase={userData.report_purchasing_access}
        report_expiry={report_expiry}
        report_id={report_id}
        hub_id={hub_id}
        title={title}
        info={userInfo?.account_type}
        access_type={userInfo?.access_type}
        viewOnlyFullAccess={userInfo?.viewOnlyFullAccess}
        subscribed={subscribed}
        remaining_ondemand_credit={remaining_ondemand_credit}
        remaining_library_credit={remaining_library_credit}
        is_requested_catalogue_report={is_requested_catalogue_report}
        fetchReport={fetchReport}
        loading={loading}
        setLoading={setLoading}
        getAllMessages={getAllMessages}
        messageData={messageData}
        openChat={openChat}
        setOpenChat={setOpeningOfChat}
      />
    ),
    no: (
      <UnPurchasedReport
        canPurchase={userData.report_purchasing_access}
        report_id={report_id}
        hub_id={hub_id}
        title={title}
        downloadSample={downloadReportSample}
        downloadLoading={downloadLoading}
        report_sample_url={report_sample_url}
        info={userInfo?.account_type}
        access_type={userInfo?.access_type}
        viewOnlyFullAccess={userInfo?.viewOnlyFullAccess}
        subscribed={subscribed}
        remaining_ondemand_credit={remaining_ondemand_credit}
        remaining_library_credit={remaining_library_credit}
        is_requested_catalogue_report={is_requested_catalogue_report}
        fetchReport={fetchReport}
        loading={loading}
        setLoading={setLoading}
        getAllMessages={getAllMessages}
        messageData={messageData}
        openChat={openChat}
        setOpenChat={setOpeningOfChat}
      />
    ),
    error: <></>,
  };

  const RegionOrCountryCovered = () => {
    const pluralizedText = (text, hasMultipleValue) => {
      if (!hasMultipleValue) return text;
      if (hasMultipleValue.indexOf(",") !== -1) {
        return text === "Region" ? "Regions" : "Countries";
      }
      return text;
    };

    const areaCovered =
      geography === "Global"
        ? "Regions"
        : pluralizedText("Country", countries_covered);
    const coveredArea =
      geography === "Global"
        ? regions_covered
        : geography === "Region"
        ? countries_covered
        : country;

    return (
      <p>
        <span>{areaCovered} Covered: </span>
        {coveredArea}
      </p>
    );
  };

  function extractSegment() {
    // Get the current URL path
    const path = window.location.pathname;

    // Split the path into segments
    const segments = path.split("/");

    // Assuming the structure is always /hubs/{segment}/...
    // and you want to extract "cep"
    const segment = segments[2]; // This gets "cep" from the example URL

    return segment;
  }

  return (
    <section
      className={styles.details}
      style={{ width: "90%", margin: "auto", marginTop: "-400px" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", color: "#5a5a5a" }}>
        <div style={{ cursor: "pointer" }} onClick={handleBack}>
          <span>
            <ArrowBackIosIcon sx={{ color: "#03b2cb", fontSize: "12px" }} />{" "}
          </span>
        </div>
        <div>
          <Tooltip title={isHubReport ? "Go To Reports" : "Go back"}>
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (isHubReport) {
                  navigate(`/hubs/${extractSegment()}`, {
                    state: {
                      id: hub_id,
                      isRedirectedFromReport: true,
                    },
                  });
                } else {
                  handleBack();
                }
              }}
            >
              {" " + cameFrom}
            </span>{" "}
          </Tooltip>
          {}
          {domain && (
            <Link
              state={{
                id: hub_id,
              }}
              style={{ color: "#5a5a5a" }}
              to={isHubReport ? `/hubs/${extractSegment()}` : `/reports`}
            >
              <Tooltip title={`Go to ${domain} hub`}>/ {domain} </Tooltip>
            </Link>
          )}
        </div>
      </Box>
      <div>
        <h1 style={{ marginBottom: "10px" }}>{title?.toUpperCase() || ""}</h1>
        <small className={styles.reportSmallDetails}>
          <ul style={{ margin: 0 }}>
            {domain &&
              (published_year != null ? (
                <li>Published In: {formatMonth(published_year)}</li>
              ) : null)}
            {domain && (
              <Link
                state={{
                  id: hub_id,
                  isRedirectedFromReport: true,
                }}
                to={isHubReport ? `/hubs/${extractSegment()}` : `/reports`}
              >
                {" "}
                <li className={styles.hubName}>Hub: {domain}</li>
              </Link>
            )}
            {subscribed_on && (
              <li>Purchased On: {formatDate(subscribed_on)}</li>
            )}
            {report_expiry && <li>Renewal On: {formatDate(report_expiry)}</li>}
          </ul>
        </small>
      </div>
      <div style={{ maxHeight: "42px" }}>
        <p style={{ margin: 0 }}>
          <span>Study Period:</span> {study_period[0] + " - " + study_period[1]}
        </p>
        <RegionOrCountryCovered />
      </div>
      <div className={styles.btnContainer}>
        {purchaseStatusFactory[subscribed || (!report_id ? "error" : "no")]}
      </div>
    </section>
  );
}

export default ReportBriefDetail;

const PurchasedReport = ({
  canPurchase = true,
  report_expiry,
  title,
  report_id,
  hub_id,
  access_type,
  viewOnlyFullAccess,
  subscribed,
  remaining_ondemand_credit,
  remaining_library_credit,
  is_requested_catalogue_report,
  fetchReport,
  loading,
  setLoading,
  getAllMessages,
  messageData,
  openChat,
  setOpenChat,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const expiryDate = new Date(report_expiry);
  const oneMonthFromNow = new Date();
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

  return (
    <>
      {is_requested_catalogue_report ? (
        <span style={requestedTagStyles}>Requested</span>
      ) : (
        <GetAccess
          subscribed={subscribed}
          remaining_ondemand_credit={remaining_ondemand_credit}
          remaining_library_credit={remaining_library_credit}
          is_requested_catalogue_report={is_requested_catalogue_report}
          report_expiry={report_expiry}
          title={title}
          report_id={report_id}
          fetchReport={fetchReport}
          hub_id={hub_id}
        />
      )}

      {expiryDate <= oneMonthFromNow &&
        !is_requested_catalogue_report &&
        remaining_ondemand_credit == 0 && (
          <button
            disabled={canPurchase === false}
            onClick={() => {
              setDrawerOpen(true);
              setTypeModal("renewal");
            }}
          >
            Renew
          </button>
        )}
      <RenewDrawer
        report_id={report_id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={typeModal}
      />
      <ChatBox
        setOpen={setOpenChat}
        open={openChat}
        title={title}
        report_id={report_id}
        getAllMessages={getAllMessages}
        loading={loading}
        setLoading={setLoading}
        data={messageData}
        reportLibrary={false}
      />
      <button
        onClick={() => {
          setOpenChat(true);
          getAllMessages();
        }}
      >
        <img src={mailImg} alt="ask query" />
        Ask Your Query
      </button>
    </>
  );
};

const UnPurchasedReport = ({
  downloadSample,
  downloadLoading,
  report_expiry,
  title,
  report_id,
  report_sample_url,
  info,
  access_type,
  viewOnlyFullAccess,
  subscribed,
  remaining_ondemand_credit,
  remaining_library_credit,
  is_requested_catalogue_report,
  fetchReport,
  hub_id,
  loading,
  setLoading,
  getAllMessages,
  messageData,
  openChat,
  setOpenChat,
  canPurchase = true,
}) => {

  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [typeModal, setTypeModal] = useState("");

  const creditCountFrom = location.state?.fromLibrary === true ? remaining_library_credit : remaining_ondemand_credit;

  return (
    <>
      {is_requested_catalogue_report ? (
        <span style={requestedTagStyles}>Requested</span>
      ) : (
        <GetAccess
          subscribed={subscribed}
          remaining_ondemand_credit={remaining_ondemand_credit}
          remaining_library_credit={remaining_library_credit}
          is_requested_catalogue_report={is_requested_catalogue_report}
          report_expiry={report_expiry}
          title={title}
          report_id={report_id}
          fetchReport={fetchReport}
          hub_id={hub_id}
        />
      )}
      {!is_requested_catalogue_report && creditCountFrom === 0 && (
        <button
          disabled={canPurchase === false}
          onClick={() => {
            setDrawerOpen(true);
            setTypeModal("buy");
          }}
        >
          Buy Now
        </button>
      )}
      {report_sample_url &&
        info !== "view only" &&
        ((access_type === "full" && viewOnlyFullAccess.includes(report_id)) ||
          access_type !== "full") && (
          <button
            onClick={downloadSample}
            disabled={downloadLoading === "PENDING"}
          >
            {downloadLoading === "PENDING" ? (
              <CircularProgress size={"20px"} />
            ) : (
              <>
                <img src={downloadImg} alt="download" />
                Download Sample
              </>
            )}
          </button>
        )}
      <button
        onClick={() => {
          setOpenChat(true);
          getAllMessages();
        }}
      >
        <img src={mailImg} alt="ask query" />
        Ask Your Query
      </button>
      <ChatBox
        setOpen={setOpenChat}
        open={openChat}
        title={title}
        report_id={report_id}
        getAllMessages={getAllMessages}
        loading={loading}
        setLoading={setLoading}
        data={messageData}
        reportLibrary={false}
      />
      <RenewDrawer
        report_id={report_id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={typeModal}
      />
    </>
  );
};

export const GetAccess = ({
  subscribed,
  remaining_ondemand_credit,
  remaining_library_credit,
  is_requested_catalogue_report,
  report_expiry,
  title = "",
  report_id,
  hub_id,
  fetchReport,
  mainBtnStyles = {},
}) => {
  const { userData } = useSelector(state => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [requestStatus, setRequestStatus] = useState("uninitialise");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { Base_URL } = API;
  const { config } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRequestStatus("uninitialise");
    setRequestMessage("");
  };

  const sendRequest = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    let reqURL;
    if (location.state?.fromLibrary) {
      reqURL = `${Base_URL}/api/users/decrease-library-credit-hub-report`;
    } else {
      reqURL = `${Base_URL}/api/users/decrease-demand-credit`;
    }
    axios({
      url: reqURL,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: report_id,
        message: requestMessage,
      },
    })
      .then(async (res) => {
        setRequestStatus("success");
        if (location.state?.fromLibrary) {
          dispatch({
            type: SET_LIBRARY_CREDITS,
            payload: {
              total_credit: res.data.data.credit.total,
              remaining_credit: res.data.data.credit.remaining,
            },
          });
        } else {
          dispatch({
            type: SET_ONDEMAND_CREDITS,
            payload: {
              total_credit: res.data.data.credit.total,
              remaining_credit: res.data.data.credit.remaining,
            },
          });
        }

        await authenticateProdgain();
        fetchReport();
      })
      .catch((error) => {
        setRequestStatus("failure");
        console.error(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  const creditFrom = location.state?.fromLibrary === true ? remaining_library_credit : remaining_ondemand_credit;

  if (subscribed === "yes") {
    return null;
  } else if (creditFrom === 0) {
    return null;
  } else if (is_requested_catalogue_report) {
    return null;
  } else {
    return (
      <>
        <Tooltip title="Access This Report" placement="top-start">
          <Button
            disabled={userData.report_purchasing_access === false}
            sx={{
              backgroundColor: userData.report_purchasing_access ? "#1BB1F0": "#e0e0e0 !important",
              textTransform: "capitalize",
              ...mainBtnStyles,
            }}
            onClick={() => {
              showModal();
            }}
            variant="contained"
          >
            Get Access
          </Button>
        </Tooltip>

        <Modal
          title={`${title.toUpperCase()}`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"800px"}
          styles={{
            width: "800px",
          }}
          footer={null}
        >
          {location.state?.fromLibrary ? (
            <div className="request-modal">
              {requestStatus === "success" && (
                <div>
                  <h1
                    style={{
                      color: "#1BB1F0",
                      fontSize: "32px",
                      fontWeight: "bold",
                    }}
                  >
                    Report Unlocked!
                  </h1>
                  <p className="description">
                    Close this window to start reading.
                  </p>
                  <p className="remaining-credits">
                    Remaining Library Credits :{" "}
                    <span> {`${remaining_library_credit}`} </span>
                  </p>
                </div>
              )}

              {requestStatus === "uninitialise" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3
                    style={{
                      marginBottom: "20px",
                      color: "rgb(125, 125, 125)",
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    Viewing this report will cost 1 credit.
                  </h3>
                  <p
                    style={{
                      marginBottom: "20px",
                      color: "rgb(125, 125, 125)",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    Current Library Credits :
                    <span style={{ color: "#1BB1F0" }}>
                      {remaining_library_credit}
                    </span>
                  </p>

                  <Button onClick={sendRequest} disabled={isButtonDisabled || (userData.report_purchasing_access === false)}>
                    Get Access
                  </Button>
                </div>
              )}

              {requestStatus === "failure" && (
                <div>
                  <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                    Something Went Wrong!
                  </h1>
                  <p className="description">Please try in some time.</p>
                  <p className="remaining-credits">
                    Your credits do not decrease.
                  </p>
                </div>
              )}
            </div>
          ) : hub_id ? (
            <div className="request-modal">
              {requestStatus === "success" && (
                <div>
                  <h1
                    style={{
                      color: "#1BB1F0",
                      fontSize: "32px",
                      fontWeight: "bold",
                    }}
                  >
                    Report Unlocked!
                  </h1>
                  <p className="description">
                    Close this window to start reading.
                  </p>
                  <p className="remaining-credits">
                    Remaining Ondemand Credits :
                    <span>{`${remaining_ondemand_credit}`}</span>
                  </p>
                </div>
              )}
              {requestStatus === "uninitialise" && (
                <>
                  {/* <form>
              <label>Message</label>
              <textarea
                value={requestMessage}
                onChange={(e) => {
                  setRequestMessage(e.target.value);
                }}
                placeholder="Your Message Here"
              />
              {
                requestMessage.length < 3 ? <small>Please type a valid message.</small> : null
              }
              <ButtonX className="req-btn" onClick={sendRequest} disabled={isButtonDisabled || (requestMessage.length < 3)}>
                Request Access
              </ButtonX>
            </form> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: "20px",
                        color: "rgb(125, 125, 125)",
                        fontWeight: "500",
                        fontSize: "20px",
                      }}
                    >
                      Viewing this report will cost 1 credit.
                    </h3>
                    <p
                      style={{
                        marginBottom: "20px",
                        color: "rgb(125, 125, 125)",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Current Ondemand Credits :
                      <span style={{ color: "#1BB1F0" }}>
                        {remaining_ondemand_credit}
                      </span>
                    </p>

                    <ButtonX onClick={sendRequest} disabled={isButtonDisabled || (userData.report_purchasing_access === false)}>
                      Get Access
                    </ButtonX>
                  </div>
                </>
              )}

              {requestStatus === "failure" && (
                <div>
                  <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                    Something Went Wrong!
                  </h1>
                  <p className="description">Please try in some time.</p>
                  <p className="remaining-credits">
                    Your credits do not decrease.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="request-modal">
              {requestStatus === "success" && (
                <div>
                  <h1
                    style={{
                      color: "#1BB1F0",
                      fontSize: "32px",
                      fontWeight: "bold",
                    }}
                  >
                    Thanks for the request!
                  </h1>
                  <p className="description">
                    Our sales representative will be working on the same.
                  </p>
                  <p className="remaining-credits">
                    Remaining Ondemand Credits :
                    <span>{`${remaining_ondemand_credit}`}</span>
                  </p>
                </div>
              )}
              {requestStatus === "uninitialise" && (
                <form>
                  <p
                    style={{
                      marginBottom: "5px",
                      color: "rgb(125, 125, 125)",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    Current Ondemand Credits :
                    <span style={{ color: "#1BB1F0" }}>
                      {remaining_ondemand_credit}
                    </span>
                  </p>
                  <label>Message</label>
                  <textarea
                    value={requestMessage}
                    onChange={(e) => {
                      setRequestMessage(e.target.value);
                    }}
                    placeholder="Your Message Here"
                  />
                  {requestMessage.length < 3 ? (
                    <small>Please type a valid message.</small>
                  ) : null}
                  <ButtonX
                    className="req-btn"
                    onClick={sendRequest}
                    disabled={isButtonDisabled || requestMessage.length < 3}
                  >
                    Request Access
                  </ButtonX>
                </form>
              )}

              {requestStatus === "failure" && (
                <div>
                  <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                    Something Went Wrong!
                  </h1>
                  <p className="description">Please try in some time.</p>
                  <p className="remaining-credits">
                    Your credits do not decrease.
                  </p>
                </div>
              )}
            </div>
          )}
        </Modal>
      </>
    );
  }
};

async function forceDownload(pdf_url, pdf_name, setLoading) {
  setLoading("PENDING");
  return new Promise(function (resolve, reject) {
    var x = new XMLHttpRequest();
    x.open("GET", pdf_url, true);
    x.responseType = "blob";
    x.onload = function (e) {
      resolve(x.response);
    };
    x.onerror = function () {
      reject(new Error("Error occurred while downloading PDF."));
    };
    x.send();
  })
    .then(function (pdf_blob) {
      saveAs(pdf_blob, pdf_name, "application/pdf");
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(() => {
      setLoading("IDLE");
    });
}
