import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LockBox from "../../LockBox";
import _ from "lodash";
import { useSelector } from "react-redux";
import { hubTitleWordsModify } from "../../../utils/common";

const ResearchMethodology = ({ framework, keyVariables, isSubscribed }) => {

  const { heading } = useSelector(state => state)

  let sampleData = [
    {
      color: "#FFEDC7",
      textColor: "#A94D19",
      title: "Identify Key Variables",
      description:
        "In order to build a robust forecasting methodology, the variables and factors identified in Step-1 are tested against available historical market numbers. Through an iterative process, the variables required for market forecast are set and the model is built on the basis of these variables.",
    },
    {
      color: "#E6E3FC",
      textColor: "#5241CE",
      title: "Identify Key Variables",
      description:
        "In order to build a robust forecasting methodology, the variables and factors identified in Step-1 are tested against available historical market numbers. Through an iterative process, the variables required for market forecast are set and the model is built on the basis of these variables.",
    },
    {
      color: "#E0EFFA",
      textColor: "#189CDE",
      title: "Identify Key Variables",
      description:
        "In order to build a robust forecasting methodology, the variables and factors identified in Step-1 are tested against available historical market numbers. Through an iterative process, the variables required for market forecast are set and the model is built on the basis of these variables.",
    },
    {
      color: "#D7F6EB",
      textColor: "#1C937B",
      title: "Identify Key Variables",
      description:
        "In order to build a robust forecasting methodology, the variables and factors identified in Step-1 are tested against available historical market numbers. Through an iterative process, the variables required for market forecast are set and the model is built on the basis of these variables.",
    },
    {
      color: "#FFCDC7",
      textColor: "#A94D19",
    },
    {
      color: "#E9E3FC",
      textColor: "#5241CE",
    },
    {
      color: "#E4EFFA",
      textColor: "#189CDE",
    },
    {
      color: "#D2F6EB",
      textColor: "#1C937B",
    },
  ];

  //Create function which generate random color

  const [currentResearch, setCurrentResearch] = useState(
    "Market Model Framework"
  );


  return (
    <Box sx={{ marginTop: "0.7rem", mb: "2rem" }}>
      <Box sx={{ textTransform: "capitalize", marginBottom: "2rem" }}>
        {currentResearch === "Market Model Framework" ? (
          <Button
            style={{
              backgroundColor: "#446BA6",
              padding: "8px 24px",
              textTransform: "capitalize",
              marginRight: "1rem",
            }}
            variant="contained"
          >
            Market Model Framework
          </Button>
        ) : (
          //Show only text type button
          <Button
            variant="text"
            sx={{
              color: "#5A5A5A",
              textTransform: "capitalize",
              marginRight: "1rem",
            }}
            onClick={() => {
              setCurrentResearch("Market Model Framework");
            }}
          >
            Market Model Framework
          </Button>
        )}
        {currentResearch === "Market Model and Key Variables" ? (
          <Button
            style={{
              backgroundColor: "#446BA6",
              padding: "8px 24px",
              textTransform: "capitalize",
              marginRight: "1rem",
            }}
            variant="contained"
          >
            Market Model and Key Variables
          </Button>
        ) : (
          //Show only text type button
          <Button
            variant="text"
            sx={{
              color: "#5A5A5A",
              textTransform: "capitalize",
              marginRight: "1rem",
            }}
            onClick={() => {
              setCurrentResearch("Market Model and Key Variables");
            }}
          >
            Market Model and Key Variables
          </Button>
        )}
      </Box>
      {currentResearch === "Market Model Framework" ? (
        <>
          {Object?.entries(framework?.steps ?? {})?.map(
            ([key, value], index) => {
              let title = key.split(":")[1];
              let description = value;

              return (
                <div
                  key={_.uniqueId()}
                  style={{
                    display: "flex",
                    backgroundColor: sampleData?.[index]?.color,
                    padding: "1.5rem",
                    marginBottom: "1.5rem",
                    alignItems: "center",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderRight: `1px solid ${sampleData?.[index]?.textColor}`,
                      paddingRight: "24px",
                      color: sampleData?.[index]?.textColor,
                    }}
                  >
                    <span>STEP</span>
                    <div
                      style={{
                        fontSize: "2.5rem",
                      }}
                      id="section1"
                    >
                      0{index + 1}
                    </div>
                  </div>
                  {/* //Add Horizontal Separator */}

                  <div style={{ paddingLeft: "24px" }} id="section2">
                    <h4
                      style={{
                        color: sampleData?.[index].textColor,
                        paddingBottom: "8px",
                      }}
                    >
                      {title}
                    </h4>
                    <p style={{ color: "#5A5A5A" }}>{description}</p>
                  </div>
                </div>
              );
            }
          )}
        </>
      ) : (
        <Box sx={{ position: "relative", minHeight: "60vh" }}>
          {!isSubscribed && (
            <LockBox
              sx={{
                top: "40%",
                left: "50%",
                zIndex: "5",
              }}
              componentName={"Market Model and Key Variables"}
              fromHub={hubTitleWordsModify(heading)}
            />
          )}
          <Box className={!isSubscribed ? "blur" : ""}>
            {keyVariables?.data?.length > 1 ? (
              <>
                <Box sx={{ width: "100%", display: "flex" }} id="container-1">
                  <div style={{ width: "80%" }}>
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#F0F7FC",
                        padding: "1.5rem 2.5rem 1.5rem 1.5rem",
                        justifyContent: "space-between",
                        position: "relative",
                        marginBottom: "99px",
                        gap: "4rem",
                      }}
                      id="top-box"
                    >
                      <div id="number-box" className="number-box">
                        1
                      </div>
                      {/* <img
                style={{ right: 0, position: "absolute" }}
                src={Vector1}
                alt="vector"
                id="vector"
              /> */}
                      <div id="top-box-raw">
                        {/* Create bullet point section */}
                        <ul style={{ listStylePosition: "inside" }}>
                          <h5
                            style={{
                              textTransform: "capitalize",
                              marginBottom: "0.5rem",
                            }}
                          >
                            {keyVariables?.data?.[0]?.title}
                          </h5>
                          {keyVariables?.data?.[0]?.bullets?.map(
                            (item, index) => (
                              <li
                                style={{ color: "#5A5A5A" }}
                                key={_.uniqueId()}
                              >
                                {item}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <div id="top-box-other">
                        {/* Create bullet point section */}
                        <ul style={{ listStylePosition: "inside" }}>
                          <h5
                            style={{
                              textTransform: "capitalize",
                              marginBottom: "0.5rem",
                            }}
                          >
                            {keyVariables?.data?.[1]?.title}
                          </h5>
                          {keyVariables?.data?.[1]?.bullets?.map(
                            (item, index) => (
                              <li
                                style={{ color: "#5A5A5A" }}
                                key={_.uniqueId()}
                              >
                                {item}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <Box width={"20%"} position={"relative"}>
                    <Box
                      width={"50%"}
                      height="63%"
                      sx={{
                        position: "absolute",
                        top: "136px",
                        // backgroundColor: 'red',
                        borderRight: "2px solid #189CDE",
                        borderTop: "2px solid #189CDE",
                        zIndex: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        borderTopRightRadius: "9px",
                      }}
                    >
                      <Box color={"#189cde"} sx={{ mt: "-13px", ml: "-10px" }}>
                        <ArrowRightIcon />
                      </Box>
                      <Box
                        color={"#189cde"}
                        sx={{ mb: "-26px", ml: "auto", mr: "-21px" }}
                      >
                        <ArrowDropDownIcon sx={{ fontSize: "40px" }} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // marginBottom: "4rem",
                  }}
                  id="container-2"
                >
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#F0F7FC",
                      padding: "1.5rem",
                      width: "40%",
                      position: "relative",
                    }}
                    id="container-2-1"
                  >
                    <div id="number-box" className="number-box">
                      3
                    </div>
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#F0F7FC",
                      }}
                      id="middle-box1-raw"
                    >
                      <ul
                        style={{ listStylePosition: "inside", width: "260px" }}
                      >
                        <h5
                          style={{
                            textTransform: "capitalize",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {keyVariables?.data?.[4]?.title}
                        </h5>
                        {keyVariables?.data?.[4]?.bullets?.map(
                          (item, index) => (
                            <li style={{ color: "#5A5A5A" }} key={_.uniqueId()}>
                              {item}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <Box flex={1}>
                    <Box
                      sx={{
                        height: "50%",
                        borderBottom: "2px solid #189CDE",
                        zIndex: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        ml: "5px",
                      }}
                    >
                      <Box color={"#189cde"} sx={{ mb: "-27px", ml: "-20px" }}>
                        <ArrowLeftIcon sx={{ fontSize: "40px" }} />
                      </Box>
                      <Box
                        color={"#189cde"}
                        sx={{ mb: "-19px", ml: "0px", mr: "-10px" }}
                      >
                        <ArrowLeftIcon />
                      </Box>
                    </Box>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "40%",
                      backgroundColor: "#F0F7FC",

                      // padding: "1.5rem",
                      position: "relative",
                    }}
                    id="container-2-2"
                  >
                    <div
                      style={{ right: "-20px" }}
                      id="number-box"
                      className="number-box"
                    >
                      2
                    </div>
                    <div
                      id="middle-box2-raw"
                      style={{
                        display: "flex",
                        backgroundColor: "#F0F7FC",
                        padding: "1.5rem",
                      }}
                    >
                      <ul
                        style={{ listStylePosition: "inside", width: "260px" }}
                      >
                        <h5
                          style={{
                            textTransform: "capitalize",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {keyVariables?.data?.[2]?.title}
                        </h5>
                        {keyVariables?.data?.[2]?.bullets?.map(
                          (item, index) => (
                            <li style={{ color: "#5A5A5A" }} key={_.uniqueId()}>
                              {item}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <div id="middle-box2-other"></div>
                  </div>
                </div>
                <Box height={"100px"} mb={"5px"}>
                  <Box
                    width={"175px"}
                    sx={{
                      height: "100%",
                      borderRight: "2px solid #189CDE",
                      zIndex: 10,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Box color={"#189cde"} sx={{ mt: "-10px", mr: "-13px" }}>
                      <ArrowDropDownIcon />
                    </Box>
                    <Box
                      color={"#189cde"}
                      sx={{ mb: "-28px", ml: "0px", mr: "-21px" }}
                    >
                      <ArrowDropDownIcon sx={{ fontSize: "40px" }} />
                    </Box>
                  </Box>
                </Box>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  id="container-3"
                >
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#F0F7FC",
                      padding: "1.5rem",
                      width: "40%",
                      position: "relative",
                    }}
                    id="container-3-1"
                  >
                    <div id="number-box" className="number-box">
                      4
                    </div>
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#F0F7FC",
                      }}
                      id="middle-box1-raw"
                    >
                      <ul
                        style={{ listStylePosition: "inside", width: "260px" }}
                      >
                        <h5
                          style={{
                            textTransform: "capitalize",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {keyVariables?.data?.[5]?.title}
                        </h5>
                        {keyVariables?.data?.[5]?.bullets?.map(
                          (item, index) => (
                            <li style={{ color: "#5A5A5A" }} key={_.uniqueId()}>
                              {item}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <Box flex={1} mr={"5px"}>
                    <Box
                      sx={{
                        height: "50%",
                        borderBottom: "2px solid #189CDE",
                        zIndex: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      <Box color={"#189cde"} sx={{ mb: "-20px", ml: "-11px" }}>
                        <ArrowRightIcon />
                      </Box>
                      <Box
                        color={"#189cde"}
                        sx={{ mb: "-28px", ml: "0px", mr: "-20px" }}
                      >
                        <ArrowRightIcon sx={{ fontSize: "40px" }} />
                      </Box>
                    </Box>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      width: "40%",
                      backgroundColor: "#F0F7FC",
                      position: "relative",
                    }}
                    id="container-3-2"
                  >
                    <div
                      id="middle-box2-raw"
                      style={{
                        display: "flex",
                        backgroundColor: "#F0F7FC",
                        padding: "1.5rem",
                      }}
                    >
                      <div id="number-box" className="number-box">
                        5
                      </div>
                      <ul
                        style={{ listStylePosition: "inside", width: "260px" }}
                      >
                        <h5
                          style={{
                            textTransform: "capitalize",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {keyVariables.data?.[3]?.title}
                        </h5>
                        {keyVariables.data?.[3]?.bullets?.map((item, index) => (
                          <li style={{ color: "#5A5A5A" }} key={_.uniqueId()}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Typography sx={{ color: "#5A5A5A", marginLeft: "0.5rem" }}>
                No Data Available
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ResearchMethodology;
