import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  Popover,
} from "@mui/material";
import { Modal, Button as ButtonX } from "antd";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import React, { useState, useEffect } from "react";
import ChatBox from "../Common/ChatBox";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import Cookies from "universal-cookie";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { BookmarkBorder } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CURRENT_REPORT_DETAIL,
  SET_ONDEMAND_CREDITS,
} from "../Redux/action";
import API from "../../api/Api";
import RenewDrawer from "../Common/RenewDrawer";
import {
  authenticateProdgain,
  checkAndUpdateDownloadCredit,
  displayNoDownloadCredit,
} from "../../utils/common";
import { setDownloadCredits } from "../Redux/action";
import DownloadAccessChecker from "../Common/DownloadOptionCheck/DownloadAccessChecker";
import { logFileDownloadClick } from "../../utils/ga";

const HubsReportCard = ({
  report_id,
  title,
  favourite,
  subscribed,
  slug,
  getReportData,
  dimensions,
  hub_type,
  study_period,
  purchased_on,
  renewal_on,
  companyLogos,
  subscribed_on,
  report_expiry,
  hubId,
  ids = {},
  category = {},
  company = {},
  published_year = null,
  report_sample_url,
  report_url,
  hubTitle,
  hubSlug,
  geography,
  regions_covered,
  country,
  countries_covered,
  is_requested_catalogue_report,
  alias_accessed_report = null,
  callCheckSubscribed,
  canPurchase = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [renewalMessage, setRenewalMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  const [reportPdf, setReportPdf] = useState("");
  const [pdfLoading, setPdfLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [typeModal, setTypeModal] = useState("");
  const [redirect, setRedirect] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [requestStatus, setRequestStatus] = useState("uninitialise");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { config, setNotify, userInfo, setCurrentTab } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    total_credit,
    remaining_credit,
    total_ondemand_credit,
    remaining_ondemand_credit,
  } = useSelector((state) => state);

  const URL = location.pathname;

  const { Base_URL } = API;

  const currentDate = new Date();
  const upcoming30Days = new Date();
  upcoming30Days.setDate(currentDate.getDate() + 30);

  const isExpiringWithin30Days =
    new Date(report_expiry) > currentDate &&
    new Date(report_expiry) <= upcoming30Days;

  // { FOR PURCHAE HISTORY CODE}
  const toggleDrawer = () => {
    setDrawerOpen(false);
    setRenewalMessage("");
  };

  const submitRenewalRequest = async (e) => {
    e.preventDefault();
    const bodyParameters = {
      email: userInfo?.email,
      message: renewalMessage,
      reportId: report_id,
    };

    try {
      const response = await axios.post(
        `${Base_URL}/api/hubs/request-renewal-data`,
        bodyParameters,
        config
      );

      if (response?.data.code === 200) {
        setNotify({
          isOpen: true,
          message: response.data?.message,
          type: "success",
          position: "right",
        });
      }
    } catch (error) {
      console.error(error);
      setNotify({
        isOpen: true,
        message: error.response.data?.message || "Some Error occurred",
        type: "error",
        position: "right",
      });
    } finally {
      toggleDrawer();
    }
  };

  // { FOR PURCHAE HISTORY CODE}

  // creating slug url
  const data = title.toLowerCase().replace(/\s+/g, "-").trim();
  let urlTitle = data.substring(0, data.indexOf("market") + 6);
  const cookies = new Cookies();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRequestStatus("uninitialise");
    setRequestMessage("");
  };

  const sendRequest = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    axios({
      url: `${Base_URL}/api/users/decrease-demand-credit`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: report_id,
        message: requestMessage,
      },
    })
      .then(async (res) => {
        setRequestStatus("success");
        dispatch({
          type: SET_ONDEMAND_CREDITS,
          payload: {
            total_credit: res.data.data.credit.total,
            remaining_credit: res.data.data.credit.remaining,
          },
        });
        await authenticateProdgain();
        getReportData();
        callCheckSubscribed();
      })
      .catch((error) => {
        setRequestStatus("failure");
        console.error(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  async function getAllMessages() {
    setLoading(true);
    setMessageData([]);

    axios
      .get(`${Base_URL}/api/hubs/showChat?reportId=${report_id}`, config)
      .then((res) => {
        if (typeof res.data.data === "object") {
          setMessageData(res.data?.data);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // Send messages from here

  async function sendMessage(msg) {
    const bodyParameters = {
      message: msg,
      reportId: report_id,
      url: window.location.href,
    };

    axios
      .post(`${Base_URL}/api/hubs/chat`, bodyParameters, config)
      .then((res) => {
        getAllMessages();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // getting the report pdf from this api⬇️⬇️

  async function downloadPdf() {
    const bodyParameters = {
      // message: msg,
      reportId: report_id,
    };

    axios
      .post(`${Base_URL}/api/hubs/reportDownload`, bodyParameters, config)
      .then((res) => {
        // getAllMessages();
        setReportPdf(res.data?.data?.report_url);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    // downloadPdf();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    getAllMessages();
  };

  async function forceDownload({ report_url, report_sample_url }, pdf_name) {
    const urlToDownload = subscribed === "yes" ? report_url : report_sample_url;

    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    setPdfLoading(true);
    setNotify({
      isOpen: true,
      message: "Download in Progress",
      type: "success",
      position: "right",
    });
    return new Promise(function (resolve, reject) {
      var x = new XMLHttpRequest();
      x.open("GET", urlToDownload, true);
      x.responseType = "blob";
      x.onload = function (e) {
        resolve(x.response);
      };
      x.onerror = function () {
        reject(new Error("Error occurred while downloading PDF."));
      };
      x.send();
    })
      .then(async function (pdf_blob) {
        saveAs(pdf_blob, pdf_name, "application/pdf");
        setNotify({
          isOpen: true,
          message: "Download Completed",
          type: "success",
          position: "right",
        });

        if (total_credit != null) {
          let credits = await checkAndUpdateDownloadCredit(config);

          dispatch(
            setDownloadCredits(credits.total_credit, credits.remaining_credit)
          );
        }

        setPdfLoading(false);
      })
      .catch(function (error) {
        setNotify({
          isOpen: true,
          message: "Download Failed",
          type: "error",
          position: "right",
        });

        setPdfLoading(false);
        console.log(error);
      });
  }

  function addLink(title) {
    const input = title;
    const parts = input.split(" - ");
    return parts[0].toLowerCase().split(" ").join("-");
  }

  // for bookmark or make favorite your report id

  async function makeThisFavorite(reportId) {
    setLoading(true);
    const bodyParameters = {
      report_id: reportId,
    };
    const url = `${Base_URL}/api/hubs/favourite`;
    axios
      .post(url, bodyParameters, config)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data?.message,
          type: "success",
          position: "right",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getReportData();
        setLoading(false);
      });
  }

  const handleReportRedirect = (slug, clickedAt) => {
    if (location.pathname === "/bookmarks") {
      return navigate(`reports/${slug}`);
    }
    if (location.pathname === "/purchase-history") {
      return navigate(`${slug}`);
    }

    if (
      location.pathname === "/companies" ||
      location.pathname === "/companies/"
    ) {
      let state = {
        report_id,
        title,
        subscribed,
        report_url,
        report_sample_url,
        slug,
        dimensions,
        hub_type,
        study_period,
        purchased_on,
        renewal_on,
        published_year,
        ids,
        category,
        company,
        is_requested_catalogue_report,
        tab: clickedAt === "title" ? "dashboard" : "report-pdf",
      };

      navigate(`/companies/${slug}`, { state });
    } else if (
      location.pathname.includes("hubs") &&
      location.pathname.includes("companies")
    ) {
      let state = {
        report_id,
        title,
        subscribed,
        report_url,
        report_sample_url,
        slug,
        dimensions,
        hub_type,
        study_period,
        purchased_on,
        renewal_on,
        published_year,
        ids,
        category,
        company,
        hubSlug,
        hubTitle,
        is_requested_catalogue_report,
        tab: clickedAt === "title" ? "dashboard" : "report-pdf",
      };
      let companySlug = company?.name
        .toLowerCase()
        .replaceAll(".", "")
        .replaceAll(",", "")
        .replaceAll("&", "and")
        .replaceAll("'", "")
        .split(" ")
        .join("-");
      navigate(`${slug}`, {
        state,
      });
    } else {
      const setReportData = {
        report_id,
        title,
        subscribed,
        report_url,
        report_sample_url,
        slug,
        getReportData,
        dimensions,
        hub_type,
        study_period,
        purchased_on,
        renewal_on,
        published_year,
        is_requested_catalogue_report,
      };

      dispatch({
        type: SET_CURRENT_REPORT_DETAIL,
        payload: setReportData,
      });

      const hub = params.id;
      if (!hub) {
        return navigate(`${slug}`);
      }
      return navigate(`${slug}`);
    }
  };

  const viewSample = () => {
    return window.open(`${report_sample_url}`);
  };

  const viewCompanyProfile = () => {
    if (
      location.pathname === "/companies" ||
      location.pathname === "/companies/"
    ) {
      let state = {
        report_id,
        title,
        subscribed,
        report_url,
        report_sample_url,
        slug,
        dimensions,
        hub_type,
        study_period,
        purchased_on,
        renewal_on,
        published_year,
        ids,
        category,
        company,
        is_requested_catalogue_report,
        tab: "company-profile",
      };

      navigate(`/companies/${slug}`, { state });
    } else if (
      location.pathname.includes("hubs") &&
      location.pathname.includes("companies")
    ) {
      let state = {
        report_id,
        title,
        subscribed,
        report_url,
        report_sample_url,
        slug,
        dimensions,
        hub_type,
        study_period,
        purchased_on,
        renewal_on,
        published_year,
        ids,
        category,
        company,
        hubSlug,
        hubTitle,
        is_requested_catalogue_report,
        tab: "company-profile",
      };
      let companySlug = company?.name
        .toLowerCase()
        .replaceAll(".", "")
        .replaceAll(",", "")
        .replaceAll("&", "and")
        .replaceAll("'", "")
        .split(" ")
        .join("-");
      navigate(`${slug}`, {
        state,
      });
    }
  };

  // for make This  unfavorite your report id

  async function makeThisUnFavorite(reportId) {
    setLoading(true);
    const bodyParameters = {
      report_id: reportId,
    };
    const url = `${Base_URL}/api/hubs/unFavourite`;
    axios
      .post(url, bodyParameters, config)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data?.message,
          type: "success",
          position: "right",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getReportData();
        setLoading(false);
      });
  }

  const isDownloadDisabled = (sample_url, subscribed) => {
    return !sample_url && subscribed === "no";
  };

  const downloadCompanyProfile = async () => {
    let pptData;
    let pptUrl;
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    try {
      const Url1 = `${Base_URL}/api/company/viewCompanyProfile`;
      const BodyParameters1 = {
        hub_id: ids?.hubId,
        company_id: ids?.companyId,
        report_id: ids?.reportId,
      };

      const response1 = await axios
        .post(Url1, BodyParameters1, config)
        .then((res) => {
          pptData = res?.data?.data?.pptData;
          setNotify({
            isOpen: true,
            message: "Data Fetched Successfully",
            type: "success",
            position: "right",
          });
        })
        .catch((error) => {
          console.log(error);
          setNotify({
            isOpen: true,
            message: "Failed to Fetch Data",
            type: "error",
            position: "right",
          });
        });

      const Url2 = `${Base_URL}/api/company/ppt-gen`;
      const BodyParameters2 = {
        file_title: company?.name + " Profile",
        pptData: pptData,
      };
      const response2 = await axios
        .post(Url2, BodyParameters2, config)
        .then((res) => {
          pptUrl = res?.data?.data?.url;
          setNotify({
            isOpen: true,
            message: "Download Completed",
            type: "success",
            position: "right",
          });
        })
        .catch((error) => {
          console.log(error);
          setNotify({
            isOpen: true,
            message: "Download Failed",
            type: "error",
            position: "right",
          });
        })
        .finally(() => {
          setNotify({
            isOpen: false,
            message: "",
            type: "",
            position: "",
          });
        });

      handleDownload(pptUrl);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleDownload(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = true;
    link.click();

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  }

  // Function to format date
  function formatDate(stringDate) {
    const date = new Date(stringDate);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();
    return `${day} ${months[monthIndex]}, ${year}`;
  }

  const handleDownloadModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadModalClose = (event) => {
    setAnchorEl(null);
  };

  const Open = Boolean(anchorEl);
  const id = Open ? "popover" : undefined;

  if (companyLogos?.length > 4) {
    companyLogos.pop();
  }

  const hasAccess = DownloadAccessChecker("report", report_id);

  const buyReport = (
    setDrawerOpen,
    setTypeModal,
    subscribed,
    location,
    currentDate,
    report_expiry,
    remaining_ondemand_credit,
    is_requested_catalogue_report
  ) => {
    if (subscribed === "yes" || currentDate <= new Date(report_expiry)) {
      return null;
    }

    if (remaining_ondemand_credit > 0) {
      return null;
    }

    if (is_requested_catalogue_report) {
      return null;
    }

    return (
      <Tooltip title="Buy This Report" placement="top-start">
        <Button
          disabled={canPurchase === false}
          sx={{
            backgroundColor: "#189CDE",
            textTransform: "capitalize",
          }}
          onClick={() => {
            setDrawerOpen(true);
            setTypeModal("buy");
          }}
          variant="contained"
        >
          Buy Now
        </Button>
      </Tooltip>
    );
  };

  const getAccess = (
    subscribed,
    location,
    currentDate,
    report_expiry,
    remaining_ondemand_credit,
    is_requested_catalogue_report
  ) => {
    if (subscribed === "yes" || currentDate <= new Date(report_expiry)) {
      return null;
    }

    if (remaining_ondemand_credit <= 0) {
      return null;
    }

    if (is_requested_catalogue_report == true) {
      return null;
    }

    return (
      <Tooltip title="Access This Report" placement="top-start">
        <Button
          disabled={canPurchase === false}
          sx={{
            backgroundColor: "#189CDE",
            textTransform: "capitalize",
            marginLeft: "15px",
          }}
          onClick={() => {
            showModal();
          }}
          variant="contained"
        >
          Get Access
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid transparent",
          marginBottom: "2rem",
          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
          "&:hover": {
            border: "1px solid  #189CDE",
          },
        }}
      >
        <ChatBox
          setOpen={setOpen}
          open={open}
          title={title}
          data={messageData}
          loading={loading}
          report_id={report_id}
          getAllMessages={getAllMessages}
          sendMessage={sendMessage}
          setLoading={setLoading}
        />
        <CardContent
          sx={{
            padding: "0px",
            //Remove padding from last child
            "&:last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          <Box
            component="div"
            sx={{ background: "#E0EFFA", padding: "0.3rem" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              {favourite === "yes" ? (
                <Tooltip title="Unbookmark" placement="top-start">
                  <BookmarkIcon
                    onClick={() => {
                      makeThisUnFavorite(report_id);
                    }}
                    sx={{
                      marginLeft: "1.2rem",
                      color: "#002F75",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Bookmark" placement="top-start">
                  <BookmarkBorder
                    onClick={() => {
                      makeThisFavorite(report_id);
                    }}
                    sx={{
                      marginLeft: "1.2rem",
                      color: "#002F75",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              )}

              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    fontFamily: "Avenir Roman !important",
                  },
                }}
                style={{ marginLeft: "0.8rem", marginTop: "0.2rem" }}
                className="title-16"
                onClick={() => {
                  setCurrentTab(0);
                  handleReportRedirect(slug, "title");
                }}
              >
                {title.toUpperCase()}
              </Typography>
            </div>
          </Box>
          <div id="card_body" className="card_body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E4E4E4",
              }}
              id="section-one"
            >
              <div id="section-one_1">
                {/* <div>
                <span
                  style={{
                    color: "#2D2D2D",
                    fontFamily: "Avenir Heavy",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Published In:
                </span>{" "}
                <span>Apr,2023</span>
              </div> */}
                {hub_type && (
                  <Chip
                    label={`Hub: ${hub_type}`}
                    style={{
                      backgroundColor: "#FFEDC7",
                      color: "#A94D19",
                      fontFamily: "Avenir Roman",
                      height: "26px",
                    }}
                    sx={{
                      //Modify the chip span font
                      "& span": {
                        fontFamily: "Avenir Heavy",
                      },
                      marginBottom: "0.5rem",
                    }}
                  />
                )}
                <div>
                  <span
                    style={{
                      color: "#2D2D2D",
                      fontFamily: "Avenir Heavy",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Published Date:
                  </span>{" "}
                  <span
                    style={{
                      color: "#5A5A5A",
                      fontFamily: "Avenir Roman",
                    }}
                  >
                    {`${formatDate(published_year)}`}
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      color: "#2D2D2D",
                      fontFamily: "Avenir Heavy",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Study Period:
                  </span>{" "}
                  {study_period && (
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {`${study_period?.[0]}-${study_period?.[1]}`}
                    </span>
                  )}
                </div>
                <div>
                  <span
                    style={{
                      color: "#2D2D2D",
                      fontFamily: "Avenir Heavy",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Format:
                  </span>{" "}
                  <span
                    style={{
                      color: "#5A5A5A",
                      fontFamily: "Avenir Roman",
                    }}
                  >
                    {`pdf, excel`}
                  </span>
                </div>
                {geography === "Global" && (
                  <div>
                    <span
                      style={{
                        color: "#2D2D2D",
                        fontFamily: "Avenir Heavy",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Regions Covered:
                    </span>{" "}
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {regions_covered}
                    </span>
                  </div>
                )}
                {geography === "Country" && (
                  <div>
                    <span
                      style={{
                        color: "#2D2D2D",
                        fontFamily: "Avenir Heavy",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Country Covered:
                    </span>{" "}
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {country}
                    </span>
                  </div>
                )}
                {geography === "Region" && (
                  <div>
                    <span
                      style={{
                        color: "#2D2D2D",
                        fontFamily: "Avenir Heavy",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Countries Covered:
                    </span>{" "}
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {countries_covered}
                    </span>
                  </div>
                )}
              </div>
              <CompanyLogosDisplay companyLogos={companyLogos} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0rem 2.6rem",
            }}
            id="section-two"
          >
            {/* <div id="section-two-text"> */}
            <div style={{ paddingTop: "0.8rem" }}>
              {(purchased_on && renewal_on) || report_expiry ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>{`Purchased On: ${formatDate(
                    purchased_on ? purchased_on : subscribed_on
                  )}`}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "0.2rem",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "4px",
                        borderRadius: "50%",
                        backgroundColor: "#5A5A5A",
                        margin: "0px 0.5rem",
                      }}
                    ></div>
                  </div>
                  <div>{`Renewal On: ${
                    purchased_on
                      ? formatDate(renewal_on)
                      : formatDate(report_expiry)
                  }`}</div>
                </div>
              ) : null}
            </div>
            <div id="section-two-button">
              {/* Add Icon Button */}
              {subscribed === "yes" && (
                <Tooltip
                  title={
                    subscribed === "no" || currentDate > new Date(report_expiry)
                      ? "Show Sample Report"
                      : "Show Report"
                  }
                  placement="top-start"
                >
                  <IconButton
                    sx={{
                      //onHover showing pointer
                      cursor: "pointer",
                    }}
                    aria-label="delete"
                    size="large"
                    onClick={() => {
                      setCurrentTab(0);
                      handleReportRedirect(slug, "eye-icon");
                    }}
                  >
                    <RemoveRedEyeIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {!location.pathname.includes("companies") &&
                report_sample_url !== null &&
                hasAccess && (
                  <Tooltip
                    title={
                      subscribed === "no" ||
                      currentDate > new Date(report_expiry)
                        ? "Download Sample"
                        : "Download Report"
                    }
                    placement="top-start"
                  >
                    <IconButton
                      onClick={() => {
                        logFileDownloadClick({ fileName: title });
                        forceDownload(
                          { report_url, report_sample_url },
                          title
                        );
                      }}
                      disabled={report_sample_url === null}
                      size="large"
                      // disabled={isDownloadDisabled(report_sample_url, subscribed)}
                    >
                      <DownloadIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}

              {/* Only For Companies */}
              {((location.pathname.includes("hubs") &&
                location.pathname.includes("companies")) ||
                location.pathname === "/companies") &&
                hasAccess && (
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                    }}
                  >
                    <Tooltip
                      title={
                        subscribed === "no" ||
                        currentDate > new Date(report_expiry)
                          ? "No Download Access"
                          : "View Download Options"
                      }
                      placement="top"
                    >
                      <IconButton
                        aria-describedby={id}
                        variant="contained"
                        onClick={(e) => {
                          logFileDownloadClick({ fileName: title });
                          if (subscribed === "yes") {
                            handleDownloadModalOpen(e);
                          }
                        }}
                        size="large"
                        sx={{
                          color:
                            subscribed === "yes"
                              ? "rgb(109,109,109)"
                              : "rgb(218,218,218)",
                          cursor:
                            subscribed === "yes" ? "pointer" : "not-allowed",
                        }}
                      >
                        <DownloadIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>

                    <Popover
                      id={id}
                      open={Open}
                      anchorEl={anchorEl}
                      onClose={handleDownloadModalClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <p>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() => {
                            forceDownload(
                              { report_url, report_sample_url },
                              title
                            );
                          }}
                        >
                          Download Report
                        </Button>
                      </p>
                      <p>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() => {
                            downloadCompanyProfile();
                          }}
                          disabled={reportLoading}
                        >
                          Download Company Profile
                        </Button>
                      </p>
                    </Popover>
                  </div>
                )}

              <Tooltip title="Ask Query" placement="top-start">
                <IconButton
                  onClick={handleClickOpen}
                  aria-label="delete"
                  size="large"
                >
                  <ChatBubbleOutlineIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

              {((location.pathname.includes("hubs") &&
                location.pathname.includes("companies")) ||
                location.pathname === "/companies") &&
                subscribed === "yes" && (
                  <Tooltip title="View Company Profile" placement="top-start">
                    <Button
                      onClick={viewCompanyProfile}
                      variant="outlined"
                      color="primary"
                      sx={{
                        marginRight: "1rem",
                        marginLeft: "1rem",
                        textTransform: "capitalize",
                      }}
                    >
                      View Co. Profile
                    </Button>
                  </Tooltip>
                )}

              {currentDate > new Date(report_expiry) &&
                report_sample_url !== null &&
                hasAccess && (
                  <Tooltip title="View Sample Report" placement="top-start">
                    <Button
                      onClick={() => viewSample()}
                      variant="outlined"
                      color="primary"
                      disabled={report_sample_url === null}
                      sx={{
                        marginRight: "1rem",
                        marginLeft: "1rem",
                        textTransform: "capitalize",
                      }}
                    >
                      View Sample
                    </Button>
                  </Tooltip>
                )}

              {subscribed !== "yes" && report_sample_url === null && (
                <Tooltip title="Request Sample" placement="top-start">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setDrawerOpen(true);
                      setTypeModal("request sample");
                    }}
                    // disabled={report_sample_url === null}
                    sx={{
                      marginRight: "1rem",
                      marginLeft: "1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Request Sample
                  </Button>
                </Tooltip>
              )}

              {/* { (location.pathname === "/companies")  &&(remaining_ondemand_credit == 0)  && (subscribed === "no" ||
                currentDate > new Date(report_expiry)) && (
                <Tooltip title="Buy This Report" placement="top-start">
                  <Button
                    sx={{
                      backgroundColor: "#189CDE",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      setDrawerOpen(true);
                      setTypeModal("buy");
                    }}
                    variant="contained"
                  >
                    Buy Now
                  </Button>
                </Tooltip>
              )} */}

              {buyReport(
                setDrawerOpen,
                setTypeModal,
                subscribed,
                location,
                currentDate,
                report_expiry,
                remaining_ondemand_credit,
                is_requested_catalogue_report
              )}

              {getAccess(
                subscribed,
                location,
                currentDate,
                report_expiry,
                remaining_ondemand_credit,
                is_requested_catalogue_report
              )}

              {subscribed !== "no" && isExpiringWithin30Days && (
                <Tooltip title="Renew This Report" placement="top-start">
                  <Button
                    disabled={canPurchase === false}
                    sx={{
                      backgroundColor: "#189CDE",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (remaining_ondemand_credit > 0) {
                        showModal();
                      } else {
                        setDrawerOpen(true);
                        setTypeModal("renewal");
                      }
                    }}
                  >
                    Renew
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          {/* </div> */}
        </CardContent>
      </Card>

      <Modal
        title={`${title.toUpperCase()}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"800px"}
        styles={{
          width: "800px",
        }}
        footer={null}
      >
        <div className="request-modal">
          {requestStatus === "success" && (
            <div>
              <h1
                style={{
                  color: "#1BB1F0",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                Report Unlocked!
              </h1>
              <p className="description">Close this window to start reading.</p>
              <p className="remaining-credits">
                Remaining Ondemand Credits :
                <span>{`${remaining_ondemand_credit}`}</span>
              </p>
            </div>
          )}
          {requestStatus === "uninitialise" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                Viewing this report will cost 1 credit.
              </h3>

              <p
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                {" "}
                Current Ondemand Credits :
                <span style={{ color: "#1BB1F0" }}>
                  {remaining_ondemand_credit}
                </span>
              </p>

              <ButtonX disabled={isButtonDisabled} onClick={sendRequest}>
                Get Access
              </ButtonX>
            </div>
          )}

          {requestStatus === "failure" && (
            <div>
              <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                Something Went Wrong!
              </h1>
              <p className="description">Please try in some time.</p>
              <p className="remaining-credits">Your credits do not decrease.</p>
            </div>
          )}
        </div>
      </Modal>

      <RenewDrawer
        report_id={report_id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={typeModal}
      />

      {/* <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <div style={{ width: "444px", height: "88%", position: "relative" }}>
          <Stack
            p={"24px"}
            sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)" }}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "800",
                lineHeight: "25.6px",
                color: "#191919",
              }}
            >
              {title}
            </Typography>
            <IconButton sx={{ color: '#002F75' }} onClick={toggleDrawer}>
              <CloseIcon color="#002F75" />
            </IconButton>
          </Stack>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              height: "100%",
            }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Box>
                <Typography
                  sx={{ my: "10px", fontWeight: "600", fontSize: "14px" }}
                >
                  Name
                </Typography>
                <TextField
                  sx={{
                    background: "#E4E4E4",
                    color: "#878787",
                    textTransform: "capitalize",
                  }}
                  disabled
                  placeholder="Enter Name"
                  value={userInfo?.username}
                  // onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                />
                <Typography
                  sx={{ my: "10px", fontWeight: "600", fontSize: "14px" }}
                >
                  Company Name
                </Typography>
                <TextField
                  sx={{ background: "#E4E4E4", color: "#878787" }}
                  disabled
                  placeholder="Enter Comapny Name"
                  name="username"
                  // value={username}
                  fullWidth
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    mt: "20px",
                    mb: "7px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  disabled
                  sx={{ background: "#E4E4E4", color: "#878787" }}
                  variant="outlined"
                  placeholder="Enter Email"
                  value={userInfo?.email}
                  fullWidth
                  error={!isValid}
                  helperText={
                    !isValid ? "Please enter a valid email address." : ""
                  }
                />
                <Typography
                  sx={{
                    mt: "20px",
                    mb: "7px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Phone
                </Typography>
                <TextField
                  disabled
                  sx={{ background: "#E4E4E4", color: "#878787" }}
                  variant="outlined"
                  placeholder="Enter Phone Number"
                  value={userInfo?.mobile.phone_number}
                  // onChange={handleEmailChange}
                  fullWidth
                />
              </Box>
            </Box>
            <Typography
              sx={{
                mt: "20px",
                mb: "7px",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Message <span style={{ color: "red" }}>*</span>{" "}
            </Typography>
            <TextField
              sx={{ mb: "40px" }}
              value={renewalMessage}
              onChange={(e) => setRenewalMessage(e.target.value)}
              multiline
              rows={4}
              variant="outlined"
            />

            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                position: "relative",
              }}
            >
              <hr
                style={{
                  width: "444px",
                  position: "absolute",
                  bottom: "70px",
                  left: "-14px",
                  border: "none",
                  boxShadow: "0px -2px 8px rgba(0, 0, 0, 1)",
                  height: "1px",
                  marginBottom: "-10px",
                  // backgroundColor: "#333"
                }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ textTransform: "capitalize", mb: "10px" }}
                type="submit"
                disabled={renewalMessage?.trim().length < 5}
                onClick={submitRenewalRequest}
              >
                Request Renewal
              </Button>
            </Box>
          </form>
        </div>
      </Drawer> */}
    </>
  );
};

export const CompanyLogosDisplay = ({ companyLogos }) => {
  if (!companyLogos || !companyLogos.length) return <></>;

  const getImageGridStyle = (index) => {
    const baseStyle = {
      flex: "1",
      overflow: "hidden",
    };

    switch (companyLogos.length) {
      case 1:
        return {
          ...baseStyle,
          flexGrow: "1",
        };
      case 2:
        return {
          ...baseStyle,
          flexGrow: "1",
          flexBasis: "50%",
        };
      case 3:
        return {
          ...baseStyle,
          flexGrow: index < 2 ? "1" : "2",
          flexBasis: index < 2 ? "50%" : "100%",
        };
      default:
        return {
          ...baseStyle,
          flexGrow: "1",
          flexBasis: "50%",
        };
    }
  };

  return (
    <div id="section-one_2">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "150px",
          // maxHeight: "150px",
          margin: "5px",
        }}
      >
        {companyLogos.map((item, index) => (
          <div key={index} style={getImageGridStyle(index)}>
            {item ? (
              <img
                key={index}
                style={{
                  borderRadius: "6.67px",
                  margin: "5px",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  // aspectRatio: "3/4"
                }}
                src={item}
                alt={"logo"}
              />
            ) : (
              <div
                style={{
                  borderRadius: "6.67px",
                  margin: "5px",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HubsReportCard;
