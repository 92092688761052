import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../context/authContext/Auth";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import Loader from "../Common/Loader";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import { CiFilter } from "react-icons/ci";
import { FaFilter } from "react-icons/fa";
import { RiFilter2Line, RiFilter2Fill } from "react-icons/ri";
import { BsChevronExpand } from "react-icons/bs";
import LockBox from "../LockBox";
import RenewDrawer from "../Common/RenewDrawer";
import API from "../../api/Api";
import { checkAndUpdateDownloadCredit, displayNoDownloadCredit,getRandomNumber } from "../../utils/common";
import { setDownloadCredits } from "../Redux/action";
import DownloadAccessChecker from "../Common/DownloadOptionCheck/DownloadAccessChecker";
import ShowComponentCheck from "../Common/ShowComponentCheck/ShowComponentCheck";
import { logFileDownloadClick } from "../../utils/ga";
// import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
// import { dataJson } from "./data.js";

const HubsReportDSMultiSelect = ({
  sheetType,
  ReportID,
  isSubscribed,
  fromDashboard = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [pdfFile, setPdfFile] = useState([]);
  const [error, setError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [arr, setArr] = useState([]);
  const [selectTabs, setSelectTabs] = useState({});
  const [reportPdf, setReportPdf] = useState([]);

  const [selectDimension, setSelectDimension] = useState({});
  const { datasheetReportId, dataSheetData, total_credit, remaining_credit } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [autoComplete, setAutoComplete] = useState();
  const { config, notify, setNotify, userInfo } = useAuth();
  const accountType = userInfo?.account_type;
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedValuesInitial, setSelectedValuesInitial] = useState({});
  const isMobileOrTablet = useMediaQuery("(max-width: 960px)");
  const cookies = new Cookies();
  const [currentValue, setCurrentValue] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // Calculate the start and end index of the current page
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const [downloadAccess, setDownloadAccess] = useState(false);
  const [purchaseTabShow, setPurchaseTabShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredDataSheet, setFilteredDataSheet] = useState([]);
  const [customiseColumn, setCustomiseColumn] = useState(false);
  const [header, setHeader] = useState([]);
  const report_id = cookies.get("HUBID");
  const [metricPosition, setMetricPosition] = useState([]);
  const [metricFirst, setMetricFirst] = useState("");
  const [metricSecond, setMetricSecond] = useState("");
  const [metricBackup, setMetricBackup] = useState("");
  const [metricBackupSecond, setMetricBackupSecond] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCustomisedColumn, setSelectedCustomisedColumn] = useState([]);
  const [selectedCustomisedColumnNew, setSelectedCustomisedColumnNew] =
    useState([]);
  const [scrollText, setScrollText] = useState(false);
  const [customiseColumnData, setCustomiseColumnData] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    id: "",
  });
  const [firstMetricValue, setFirstMetricValue] = useState({
    Metric: "",
    Units: "",
  });
  const [secondMetricValue, setSecondMetricValue] = useState({
    Metric: "",
    Units: "",
  });

  const { Base_URL } = API;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const { selectedReportDetail } = useSelector((state) => state);
  const [noPurchasedReport, setNoPurchasedReport] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Function to handle the change in the Select component

  const handleSelectChange = (value) => {
    setCurrentValue(value);
  };

  const handleRequestAccess = (reportId, reportTitle) => {
    setFormData({
      title: reportTitle,
      id: reportId,
    });
    setDrawerOpen(true);
  };
  const hasAccess = sheetType === "masterData" ? DownloadAccessChecker("hub", +cookies.get("hubId")) : DownloadAccessChecker('report', selectedReportDetail?.report_id || ReportID);
  const toShow = sheetType === "masterData" ? ShowComponentCheck("master data") : true;

  useEffect(() => {
    apiCall();
  }, [currentValue]);

  const apiCall = () => {
    setLoading(true);
    setSelectedValues({});
    setAutoComplete(SetState);
    setTableData(data);
    setArr([]);
    setHeader(customiseColumnData);
    setSelectedCustomisedColumn([]);
    setSelectedCustomisedColumnNew([]);
    setCustomiseColumn(false);
    setSelectedValuesInitial({});
    handleApplyFilter("reset");
    setMetricFirst(metricBackup);
    setMetricSecond(metricBackupSecond);
    setTableData(data);
    setArr([]);
    setAutoComplete(SetState);
    setSelectedValues({});
    if (sheetType === "masterData") {
      const payload = {
        hub_id: cookies.get("hubId"),
        all: currentValue === "All" ? true : false,
      };

      axios
        .post(`${Base_URL}/api/hubs/master`, payload, config)
        .then((res) => {
          if (typeof res.data?.data === "object") {
            setError(false);
            if (res.data?.data.length === 0) {
              setNoPurchasedReport(true);
            } else {
              setData(res.data?.data);
              setTableData(res.data?.data);
              setDownloadAccess(res?.data?.downloadAccess);
              setPurchaseTabShow(res?.data?.purchaseTabShow);
              setNewData(res.data?.data);
              if (Object.keys(res.data?.data[0].volume.data).length > 0) { 
                setScrollText(true);
              }
            }
          } else {
            setError(true);
            setDownloadAccess(false);
          }
        })
        .catch((err) => {
            setError(true);
          console.log(err);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .get(
          `${Base_URL}/api/hubs/showDatasheet?report_id=${
            selectedReportDetail?.report_id || ReportID
          }`,
          config
        )
        .then((res) => {
          if (typeof res.data.data === "object") {
            setError(false);
            if (res.data?.data.length === 0) {
              setNoPurchasedReport(true);
            } else {
              setData(res.data?.data);
              setTableData(res.data?.data);
              setNewData(res.data?.data);
              if (Object.keys(res.data?.data[0].volume.data).length > 0) { 
                setScrollText(true);
              }
            }
          } else {
            setError(true);
          }
        })
        .catch((err) => {
            setError(true);
          console.log(err);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // New code for Dynamic data sheet⬇️

  // exprenet Functions That we are going to create in  here⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️

let TableData = Object.keys(
    data !== undefined &&
      data !== null &&
      data.length !== 0 &&
      data[0].dimensions
  );

  let SetState = {};
  for (let i = 0; i < TableData?.length; ++i) {
    SetState[TableData[i]] = null;
  }

  useEffect(() => {
    setAutoComplete(SetState);
  }, [data]);

  // Here we are filtring the Autocomplete Dropdown or MenuItems from the data

  const selectItems = [];
  const filterData = [];
  let metricIndex = TableData.indexOf("Metric");

  for (let i = 0; i < TableData.length; i++) {
    if (data !== undefined) {
      let item = [...new Map(data?.map((m) => [m[TableData[i]], m])).values()];
      let keyValue = item.map((val) => val[TableData[i]]);
      const cleanedKey = TableData[i].replace(/(_1|\s\(f\)|\s\(est\.)|_/g, " ");
      selectItems.push({ [cleanedKey]: keyValue });
      if (i < metricIndex) {
        filterData.push({ [cleanedKey]: keyValue });
      }
    }
  }

  const handleCheckboxChange = (index) => {
    // Toggle the selected index
    const updatedValues = selectedCustomisedColumn.includes(index)
      ? selectedCustomisedColumn.filter((value) => value !== index)
      : [...selectedCustomisedColumn, index];
    setSelectedCustomisedColumn(updatedValues);
  };

  const handleApplyCustomiseColumn = () => {
    handleApplyFilter("reset");
    if (selectedCustomisedColumn === metricPosition) {
      setHeader(customiseColumnData);
      setSelectedCustomisedColumnNew([]);
    } else {
      const mergedArray = selectedCustomisedColumn.concat(metricPosition);
      const filteredHeader = customiseColumnData?.filter((_, index) =>
        mergedArray.includes(index)
      );
      let check = false;
      for (let i = 0; i < filteredHeader.length; i++) {
        if (filteredHeader[i] === "Metrics" || filteredHeader[i] === "Metric") {
          if (!check) {
            setMetricFirst(i);
            check = true;
          } else {
            setMetricSecond(i);
          }
        }
      }
      setHeader(filteredHeader);
      setSelectedCustomisedColumnNew(mergedArray);
    }
    handlePopoverClose();
  };

  const handleClearCustomiseColumn = () => {
    // Clear all selected indices
    setHeader(customiseColumnData);
    setSelectedCustomisedColumn([]);
    setSelectedCustomisedColumnNew([]);
    setMetricFirst(metricBackup);
    setMetricSecond(metricBackupSecond);
    handlePopoverClose();
  };

  const extractKeys = (obj, parentKey = "") => {
    let result = {};
    for (let key in obj) {
      let currentKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === "object") {
        result = { ...result, ...extractKeys(obj[key], currentKey) };
      } else {
        result[currentKey] = obj[key];
      }
    }
    return result;
  };

  useEffect(() => {
    if (data?.length > 0) {
      const headingValue = [];
      const { dimensions, value, volume } = data[0];

      headingValue.push(...Object.keys(dimensions));
      headingValue.push(...Object.keys(value.headers));
      headingValue.push(...Object.keys(value.data));
      headingValue.push(...Object.keys(volume.headers));
      headingValue.push(...Object.keys(volume.data));
      setCustomiseColumnData(headingValue);
      setHeader(headingValue);

      const resultArray = [];
      let check = false;

      for (let i = 0; i < headingValue.length; i++) {
        if (headingValue[i] === "Metrics" || headingValue[i] === "Metric") {
          resultArray.push(i);
          resultArray.push(i + 1);
          if (!check) {
            check = true;
            setMetricFirst(i);
            setMetricBackup(i);
          } else {
            setMetricSecond(i);
            setMetricBackupSecond(i);
          }
        }
      }
      setMetricPosition(resultArray);
    }
  }, [data]);

  // Here we are filtering the onChange Function from Autocomplete

  //   function handleFilter(key, newValue) {
  //     setSelectDimension((prevState) => ({
  //       ...prevState,
  //       [key]: newValue,
  //     }));
  //   }

  // to download the pdfFlie we use this function here⬇️
  ///////////////////////////

  const downloadPdf = async () => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }
    setNotify({
      isOpen: true,
      message: "Download in Progress",
      type: "success",
      position: "right",
    });

    const dimensionArray = header.slice(0, metricFirst);
    const firstArray = header.slice(metricFirst + 2, metricSecond);
    const secondArray = header.slice(metricSecond + 2, header.length);
    if (sheetType === "masterData") {
      const payload = {
        hub_id: cookies.get("hubId"),
        all: currentValue === "All" ? true : false,
        dimensionsSelected: dimensionArray,
        dimensionsArray: selectedValuesInitial,
        valueArray: firstArray,
        volumeArray: secondArray,
      };

      await axios
        .post(`${Base_URL}/api/hubs/master/download`, payload, config)
        .then((response) => {
          const url = response.data.data.masterME_url;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    } else {
      const payload = {
        report_id: ReportID || selectedReportDetail?.report_id,
        dimensionsSelected: dimensionArray,
        dimensionsArray: selectedValuesInitial,
        valueArray: firstArray,
        volumeArray: secondArray,
      };
      await axios
        .post(`${Base_URL}/api/hubs/datasheetDownload`, payload, config)
        .then((response) => {
          const url = response.data.data.me_url;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  useEffect(() => {
    const temporaryTabs = {};
    if (data?.length > 0) {
      const { dimensions } = data[0];

      Object.entries(dimensions).forEach(([key, value]) => {
        temporaryTabs[key] = [
          ...new Set(data.map((item) => item.dimensions[key])),
        ];
      });
    }
    setFirstMetricValue({
      Metric: data?.[0].value.headers.Metric,
      Units: data?.[0].value.headers.Units,
    });
    setSecondMetricValue({
      Metric: data?.[0].volume.headers.Metric,
      Units: data?.[0].volume.headers.Units,
    });
    setSelectTabs(temporaryTabs);
  }, [data]);

  const clearAllSelectedValue = (item) => {
    handleApplyFilter(item);
  };

  const clearSelectedValue = (key, value, item) => {
    setSelectedValuesInitial((prevState) => {
      const updatedValues = { ...prevState };
      if (
        updatedValues.hasOwnProperty(item) &&
        Array.isArray(updatedValues[item])
      ) {
        // Remove the specific value (item) from the array associated with the dynamic key
        updatedValues[item] = updatedValues[item].filter(
          (val) => val !== value
        );
      }
      return updatedValues;
    });
  };

  // Filtered data
  const filterDataSheetFunction = (item) => {
    let currentStateCopy;
    if (item === "reset") {
      currentStateCopy = [];
      setSelectedValuesInitial(currentStateCopy);
    } else {
      currentStateCopy = { ...selectedValuesInitial };
      Object.keys(currentStateCopy).forEach((key) => {
        // Check if the array is empty
        if (
          Array.isArray(currentStateCopy[key]) &&
          currentStateCopy[key].length === 0
        ) {
          // Remove the key from the object
          delete currentStateCopy[key];
        }
      });
      if (item) {
        // Update the copy
        delete currentStateCopy[item];
        setSelectedValuesInitial(currentStateCopy);
      }
    }
    const shouldDisplayRow = (row) => {
      const { dimensions } = row;
      return Object.entries(currentStateCopy).every(([key, selectedValues]) => {
        const rowValue = dimensions[key];
        return (
          Array.isArray(selectedValues) &&
          selectedValues.some((item) => rowValue.includes(item))
        );
      });
    };

    const filteredData = data?.filter(shouldDisplayRow);

    const temporaryTabs = {};
    if (filteredData?.length > 0) {
      const { dimensions } = filteredData[0];

      Object.entries(dimensions).forEach(([key, value]) => {
        temporaryTabs[key] = [
          ...new Set(filteredData.map((item) => item.dimensions[key])),
        ];
      });
    }
    setSelectTabs(temporaryTabs);

    // Set the filtered data to the state
    setFilteredDataSheet(filteredData);
  };

  useEffect(() => {
    filterDataSheetFunction();
  }, [data]);
  const dimensionLength =
    data?.length > 0 ? Object.keys(data?.[0].dimensions).length : 0;

  useEffect(() => {
    const temporaryTabs = {};
    if (filteredDataSheet?.length > 0) {
      const { dimensions } = filteredDataSheet[0];

      Object.entries(dimensions).forEach(([key, value]) => {
        temporaryTabs[key] = [
          ...new Set(filteredDataSheet.map((item) => item.dimensions[key])),
        ];
      });
    }
    setSelectTabs(temporaryTabs);
  }, [filteredDataSheet]);

  const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(false);
  const [openAutoComplete, setOpenAutoComplete] = useState("");

  const handleButtonClick = (value) => {
    setIsAutocompleteVisible(!isAutocompleteVisible);
    setOpenAutoComplete(value);
  };

  const handleApplyFilter = (item) => {
    // applyFilter(item);
    setSelectedValues(selectedValuesInitial);
    filterDataSheetFunction(item);
    setOpenAutoComplete("");
  };

  const handleClearSelectedValue = () => {
    // clearSelectedValue(item);
    setIsAutocompleteVisible(false);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedValues]);

  // Slice the filtered data to display only the current page's rows
  const pageData = filteredDataSheet?.slice(startIndex, endIndex);
  const totalItems = filteredDataSheet?.length;

  const totalPages = Math.ceil(filteredDataSheet?.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleItemAutocompleteChange = (category, event, value) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [category]: value,
    }));
  };

  const sortData = (columnName, i) => {
    const sortedData = [...filteredDataSheet].sort((a, b) => {
      let valueA = extractValue(a.volume.data, columnName);
      let valueB = extractValue(b.volume.data, columnName);
      if (i < metricFirst) {
        valueA = extractValue(a.dimensions, columnName);
        valueB = extractValue(b.dimensions, columnName);
      } else if (i > metricFirst && i < metricSecond) {
        valueA = extractValue(a.value.data, columnName);
        valueB = extractValue(b.value.data, columnName);
      }

      // Handle sorting for numbers and strings separately
      if (!isNaN(valueA) && !isNaN(valueB)) {
        // Both values are numbers
        if (sortOrder === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      } else {
        // One or both values are non-numeric, use localeCompare for strings
        if (sortOrder === "asc") {
          return String(valueA).localeCompare(String(valueB));
        } else {
          return String(valueB).localeCompare(String(valueA));
        }
      }
    });

    // Toggle the sort order for the next click
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    // Set the sorted data to the state
    setFilteredDataSheet(sortedData);
  };

  // Helper function to extract the value from dimensions
  const extractValue = (dimensions, columnName) => {
    const value = dimensions[columnName];
    return value !== undefined ? value : "";
  };

  return (
    <Container sx={{ position: "relative" }} maxWidth={"xl"}>
      {/* {((!toShow && !loading) || (!isSubscribed && !loading)) && (
        <LockBox
          sx={{
            top: "40%",
            left: "50%",
            zIndex: "5",
          }}
          componentName={sheetType === "masterData"? "Master Data" : "Data Sheet"}
          purchaseCustomText={
            fromDashboard
              ? "Purchase the report to gain access."
              : "Purchase the hub to gain access."
          }
          toShowText={!toShow?"You don't have access for ":false}
        />
      )} */}

      {/* {error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          // width="100vw"
          height="70vh"
          className={!toShow && !loading || !isSubscribed && !loading ? "blur" : ""}
        >
          No Report Purchased
        </Box>
      ) : ( */}
      <Box sx={{ marginTop: sheetType === "masterData" ? "0px" : "48px" }}>
        {/* {(!toShow && !loading) ||
          (!isSubscribed && !loading && <div className="overlay-blur"></div>)} */}
        <Box gap={2}>
          <Box
            sx={{
              // textAlign: "end",
              // mt: ["0px", "0px", "50px"],
              mb: ["0px", "10px", "30px"],
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
            // className={
            //   (!toShow && !loading) || (!isSubscribed && !loading)
            //     ? "blur"
            //     : ""
            // }
            >
              {sheetType !== "masterData" ? (
                ""
              ) : (
                <Box display={"flex"} gap={2}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor:
                        currentValue === "All" ? "#446BA6" : "transparent",
                      color: currentValue !== "All" ? "#5a5a5a" : "#fff",
                    }}
                    variant={currentValue === "All" ? "contained" : "text"}
                    onClick={() => handleSelectChange("All")}
                  >
                    All
                  </Button>
                  <Button
                    variant={
                      currentValue === "Purchased" ? "contained" : "text"
                    }
                    sx={{
                      backgroundColor:
                        currentValue === "Purchased"
                          ? "#446BA6"
                          : "transparent",
                      color: currentValue === "Purchased" ? "#fff" : "#5a5a5a",
                      textTransform: "capitalize",
                      display:
                        purchaseTabShow || isSubscribed ? "unset" : "none",
                    }}
                    onClick={() => handleSelectChange("Purchased")}
                  >
                    Purchased
                  </Button>
                </Box>
              )}
            </Box>
            <Box
              // className={
              //   (!toShow && !loading) || (!isSubscribed && !loading)
              //     ? "blur"
              //     : ""
              // }
              sx={{ display: "flex", gap: "1", ml: "auto" }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  display: "flex",
                  alignItems: "Right",
                  fontWeight: "800",
                  fontSize: "16px",
                  height: "26px",
                  color: "#189CDE",
                  font: "Avenir",
                }}
                disabled={loading}
                // startIcon={
                // }
                onClick={() => {
                  setSelectedValues({});
                  setAutoComplete(SetState);
                  setTableData(data);
                  setArr([]);
                  setHeader(customiseColumnData);
                  setSelectedCustomisedColumn([]);
                  setSelectedCustomisedColumnNew([]);
                  setCustomiseColumn(false);
                  setSelectedValuesInitial({});
                  handleApplyFilter("reset");
                  setMetricFirst(metricBackup);
                  setMetricSecond(metricBackupSecond);
                }}
              >
                <RestartAltIcon sx={{ width: "24px", height: "24px" }} />
                Reset All Filters
              </Button>
              <Box>
                <Button
                  sx={{
                    textTransform: "none",
                    display: "flex",
                    alignItems: "Right",
                    fontWeight: "800",
                    fontSize: "16px",
                    height: "26px",
                    color: "#189CDE",
                    font: "Avenir",
                  }}
                  disabled={loading}
                  // startIcon={
                  // }
                  onClick={handlePopoverOpen}
                >
                  <SettingsSharpIcon sx={{ width: "24px", height: "24px" }} />
                  Customize Columns
                </Button>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box
                    sx={{
                      width: "430px",
                      height: "600px",
                      background: "white",
                      zIndex: "5",
                      color: "black",
                      padding: "24px 0px 16px 0px",
                      borderRadius: "8px",
                      boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
                    }}
                  >
                    <Box
                      width={"100%"}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        color: "#2D2D2D",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {/* <Typography>Dimensions</Typography> */}
                      {customiseColumnData?.map((item, index) => (
                        // metric = 6, metric 10
                        // metric = value, metric volume
                        <Box
                          width={item === "Metric" ? "100%" : "50%"}
                          // key={index}
                          sx={{
                            display: item === "Units" ? "none" : "block",
                            pl: 3,
                            "&:hover": {
                              borderRight: "solid #1976d2 1px",
                              background: "#f1f1f1",
                            },
                          }}
                        >
                          {item === "Metric" ? (
                            <>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", padding: "16px" }}
                              >
                                {index === metricBackup ? (
                                  <>
                                    <span style={{ color: "#191919" }}>
                                      Metric:{" "}
                                    </span>
                                    <span style={{ color: "#5A5A5A" }}>
                                      {firstMetricValue.Metric}{" "}
                                    </span>
                                    <span style={{ color: "#191919" }}>
                                      Units:{" "}
                                    </span>
                                    <span style={{ color: "#5A5A5A" }}>
                                      {firstMetricValue.Units}{" "}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span style={{ color: "#191919" }}>
                                      Metric:{" "}
                                    </span>
                                    <span style={{ color: "#5A5A5A" }}>
                                      {secondMetricValue.Metric}{" "}
                                    </span>
                                    <span style={{ color: "#191919" }}>
                                      Units:{" "}
                                    </span>
                                    <span style={{ color: "#5A5A5A" }}>
                                      {secondMetricValue.Units}{" "}
                                    </span>
                                  </>
                                )}
                              </Typography>
                              <hr />
                            </>
                          ) : (
                            <FormControlLabel
                              sx={{ textTransform: "capitalize" }}
                              key={index}
                              label={
                                typeof item === "string"
                                  ? item.replace(/_/g, " ")
                                  : item
                              }
                              control={
                                <Checkbox
                                  checked={selectedCustomisedColumn.includes(
                                    index
                                  )}
                                  onChange={() => handleCheckboxChange(index)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                    <hr style={{ margin: "16px" }} />

                    <Box
                      sx={{
                        p: "16px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "8px",
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: "white",
                          width: "127px",
                          height: "40px",
                          padding: "8px 0px 8px 0px",
                          textTransform: "none",
                        }}
                        onClick={handleClearCustomiseColumn}
                      >
                        Clear All
                      </Button>
                      <Button
                        sx={{
                          width: "191px",
                          height: "40px",
                          borderRadius: "8px",
                          background: "#189CDE",
                        }}
                        variant="contained"
                        fullWidth
                        onClick={handleApplyCustomiseColumn}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </Box>
              <Box>
                {/* {isSubscribed || downloadAccess ? ( */}
                {userInfo.account_type !== 'view only' && (isSubscribed || downloadAccess) ? (
                  <Button
                    disabled={loading}
                    onClick={() => { 
                      logFileDownloadClick({ fileName: "Data Sheet" });
                      downloadPdf();
                     }}
                    // color="black"
                    // aria-label="delete"
                    // size="large"
                    // href={reportPdf}
                    sx={{
                      textTransform: "none",
                      display: "flex",
                      alignItems: "Right",
                      fontWeight: "800",
                      fontSize: "16px",
                      height: "26px",
                      color: "#189CDE",
                      font: "Avenir",
                    }}
                  >
                    <DownloadIcon sx={{ width: "24px", height: "24px" }} />
                    Download
                  </Button>
                ) : null}
              </Box>
              {/* <IconButton
                  disabled={loading}
                  onClick={()=>window.print()}
                  color="black"
                  aria-label="delete"
                  size="large"
                  // href={reportPdf}
                  sx={{
                    textTransform: "none",
                    display: "flex",
                    alignItems: "Right",
                    fontWeight: "800",
                    fontSize: "16px",
                    height: "26px",
                    color: "#189CDE",
                    font: "Avenir",
                  }}
                >
                  <LocalPrintshopOutlinedIcon fontSize="inherit" />
                  Print
                </IconButton> */}
            </Box>
          </Box>
          {error || noPurchasedReport || !toShow
            ? ""
            : scrollText && (
                <Typography>
                  Scroll to the extreme right of this table for market volumes
                  data
                </Typography>
              )}
          <Box sx={{ display: "flex", gap: "10px", marginTop: "0px" }}>
            {error || noPurchasedReport ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="70vh"
              >
                No Report Purchased
              </Box>
            ) : (
              <Paper
                // className={
                //   (!toShow && !loading) || (!isSubscribed && !loading)
                //     ? "blur"
                //     : ""
                // }
                sx={{ flex: 1, overflow: "hidden" }}
              >
                {loading || !data ? (
                  <Loader />
                ) : (
                  <>
                    <TableContainer sx={{ height: "800px" }}>
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {header?.map((item, i) => {
                              return (
                                <TableCell
                                  key={i}
                                  sx={{
                                    color: "black",
                                    backgroundColor:
                                      i % 2 === 0 ? "#E0EFFA" : "#C8E5F9",
                                    zIndex: "0",
                                    // height: "45px",
                                    "&.MuiTableCell-root": {
                                      paddingY: "6px",
                                    },
                                  }}
                                >
                                  <Box
                                    minWidth={150}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        // width: "240px",
                                        height: "45px",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {/* <FilterAltOutlinedIcon /> */}
                                      {i < metricFirst &&
                                        (openAutoComplete !== item ? (
                                          <IconButton
                                            sx={{ mr: "5px" }}
                                            onClick={() =>
                                              handleButtonClick(item)
                                            }
                                          >
                                            <RiFilter2Line
                                              style={{
                                                color: "#2D2D2D",
                                                fontSize: "inherit",
                                              }}
                                            />
                                            {/* <CiFilter /> */}
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            sx={{ mr: "5px" }}
                                            onClick={() =>
                                              handleButtonClick("")
                                            }
                                          >
                                            {/* <FaFilter /> */}
                                            <RiFilter2Fill
                                              style={{
                                                color: "#2D2D2D",
                                                fontSize: "inherit",
                                              }}
                                            />
                                          </IconButton>
                                        ))}

                                      <Typography
                                        sx={{
                                          whiteSpace: "nowrap",
                                          textTransform: "capitalize",
                                          marginLeft:
                                            item === "Metric" ||
                                            item === "Units"
                                              ? "-70%"
                                              : "0px",
                                        }}
                                        fontWeight={600}
                                        // mb={1}
                                      >
                                        {typeof item === "string"
                                          ? item.replace(/_/g, " ")
                                          : item}
                                      </Typography>
                                      {item !== "Metric" &&
                                        item !== "Units" && (
                                          <IconButton
                                            size="small"
                                            sx={{
                                              ml: "auto",
                                              color: "#2d2d2d",
                                            }}
                                            onClick={() => {
                                              sortData(item, i);
                                            }}
                                          >
                                            <BsChevronExpand
                                              style={{
                                                color: "#2D2D2D",
                                                fontSize: "inherit",
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      {/* <BsChevronExpand/> */}
                                    </Box>

                                    {item === openAutoComplete && (
                                      <Paper
                                        sx={{
                                          width: "256px",
                                          background: "white",
                                          position: "absolute",
                                          zIndex: "5",
                                          top: "50px",
                                          py: "10px",
                                          marginTop: '8px',
                                          marginLeft: '-15px',
                                          border: '1px solid lightgray',
                                        }}
                                      >
                                        <Autocomplete
                                          multiple
                                          disablePortal
                                          options={[
                                            ...(selectTabs[item] || [])
                                              .sort((option1, option2) => {
                                                if (option1.toLowerCase() === "all") return -1;
                                                if (option2.toLowerCase() === "all") return 1;
                                                return option1.toLowerCase().localeCompare(option2.toLowerCase());
                                              })
                                          ]}
                                          size="small"
                                          disableClearable={true}
                                          sx={{
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: "none",
                                              },
                                            backgroundColor: "white",
                                            fontSize: "16px",
                                            color: "#333",
                                            outline: "none",
                                            boxShadow:
                                              "0 2px 2px rgba(0, 0, 0, 0.1)",
                                            width: "100%",
                                            zIndex: "0",
                                          }}
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                label={option}
                                                {...getTagProps({ index })}
                                                onDelete={() =>
                                                  clearSelectedValue(
                                                    index,
                                                    option,
                                                    item
                                                  )
                                                }
                                              />
                                            ))
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              size="small"
                                            />
                                          )}
                                          value={
                                            selectedValuesInitial[item] || []
                                          }
                                          onChange={(e, newValue) => {
                                            setSelectedValuesInitial(
                                              (prevState) => ({
                                                ...prevState,
                                                [item]: newValue,
                                              })
                                            );
                                          }}
                                        />
                                        <hr style={{ margin: "10px 0" }} />

                                        <Box
                                          px={2}
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Button
                                            variant="text"
                                            sx={{
                                              textTransform: "capitalize",
                                            }}
                                            onClick={() =>
                                              clearAllSelectedValue(item)
                                            }
                                          >
                                            Clear all
                                          </Button>
                                          <Button
                                            sx={{
                                              textTransform: "capitalize",
                                            }}
                                            variant="contained"
                                            onClick={handleApplyFilter}
                                          >
                                            Apply
                                          </Button>
                                        </Box>
                                      </Paper>
                                    )}
                                  </Box>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {pageData?.map((item, index) => {
                            const { dimensions, value, volume } = item;
                            const values = [
                              ...Object.values(dimensions),
                              ...Object.values(value.headers),
                              ...Object.values(value.data),
                              ...Object.values(volume.headers),
                              ...Object.values(volume.data),
                            ];

                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0 ? "white" : "#F0F7FC",
                                }}
                              >
                                {values
                                  .filter((value, colIndex) => {
                                    // Check if selectedCustomisedColumn is empty, if so, show all columns
                                    return (
                                      selectedCustomisedColumnNew.length ===
                                        0 ||
                                      selectedCustomisedColumnNew.includes(
                                        colIndex
                                      )
                                    );
                                  })
                                  .map((value, colIndex) => (
                                    <TableCell key={colIndex}>
                                      {value === "Request Access" ? (
                                        <Box
                                          onClick={() =>
                                            handleRequestAccess(
                                              item?.report_id,
                                              item?.report_title
                                            )
                                          }
                                          sx={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            color: "blue",
                                          }}
                                        >
                                          {value}
                                        </Box>
                                      ) : value === "blur" ? (
                                        <Box
                                          sx={{
                                            filter: "blur(4px)",
                                          }}
                                        >
                                          {getRandomNumber(10, 10000)}
                                        </Box>
                                      ) : parseFloat(value).toFixed(2) !==
                                        "NaN" ? (
                                        parseFloat(value).toLocaleString(
                                          "en-US",
                                          {
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : (
                                        value
                                      )}
                                    </TableCell>
                                  ))}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {!filteredDataSheet?.length && (
                        <Box
                          sx={{
                            width: "100vw",
                            height: "80vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography>
                            There is no data found Please reset The filter
                          </Typography>
                        </Box>
                      )}
                    </TableContainer>
                  </>
                )}
              </Paper>
            )}
          </Box>
        </Box>
        {/* Pagination UI */}
        {loading || !data || noPurchasedReport ? (
          ""
        ) : (
          <Box
            // className={
            //   (!toShow && !loading) || (!isSubscribed && !loading) ? "blur" : ""
            // }
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBlock: "20px",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ marginRight: 2 }}>
              {currentPage === 1 ? 1 : (currentPage - 1) * rowsPerPage + 1}-
              {currentPage * rowsPerPage <= totalItems
                ? currentPage * rowsPerPage
                : totalItems}{" "}
              of {totalItems}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              sx={{ marginRight: "5px" }}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>
      {/* )} */}
      <RenewDrawer
        title={formData.title ? formData.title : "Request Access"}
        report_id={formData.id}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={"request"}
      />
    </Container>
  );
};

export default HubsReportDSMultiSelect;
