import React from "react";
import { Modal, Button as ButtonX } from "antd";
import axios from "axios";
import API from "../../../api/Api";
import { useAuth } from "../../../context/authContext/Auth";
import { useDispatch, useSelector } from "react-redux";
import { SET_LIBRARY_CREDITS, SET_ONDEMAND_CREDITS } from "../../Redux/action";
import { authenticateProdgain } from "../../../utils/common";

export const ReportAccess = ({
  report_id,
  title,
  closeModal,
  reloadFunction = () => {},
  from = "library",
  isLibraryHubReport = false,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const { Base_URL } = API;
  const [requestStatus, setRequestStatus] = React.useState("uninitialise");
  const [requestMessage, setRequestMessage] = React.useState("");
  const { config } = useAuth();
  const dispatch = useDispatch();
  const { remaining_library_credit, remaining_ondemand_credit, userData } = useSelector(
    (state) => state
  );

  const handleCancel = () => {
    setRequestStatus("uninitialise");
    setRequestMessage("");
    closeModal();
    // reloadFunction();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);

    let url = `${Base_URL}/api/users`;
    if (from === "library") {
      url += `/decrease-library-credit`;
    } else if (from === "hub_report" || from === "flash") {
      url += `/decrease-demand-credit`;
    }

    if (from === "hub_report" && isLibraryHubReport) {
      // this means report is coming from library tab but it is a hub report
      url = `${Base_URL}/api/users/decrease-library-credit-hub-report`;
    }

    axios({
      url,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: report_id,
        message: requestMessage,
      },
    })
      .then(async (res) => {
        setRequestStatus("success");

        dispatch({
          type:
            (from === "library" || (from === "hub_report" && isLibraryHubReport))
              ? SET_LIBRARY_CREDITS
              : SET_ONDEMAND_CREDITS,
          payload: {
            total_credit: res.data.data.credit.total,
            remaining_credit: res.data.data.credit.remaining,
          },
        });

        await authenticateProdgain();

        reloadFunction();
      })
      .catch((error) => {
        setRequestStatus("failure");
        console.error(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  const handleOk = () => {
    closeModal();
  };
  return (
    <Modal
      title={`${title.toUpperCase()}`}
      open={true}
      onOk={handleOk}
      onCancel={handleCancel}
      width={"800px"}
      styles={{
        width: "800px",
      }}
      footer={null}
    >
      <div className="request-modal">
        {requestStatus === "success" && (
          <div>
            <h1
              style={{
                color: "#1BB1F0",
                fontSize: "32px",
                fontWeight: "bold",
              }}
            >
              Report Unlocked!
            </h1>
            <p className="description">Close this window to start reading.</p>
            <p className="remaining-credits">
              {(from === "library" || (from === "hub_report" && isLibraryHubReport))
                ? `Remaining Library Credits : ${remaining_library_credit}`
                : `Remaining On Demand Credits : ${remaining_ondemand_credit}`}
            </p>
          </div>
        )}

        {requestStatus === "uninitialise" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                marginBottom: "20px",
                color: "rgb(125, 125, 125)",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              {(from === "library" || (from === "hub_report" && isLibraryHubReport))
                ? `Your Library Credits will decrease by 1 on accessing this report.`
                : `Your On Demand Credits will decrease by 1 on accessing this report.`}
            </h3>

            <ButtonX onClick={sendRequest} disabled={isButtonDisabled || (userData.report_purchasing_access === false)}>
              Get Access
            </ButtonX>
          </div>
        )}

        {requestStatus === "failure" && (
          <div>
            <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
              Something Went Wrong!
            </h1>
            <p className="description">Please try in some time.</p>
            <p className="remaining-credits">Your credits do not decrease.</p>
          </div>
        )}
      </div>
    </Modal>
  );
};
