import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Paper, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { BsExclamationTriangle } from "react-icons/bs";
import { GoShieldCheck } from "react-icons/go";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import API from "../../api/Api";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: "24px 32px",
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E0EFFA",
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F0F7FC',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function UserCard({ rows, data, getUsers, handleEditModal }) {
  const { config } = useAuth();
  const [open, setOpen] = useState(false);
  const [removedEmail, setRemovedEmail] = useState({
    email: '',
    active: ''
  })
  const [successfullyRemoved, setSuccessfullyRemoved] = useState(false);
  const [deleteName, setDeleteName] = useState('')
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const { Base_URL } = API

  const handlePopoverOpen = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const isPopoverOpen = Boolean(popoverAnchor);
  const popoverId = isPopoverOpen ? 'custom-popover' : undefined;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenSuccessfullyRemoved = () => setSuccessfullyRemoved(true);
  const handleCloseSuccessfullyRemoved = () => {
    setSuccessfullyRemoved(false)
    getUsers()
  };

  const [anchorEl, setAnchorEl] = useState(Array(rows?.length).fill(null));

  const handleOpenPopover = (event, index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };

  const handleClosePopover = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };



  async function updateUser() {
    handleClose()
    const bodyParameters = {
      email: removedEmail.email,
      active: removedEmail.active === true ? "false" : "true"
    };

    try {
      const response = await axios.patch(
        `${Base_URL}/api/users/action-alias`,
        bodyParameters,
        config
      );

      //   setData(response?.data.data
      if (response?.data.code === 200) {
        handleOpenSuccessfullyRemoved()
      }

    } catch (error) {
      console.error(error);
    } finally {
      // getUsers()
      // handleClose()
    }
  }

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const isAdmin = data?.account_type === 'owner' ? true : false;





  return (
    <>
      <TableContainer component={Paper} >
        <Table >
          <TableHead>
            <TableRow>
              <StyledTableCell >Name</StyledTableCell>
              <StyledTableCell style={{ background: '#C8E5F9' }} >Email</StyledTableCell>
              <StyledTableCell >Created On</StyledTableCell>
              {/* <TableCell >Account</TableCell> */}
              <StyledTableCell style={{ background: '#C8E5F9' }} >Permissions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
           {rows?.map((row, index) => {

              const id = `popover-${index}`;
              const open = Boolean(anchorEl[index]);

              return (<>
                {row.active && <StyledTableRow key={index}   >
                  <TableCell sx={{ color: '#2d2d2d', lineHeight: '21px', fontWeight: '800', fontSize: '14px', textTransform: 'capitalize' }} >{row.name ? row.name : row?.username}</TableCell>
                  <TableCell >{row.email}</TableCell>
                  <TableCell >{formatDate(row?.createdAt)}</TableCell>
                  {/* <TableCell >
                  <p style={{ fontWeight: 'bold' }}>Main Account</p>
                  <a href="" style={{ textDecoration: 'underline' }}>
                    + All sub-accounts
                  </a>
                </TableCell> */}
                  <TableCell >
                    <Box display="flex" justifyContent="space-between">
                      <Box
                        textTransform="uppercase"
                      // backgroundColor={backgroundColor}
                      // padding={1}
                      >
                        <Stack justifyContent={"center"} width='150px' height='41px' alignItems={'center'} backgroundColor='#E6E3FC' borderRadius={1}>
                          <Box textTransform={'uppercase'} fontWeight="800" fontSize={'14px'} lineHeight={'21px'} color={'#5241ce'} >
                            {row?.account_type ? row?.account_type : row?.designation}
                          </Box>
                        </Stack>
                        {/* {row.designation} */}
                      </Box>
                      {isAdmin && <Box>
                        <IconButton onClick={(event) => {
                          handleOpenPopover(event, index)
                          setDeleteName(row.name ? row.name : row?.username)
                        }
                        }>
                          <MoreVertIcon sx={{ color: "#002F75" }} />
                        </IconButton>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl[index]}
                          onClose={() => handleClosePopover(index)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <Box display={'flex'} flexDirection={'column'} width='240px'>
                            <Button fullWidth onClick={() => {
                              // handleOpenSuccessfullyRemoved()
                              handleEditModal(row.name, row.email, row?.account_type, row?.mobile?.country_code, row?.mobile?.phone_number, row?.report_purchasing_access)
                              handleClosePopover(index)
                            }} sx={{ p: "16px 24px", height: '56px', color: 'gray', justifyContent: 'start', gap: '12px', alignItems: 'center' }} startIcon={<FiEdit style={{ width: '21px', height: '21px', color: '#002F75' }} />
                            }>
                              Edit
                            </Button>
                            <hr />
                            <Button fullWidth sx={{ p: "16px 24px", height: '56px', color: 'gray', justifyContent: 'start', gap: '12px', alignItems: 'center' }} onClick={() => {
                              setRemovedEmail({
                                email: row.email,
                                active: row.active
                              })
                              handleOpen()
                              handleClosePopover(index)
                            }} startIcon={<AiOutlineDelete style={{ width: '21px', height: '21px', color: '#002F75' }} />}>
                              Remove
                            </Button>
                          </Box>
                        </Popover>
                      </Box>}
                    </Box>
                  </TableCell>
                </StyledTableRow>}
              </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {
        rows.length < 1 && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "60vh" }}>No user found</Box> 
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ height: '298px' }}>
          <Box >
            <Box textAlign={'center'}>
              <BsExclamationTriangle style={{ width: '55px', height: '48px', color: '#189cde' }} />
            </Box>
            <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '800', lineHeight: '25px', color: '#191919', my: '16px' }} id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to remove <span style={{ textTransform: 'capitalize' }}>{data?.username} </span> ?
            </Typography>
            <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '400', lineHeight: '25px', color: '#5a5a5a' }} id="modal-modal-description">
              This will delete <span style={{ textTransform: 'capitalize', fontWeight: '400' }}>{deleteName} </span> Permanently. You cannot undo this action.
            </Typography>
          </Box>
          <Box display={"flex"} gap={1} height={'52px'}>
            <Button fullWidth sx={{ color: '#002f75', fontSize: '16px', fontWeight: '800', textTransform: 'capitalize' }} onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={updateUser} fullWidth sx={{ fontSize: '16px', fontWeight: '800', textTransform: 'capitalize' }} variant="contained" >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={successfullyRemoved}
        onClose={handleCloseSuccessfullyRemoved}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ height: '212px', padding: '32px 32px 48px 32px' }}>
          <Box textAlign={'center'}>
            <GoShieldCheck style={{ width: '64px', height: '64px', color: '#189cde' }} />
          </Box>
          <Box>
            <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '800', lineHeight: '25px', color: '#191919' }} id="modal-modal-title" variant="h6" component="h2">
              <span style={{ textTransform: 'capitalize' }}>{deleteName} </span> has been
            </Typography>
            <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '800', lineHeight: '25px', color: '#191919' }} id="modal-modal-title" variant="h6" component="h2">
              removed successfully!
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default UserCard