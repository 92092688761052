import { SYNAPSE_API } from "../api/Api";

export const logFileDownloadClick = ({ fileName = '', fileType = "pdf" }) => {
  const location = window.location.href;
  if(window.dataLayer === undefined) {
    console.error("dataLayer is not defined");
    return;
  }
  window.dataLayer.push({
    event: "fileDownload",
    category: "Download Button Click",
    file_type: fileType, 
    fileName: fileName + `.${fileType}`,
    fileCategory: "Reports",
    location,
  });
};

export const logCreditsChange = ({ typeOfCredit, remainingCredit, event }) => {
  const location = window.location.href;
  if(window.dataLayer === undefined) {
    console.error("dataLayer is not defined");
    return;
  }
  window.dataLayer.push({
    event: event || "Credits Changed",
    category: "Credits",
    label: typeOfCredit,
    remaining_credit: remainingCredit,
    location,
  });
}

export const loginGAEvent = async () => {
  if(window.dataLayer === undefined) {
    console.error("dataLayer is not defined");
    return;
  }
  const resp = await SYNAPSE_API.get("/api/users/info");
  const user = resp.data.data;
  const location = window.location.href;

  const trail = {
    start_date: null,
    end_date: null,
  }

  if(user.access_type === "trial" && user.trial_period_expiry) {
    trail.start_date = new Date(user.createdAt).toISOString();
    trail.end_date = new Date(user.trial_period_expiry).toISOString();
  }

  window.dataLayer.push({
    event: "user_login",
    category: "User",
    access_type: user.access_type,
    myra_credits: user.myra_credits,
    ondemand_credits: user.ondemand_credits.remaining,
    library_credits: user.library_credits.remaining,
    ...trail,
    location,
  });
}