import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { Email } from "@mui/icons-material";
import LockIcon from "../../assets/lock.svg";
import RenewDrawer from "../Common/RenewDrawer";

const LockBox = ({
  sx,
  componentName,
  purchaseCustomText = "Purchase the hub to gain access.",
  reportType = "",
  report_id,
  title,
  toShowText,
  fromHub = undefined,
}) => {

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          background: "#FFF",
          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
          borderRadius: "8px",
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: "2rem",
          zIndex: "100",
          width: "504px",
          ...sx,
        }}
      >
        <img
          src={LockIcon}
          alt="locked-content"
          style={{ fontSize: "30px", color: "#189CDE", marginBottom: "1rem" }}
        />
        <p
          style={{
            color: "#191919",
            fontWeight: "800",
            marginBottom: "0.5rem",
            fontSize: "16px",
          }}
        >
          Unlock {componentName}
        </p>
        <p style={{ color: "#5A5A5A", fontSize: "16px", marginBottom: "1rem" }}>
          {toShowText ? `${toShowText} ${componentName}` : purchaseCustomText}
        </p>
        <Button
          startIcon={<Email />}
          style={{
            backgroundColor: "#189CDE",
            color: "white",
            borderRadius: "8px",
            textTransform: "none",
            width: "100%",
            padding: "0.5rem",
          }}
          variant="contained"
          onClick={() => {
            setDrawerOpen(true);
          }}
        >
         { reportType === "sample" ? "Request Sample" : " Talk to Us"}
        </Button>
      </Box>
    { reportType === 'sample'? (
       <RenewDrawer
       report_id={report_id}
       title={title}
       drawerOpen={drawerOpen}
       setDrawerOpen={setDrawerOpen}
       typeModal={"request sample"}
       componentName={componentName}
       fromHub={fromHub}
     />
    )
     : (<RenewDrawer
        title={"Talk To Us"}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={ "contact"}
        fromCompanies={componentName === "Companies"}
        componentName={componentName}
        fromHub={fromHub}
      />)}
      {/* <HubsHomeChatDialog
        open={open}
        setOpen={setOpen}
        notify={notify}
        setNotify={setNotify}
      /> */}
    </>
  );
};

export default LockBox;
