import styles from "./styles.module.css";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import SynapseLogo from '../../../src/assets/Synapse_logo.svg'
import SynapseLogo from "../../assets/Synapse_logo.svg";
const driftImg =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/drift_design.svg";
const mainLogo =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/logo.svg";
const heroBanner1 =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/hero_banner_1.svg";
const heroBanner2 =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/hero_banner_2.svg";

function UserAccessHOC() {
  const p = useLocation();
  const Year = new Date().getFullYear();
  const cookies = new Cookies();
  const navigate = useNavigate();

  useEffect(() => {
    const element = document.getElementById('fc_frame');
    if (element) { 
      element.style.display= 'none';
    }
    if (cookies.get("TOKEN")) {
      navigate("/hubs", { replace: true });
    }
    return () => {};
  }, []);


  return (
    <div className={styles.wrapper}>
      <img src={driftImg} alt="driftImg" className={styles.driftSection} />
      <div className={styles.mainContainer}>
        <div className={styles.topBar}>
        <a href="https://www.mordorintelligence.com/synapse" target="_blank" rel="noreferrer">
          <img src={SynapseLogo} alt="main logo" />
        </a>
        </div>
        <div className={styles.mainDetailsContainer}>
          <section className={styles.leftSection}>
            <div className={styles.textWrapper}>
              <h4 className={styles.nametag}>
                Delivering Competitive Edge,{" "}
                <span>Turning Precise Data into Decisions</span>
              </h4>
              <h4 className={styles.nametag}>
                Mapping Complex Business Ecosystems,{" "}
                <span>Predicting Butterfly Effects</span>
              </h4>
            </div>
            <div className={styles.imgWrapper}>
              <img
                src={heroBanner1}
                alt="hero banner 1"
                className={styles.bgFade}
              />
              <img
                src={heroBanner2}
                alt="hero banner 2"
                className={styles.bgFade2}
              />
            </div>
          </section>
          <section className={styles.rightSection}>
            <Outlet />
          </section>
        </div>
      </div>
      <div className={styles.credit}>
        © {Year}. All Rights Reserved to Mordor Intelligence.
      </div>
    </div>
  );
}

export default UserAccessHOC;
