import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { Modal, Button as ButtonX } from "antd";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import React, { useState } from "react";
import ChatBox from "../Common/ChatBox";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { BookmarkBorder } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CURRENT_REPORT_DETAIL,
  SET_ONDEMAND_CREDITS,
  SET_LIBRARY_CREDITS,
} from "../Redux/action";
import RenewDrawer from "../Common/RenewDrawer";
import API from "../../api/Api";
import {
  authenticateProdgain,
  checkAndUpdateDownloadCredit,
  displayNoDownloadCredit,
} from "../../utils/common";
import { setDownloadCredits } from "../Redux/action";
import DownloadAccessChecker from "../Common/DownloadOptionCheck/DownloadAccessChecker";
import { logFileDownloadClick } from "../../utils/ga";

const HubsReportCardForReports = ({
  currentTab,
  report_id,
  title,
  favourite,
  subscribed,
  slug,
  getReportData,
  dimensions,
  hub_type,
  purchased_on,
  renewal_on,
  companyLogos,
  subscribed_on,
  report_expiry,
  hubId,
  ids = {},
  category = {},
  company = {},
  published_year = null,
  report_sample_url,
  report_url,
  hubTitle,
  hubSlug,
  new_description_order_0,
  country,
  geography,
  regions_covered,
  sample_info = {},
  study_period,
  countries_covered,
  reqReportIdsList,
  is_requested_catalogue_report = false,
  format = "pdf, excel",
  instant_access = false,
  report_version,
  fromLibrary = false,
  alias_accessed_report = null,
  custom,
  isReportFlash = false,
  canPurchase = true
}) => {
  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [requestStatus, setRequestStatus] = useState("uninitialise");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [requestedReports, setRequestedReports] = useState([]);

  const { Base_URL } = API;

  const location = useLocation();

  const { config, setNotify, userInfo, setCurrentTab } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    total_credit,
    remaining_credit,
    remaining_ondemand_credit,
    remaining_library_credit,
  } = useSelector((state) => state);

  // getForeCastFromTitle
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const view = Boolean(anchorEl);
  const id = view ? "simple-popover" : undefined;

  const currentDate = new Date();
  const upcoming30Days = new Date();
  upcoming30Days.setDate(currentDate.getDate() + 30);

  const isExpiringWithin30Days =
    new Date(report_expiry) > currentDate &&
    new Date(report_expiry) <= upcoming30Days;

  const hasAccess = DownloadAccessChecker("report", report_id);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRequestStatus("uninitialise");
    setRequestMessage("");
  };

  async function getAllMessages() {
    setLoading(true);
    setMessageData([]);

    axios
      .get(`${Base_URL}/api/hubs/showChat?reportId=${report_id}`, config)
      .then((res) => {
        if (typeof res.data.data === "object") {
          setMessageData(res.data?.data);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // Send messages from here

  async function sendMessage(msg) {
    const bodyParameters = {
      message: msg,
      reportId: report_id,
      url: window.location.href,
    };

    axios
      .post(`${Base_URL}/api/hubs/chat`, bodyParameters, config)
      .then((res) => {
        getAllMessages();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
    getAllMessages();
  };

  async function forceDownload({ report_url, report_sample_url }, pdf_name) {
    const urlToDownload = subscribed === "yes" ? report_url : report_sample_url;
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    setPdfLoading(true);

    setNotify({
      isOpen: true,
      message: "Download in Progress",
      type: "success",
      position: "right",
    });

    return new Promise(function (resolve, reject) {
      var x = new XMLHttpRequest();
      x.open("GET", urlToDownload, true);
      x.responseType = "blob";
      x.onload = function (e) {
        resolve(x.response);
      };
      x.onerror = function () {
        reject(new Error("Error occurred while downloading PDF."));
      };
      x.send();
    })
      .then(async function (pdf_blob) {
        saveAs(pdf_blob, pdf_name, "application/pdf");
        setNotify({
          isOpen: true,
          message: "Download Completed",
          type: "success",
          position: "right",
        });

        if (total_credit != null) {
          let credits = await checkAndUpdateDownloadCredit(config);

          dispatch(
            setDownloadCredits(credits.total_credit, credits.remaining_credit)
          );
        }
        setPdfLoading(false);
      })
      .catch(function (error) {
        setNotify({
          isOpen: true,
          message: "Download Failed",
          type: "error",
          position: "right",
        });

        setPdfLoading(false);
        console.log(error);
      });
  }

  // for bookmark or make favorite your report id
  async function makeThisFavorite(reportId) {
    setLoading(true);
    const bodyParameters = {
      report_id: reportId,
    };
    const url = `${Base_URL}/api/hubs/favourite`;
    axios
      .post(url, bodyParameters, config)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data?.message,
          type: "success",
          position: "right",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getReportData();
        setLoading(false);
      });
  }

  const handleReportRedirect = (slug, clickedAt) => {
    const setReportData = {
      report_id,
      title,
      subscribed,
      report_url,
      report_sample_url,
      slug,
      getReportData,
      dimensions,
      hub_type,
      study_period,
      purchased_on,
      renewal_on,
      published_year,
    };

    dispatch({
      type: SET_CURRENT_REPORT_DETAIL,
      payload: setReportData,
    });
    if (clickedAt === "view_sample") {
      setCurrentTab(2);
    }

    const searchParams = new URLSearchParams(location.search);
    const fromLibrary =
      searchParams.get("tab") === "REPORTS_LIBRARY" ? true : false;

    let navigateTo = `${slug}`;
    if(isReportFlash) {
      navigateTo = `flash/${slug}`;
    }

    return navigate(navigateTo, {
      state: { cameFrom: currentTab, fromLibrary: fromLibrary },
    });
  };

  // for make This  unfavorite your report id

  async function makeThisUnFavorite(reportId) {
    setLoading(true);
    const bodyParameters = {
      report_id: reportId,
    };
    const url = `${Base_URL}/api/hubs/unFavourite`;
    axios
      .post(url, bodyParameters, config)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data?.message,
          type: "success",
          position: "right",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getReportData();
        setLoading(false);
      });
  }

  const isDownloadDisabled = (sample_url, subscribed) => {
    if (!sample_url && !report_url) return true;
    else if (subscribed === "yes" && !report_url) return true;
    else if (subscribed === "no" && !sample_url) return true;
    else return false;
  };

  // Function to format date
  function formatDate(stringDate) {
    const date = new Date(stringDate);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();
    return `${day} ${months[monthIndex]}, ${year}`;
  }

  const buyReport = (
    subscribed,
    currentTab,
    remaining_ondemand_credit,
    remaining_library_credit,
    is_requested_catalogue_report
  ) => {

    const creditFrom = fromLibrary === true ? remaining_library_credit : remaining_ondemand_credit;

    if (subscribed === "yes" || currentDate < new Date(report_expiry)) {
      return null;
    } else if (creditFrom != 0) {
      return null;
    } else if (creditFrom) {
      return null;
    }

    return (
      <Tooltip title="Buy This Report" placement="top-start">
        <Button
          disabled={canPurchase === false}
          sx={{
            backgroundColor: "#189CDE",
            textTransform: "capitalize",
          }}
          onClick={() => {
            setDrawerOpen(true);
            setTypeModal("buy");
          }}
          variant="contained"
        >
          Buy Now
        </Button>
      </Tooltip>
    );
  };

  const getAccess = (
    subscribed,
    currentTab,
    remaining_ondemand_credit,
    remaining_library_credit,
    is_requested_catalogue_report,
    reqReportIdsList,
    report_id
  ) => {

    const creditFrom = fromLibrary === true ? remaining_library_credit : remaining_ondemand_credit;

    if (subscribed === "yes" && currentDate < new Date(report_expiry)) {
      return null;
    } else if (creditFrom === 0) {
      return null;
    } else if (
      creditFrom !== 0 &&
      !is_requested_catalogue_report
    ) {
      return (
        <Tooltip title="Access This Report" placement="top-start">
          <Button
            disabled={canPurchase === false}
            sx={{
              backgroundColor: "#189CDE",
              textTransform: "capitalize",
            }}
            onClick={() => {
              showModal();
            }}
            variant="contained"
          >
            Get Access
          </Button>
        </Tooltip>
      );
    }
  };

  const sendRequest = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    let reqURL;
    if (fromLibrary) {
      reqURL = `${Base_URL}/api/users/decrease-library-credit-hub-report`;
    } else {
      reqURL = `${Base_URL}/api/users/decrease-demand-credit`;
    }

    axios({
      url: reqURL,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: report_id,
        message: requestMessage,
      },
    })
      .then(async (res) => {
        setRequestStatus("success");

        if (fromLibrary) {
          dispatch({
            type: SET_LIBRARY_CREDITS,
            payload: {
              total_credit: res.data.data.credit.total,
              remaining_credit: res.data.data.credit.remaining,
            },
          });
        } else {
          dispatch({
            type: SET_ONDEMAND_CREDITS,
            payload: {
              total_credit: res.data.data.credit.total,
              remaining_credit: res.data.data.credit.remaining,
            },
          });
        }
        await authenticateProdgain();
        getReportData();
      })
      .catch((error) => {
        setRequestStatus("failure");
        console.error(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  const getForeCastFromTitle = (title) => {
    let forecast = title.match(/(20[0-9]{2})/g);
    return forecast ? forecast[0] === "2024" : false;
  };

  const handleDownloadModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadModalClose = (event) => {
    setAnchorEl(null);
  };

  const Open = Boolean(anchorEl);
  const popId = Open ? "popover" : undefined;

  return (
    <>
      <Card
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid transparent",
          marginBottom: "2rem",
          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
          "&:hover": {
            border: "1px solid #189CDE",
          },
        }}
      >
        <ChatBox
          setOpen={setOpen}
          open={open}
          title={title}
          data={messageData}
          loading={loading}
          report_id={report_id}
          getAllMessages={getAllMessages}
          sendMessage={sendMessage}
          setLoading={setLoading}
        />
        <CardContent
          sx={{
            padding: "0px",
            //Remove padding from last child
            "&:last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          <Box
            component="div"
            sx={{ background: "#E0EFFA", padding: "0.3rem" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {favourite === "yes" ? (
                <Tooltip title="Unbookmark" placement="top-start">
                  <BookmarkIcon
                    onClick={() => {
                      makeThisUnFavorite(report_id);
                    }}
                    sx={{
                      marginLeft: "1.2rem",
                      color: "#002F75",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Bookmark" placement="top-start">
                  <BookmarkBorder
                    onClick={() => {
                      makeThisFavorite(report_id);
                    }}
                    sx={{
                      marginLeft: "1.2rem",
                      color: "#002F75",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              )}

              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  fontFamily: "Avenir Roman !important",
                }}
                style={{
                  marginLeft: "0.8rem",
                  marginTop: "0.2rem",
                  maxWidth: "calc(100% - 131px)",
                }}
                className="title-16"
                onClick={() => {
                  setCurrentTab(0);
                  handleReportRedirect(slug, "title");
                }}
              >
                {title.toUpperCase()}
              </Typography>
              {is_requested_catalogue_report && subscribed === "no" ? (
                <span className="request-tag">Requested</span>
              ) : null}
            </div>
          </Box>
          <div id="card_body" className="card_body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E4E4E4",
              }}
              id="section-one"
            >
              <div id="section-one_1">
                {hub_type && (
                  <Chip
                    label={`Hub: ${hub_type}`}
                    style={{
                      backgroundColor: "#FFEDC7",
                      color: "#A94D19",
                      fontFamily: "Avenir Roman",
                      height: "26px",
                      textTransform: "capitalize",
                    }}
                    sx={{
                      //Modify the chip span font
                      "& span": {
                        fontFamily: "Avenir Heavy",
                      },
                      marginBottom: "0.5rem",
                    }}
                  />
                )}
                {hub_type && (
                  <div>
                    <span
                      style={{
                        color: "#2D2D2D",
                        fontFamily: "Avenir Heavy",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Published Date:
                    </span>{" "}
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {`${formatDate(published_year)}`}
                    </span>
                  </div>
                )}
                <div>
                  <span
                    style={{
                      color: "#2D2D2D",
                      fontFamily: "Avenir Heavy",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Study Period:
                  </span>{" "}
                  {study_period && (
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {`${study_period?.[0]}-${study_period?.[1]}`}
                    </span>
                  )}
                </div>
                <div>
                  <span
                    style={{
                      color: "#2D2D2D",
                      fontFamily: "Avenir Heavy",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Format:{" "}
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {format}
                    </span>
                  </span>
                </div>
                <RegionOrCountryCovered
                  {...{
                    geography,
                    regions_covered,
                    countries_covered,
                    country,
                  }}
                />
              </div>
              <CompanyLogosDisplay companyLogos={companyLogos} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0rem 2.6rem",
            }}
            id="section-two"
          >
            {/* <div id="section-two-text"> */}
            <div style={{ paddingTop: "0.8rem" }}>
              {!purchased_on && !renewal_on && !report_expiry ? (
                subscribed === "no" &&
                (instant_access || getForeCastFromTitle(title)) ? (
                  report_version == null ? (
                    <>
                      {/* <strong>Latest Version of the report available.</strong> */}
                    </>
                  ) : report_version.length > 1 ? (
                    <>
                      {/* <strong
                        onClick={handleClick}
                        aria-describedby={id}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        Latest Version of the report available in Reports
                        Libray, Click here to see this report in the library
                      </strong> */}
                      <Popover
                        id={id}
                        open={view}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {report_version?.map((item, i) => {
                          return (
                            <Box px="10vw" py={"10px"}>
                              <Link
                                to={`/reports/reports-library/${item.slug}`}
                              >
                                {item?.title} {item?.forecast_year}
                              </Link>
                            </Box>
                          );
                        })}
                      </Popover>
                    </>
                  ) : (
                    <>
                      {/* <strong style={{ fontSize: "14px", color: "black" }}>
                        {" "}
                        <Link
                          style={{ color: "black" }}
                          to={`/reports/reports-library/${report_version?.[0]?.slug}`}
                        >
                          Latest Version of the report available in Reports
                          Libray Click here to see this report in the library{" "}
                        </Link>
                      </strong> */}
                    </>
                  )
                ) : (
                  <>
                    {subscribed === "no" && (
                      <strong>* 24-72 Hrs Delivery Time</strong>
                    )}
                  </>
                )
              ) : null}

              {/* {(purchased_on && renewal_on) || report_expiry ? ( */}

              {(purchased_on && renewal_on) || report_expiry ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>{`Purchased On: ${formatDate(
                    purchased_on ? purchased_on : subscribed_on
                  )}`}</div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "0.2rem",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "4px",
                        borderRadius: "50%",
                        backgroundColor: "#5A5A5A",
                        margin: "0px 0.5rem",
                      }}
                    ></div>
                  </div>
                  <div>{`Renewal On: ${
                    purchased_on
                      ? formatDate(renewal_on)
                      : formatDate(report_expiry)
                  }`}</div>
                  {alias_accessed_report && (
                    <div>
                      <Chip
                        label={`Purchased by Alias: ${alias_accessed_report.alias_name}`}
                        sx={{
                          backgroundColor: "#FFEDC7",
                          color: "#A94D19",
                          fontFamily: "Avenir Roman",
                          height: "26px",
                          marginLeft: "1rem",
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </div>

            <div id="section-two-button">
              {/* Add Icon Button */}
              {subscribed === "yes" && (
                <Tooltip
                  title={
                    subscribed === "no" || currentDate > new Date(report_expiry)
                      ? "Show Sample Report"
                      : "Show Report"
                  }
                  placement="top-start"
                >
                  <IconButton
                    sx={{
                      //onHover showing pointer
                      cursor: "pointer",
                    }}
                    aria-label="delete"
                    size="large"
                    onClick={() => {
                      setCurrentTab(0);
                      handleReportRedirect(slug, "eye-icon");
                    }}
                  >
                    <RemoveRedEyeIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {hasAccess && !hubId && custom ? (
                <div style={{ display: "inline-block" }}>
                  <Tooltip title={"Download Report"} placement="top-start">
                    <IconButton
                      onClick={(e) => {
                        logFileDownloadClick({ fileName: title });
                        handleDownloadModalOpen(e);
                      }}
                      size="large"
                      // disabled={isDownloadDisabled(
                      //   sample_info.sample_url,
                      //   subscribed
                      // )}
                    >
                      <DownloadIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    id={id}
                    open={Open}
                    anchorEl={anchorEl}
                    onClose={handleDownloadModalClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {!custom?.report_pdfs?.length ? (
                      <p style={{ padding: "10px" }}>No reports present</p>
                    ) : (
                      custom?.report_pdfs.map((pdf) => {
                        let pdfUrl = pdf?.url;
                        let pdfTitle = pdf.file_name || pdf.fileName;
                        return (
                          <p>
                            <Button
                              sx={{ width: "100%" }}
                              onClick={() => {
                                forceDownload({ report_url: pdfUrl }, pdfTitle);
                              }}
                              disabled={false}
                            >
                              {pdfTitle}
                            </Button>
                          </p>
                        );
                      })
                    )}
                  </Popover>
                </div>
              ) : (
                hasAccess && sample_info && sample_info?.sample_url !== null && (
                  <Tooltip
                    title={
                      subscribed === "no" ||
                      currentDate > new Date(report_expiry)
                        ? "Download Sample"
                        : "Download Report"
                    }
                    placement="top-start"
                  >
                    <IconButton
                      onClick={() => {
                        logFileDownloadClick({ fileName: title })
                        forceDownload(
                          {
                            report_url,
                            report_sample_url: sample_info.sample_url,
                          },
                          title
                        )
                      }}
                      size="large"
                      disabled={isDownloadDisabled(
                        sample_info.sample_url,
                        subscribed
                      )}
                    >
                      <DownloadIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )
              )}

              <Tooltip title="Ask Query" placement="top-start">
                <IconButton
                  onClick={handleClickOpen}
                  aria-label="delete"
                  size="large"
                >
                  <ChatBubbleOutlineIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

              {currentDate > new Date(report_expiry) &&
                sample_info && sample_info?.sample_url !== null && (
                  <Tooltip title="View Sample Report" placement="top-start">
                    <Button
                      onClick={() => handleReportRedirect(slug, "view_sample")}
                      variant="outlined"
                      color="primary"
                      disabled={report_sample_url === null}
                      sx={{
                        marginRight: "1rem",
                        marginLeft: "1rem",
                        textTransform: "capitalize",
                      }}
                    >
                      View Sample
                    </Button>
                  </Tooltip>
                )}

              {subscribed !== "yes" && sample_info && sample_info?.sample_url === null && (
                <Tooltip title="Request Sample" placement="top-start">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setDrawerOpen(true);
                      setTypeModal("request sample");
                    }}
                    // disabled={report_sample_url === null}
                    sx={{
                      marginRight: "1rem",
                      marginLeft: "1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Request Sample
                  </Button>
                </Tooltip>
              )}

              {buyReport(
                subscribed,
                currentTab,
                remaining_ondemand_credit,
                remaining_library_credit,
                is_requested_catalogue_report
              )}
              {getAccess(
                subscribed,
                currentTab,
                remaining_ondemand_credit,
                remaining_library_credit,
                is_requested_catalogue_report,
                reqReportIdsList,
                report_id
              )}
              {subscribed !== "no" && isExpiringWithin30Days && (
                <Tooltip title="Renew This Report" placement="top-start">
                  <Button
                    disabled={canPurchase === false}
                    sx={{
                      backgroundColor: "#189CDE",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (remaining_ondemand_credit > 0) {
                        showModal();
                      } else {
                        setDrawerOpen(true);
                        setTypeModal("renewal");
                      }
                    }}
                  >
                    Renew
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          {/* </div> */}
        </CardContent>
      </Card>

      <RenewDrawer
        report_id={report_id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={typeModal}
      />

      {/* {console.log(hubId, hub_type, title.toUpperCase() )} */}

      <Modal
        title={`${title.toUpperCase()}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"800px"}
        styles={{
          width: "800px",
        }}
        footer={null}
      >
        {fromLibrary ? (
          <div className="request-modal">
            {requestStatus === "success" && (
              <div>
                <h1
                  style={{
                    color: "#1BB1F0",
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  Report Unlocked!
                </h1>
                <p className="description">
                  Close this window to start reading.
                </p>
                <p className="remaining-credits">
                  Remaining Library Credits :{" "}
                  <span> {`${remaining_library_credit}`} </span>
                </p>
              </div>
            )}

            {requestStatus === "uninitialise" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    marginBottom: "20px",
                    color: "rgb(125, 125, 125)",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                >
                  Viewing this report will cost 1 credit.
                </h3>
                <p
                  style={{
                    marginBottom: "20px",
                    color: "rgb(125, 125, 125)",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  {" "}
                  Current Library Credits :
                  <span style={{ color: "#1BB1F0" }}>
                    {remaining_library_credit}
                  </span>
                </p>

                <ButtonX onClick={sendRequest} disabled={isButtonDisabled}>
                  Get Access
                </ButtonX>
              </div>
            )}

            {requestStatus === "failure" && (
              <div>
                <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                  Something Went Wrong!
                </h1>
                <p className="description">Please try in some time.</p>
                <p className="remaining-credits">
                  Your credits do not decrease.
                </p>
              </div>
            )}
          </div>
        ) : location.pathname.includes("purchase-history") ? (
          <div className="request-modal">
            {requestStatus === "success" && (
              <div>
                <h1
                  style={{
                    color: "#1BB1F0",
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  Report Unlocked!
                </h1>
                <p className="description">
                  Close this window to start reading.
                </p>
                <p className="remaining-credits">
                  Remaining Ondemand Credits :{" "}
                  <span> {`${remaining_ondemand_credit}`} </span>
                </p>
              </div>
            )}

            {requestStatus === "uninitialise" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    marginBottom: "20px",
                    color: "rgb(125, 125, 125)",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                >
                  Viewing this report will cost 1 credit.
                </h3>
                <p
                  style={{
                    marginBottom: "20px",
                    color: "rgb(125, 125, 125)",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  {" "}
                  Current Ondemand Credits :
                  <span style={{ color: "#1BB1F0" }}>
                    {remaining_ondemand_credit}
                  </span>
                </p>

                <ButtonX onClick={sendRequest} disabled={isButtonDisabled}>
                  Get Access
                </ButtonX>
              </div>
            )}

            {requestStatus === "failure" && (
              <div>
                <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                  Something Went Wrong!
                </h1>
                <p className="description">Please try in some time.</p>
                <p className="remaining-credits">
                  Your credits do not decrease.
                </p>
              </div>
            )}
          </div>
        ) : hub_type != ""
        ? <div className="request-modal">
        {requestStatus === "success" && (
          <div>
            <h1
              style={{
                color: "#1BB1F0",
                fontSize: "32px",
                fontWeight: "bold",
              }}
            >
              Report Unlocked!
            </h1>
            <p className="description">
              Close this window to start reading.
            </p>
            <p className="remaining-credits">
              Remaining Ondemand Credits :{" "}
              <span> {`${remaining_ondemand_credit}`} </span>
            </p>
          </div>
        )}

        {requestStatus === "uninitialise" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                marginBottom: "20px",
                color: "rgb(125, 125, 125)",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              Viewing this report will cost 1 credit.
            </h3>
            <p
              style={{
                marginBottom: "20px",
                color: "rgb(125, 125, 125)",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              {" "}
              Current Ondemand Credits :
              <span style={{ color: "#1BB1F0" }}>
                {remaining_ondemand_credit}
              </span>
            </p>

            <ButtonX onClick={sendRequest} disabled={isButtonDisabled}>
              Get Access
            </ButtonX>
          </div>
        )}

        {requestStatus === "failure" && (
          <div>
            <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
              Something Went Wrong!
            </h1>
            <p className="description">Please try in some time.</p>
            <p className="remaining-credits">
              Your credits do not decrease.
            </p>
          </div>
        )}
      </div>
        :(
          <div className="request-modal">
            {requestStatus === "success" && (
              <div>
                <h1
                  style={{
                    color: "#1BB1F0",
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  Thanks for the request!
                </h1>
                <p className="description">
                  Our sales representative will be working on the same.
                </p>
                <p className="remaining-credits">
                  Remaining Ondemand Credits :
                  <span>{`${remaining_ondemand_credit}`}</span>
                </p>
              </div>
            )}
            {requestStatus === "uninitialise" && (
              <form>
                  <p style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}> Current Ondemand Credits : 
                <span style={{color: "#1BB1F0"}}>
                  {remaining_ondemand_credit}
                </span> 
                </p>
                <label>Message</label>
                <textarea
                  value={requestMessage}
                  onChange={(e) => {
                    setRequestMessage(e.target.value);
                  }}
                  placeholder="Your Message Here"
                />
                {requestMessage.length < 3 ? (
                  <small>Please type a valid message.</small>
                ) : null}
                <ButtonX
                  className="req-btn"
                  onClick={sendRequest}
                  disabled={isButtonDisabled || requestMessage.length < 3}
                >
                  Request Access
                </ButtonX>
              </form>
            )}

            {requestStatus === "failure" && (
              <div>
                <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                  Something Went Wrong!
                </h1>
                <p className="description">Please try in some time.</p>
                <p className="remaining-credits">
                  Your credits do not decrease.
                </p>
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default HubsReportCardForReports;

const RegionOrCountryCovered = ({
  geography,
  regions_covered,
  countries_covered,
  country,
}) => {
  const pluralizedText = (text, hasMultipleValue) => {
    if (!hasMultipleValue) return text;
    if (hasMultipleValue.indexOf(",") !== -1) {
      return text === "Region" ? "Regions" : "Countries";
    }
    return text;
  };

  const areaCovered =
    geography === "Global"
      ? "Regions"
      : pluralizedText("Country", countries_covered);
  const coveredArea =
    geography === "Global"
      ? regions_covered
      : geography === "Region"
      ? countries_covered
      : country;

  return (
    <div>
      <span
        style={{
          color: "#2D2D2D",
          fontFamily: "Avenir Heavy",
          fontSize: "14px",
          fontWeight: "400",
        }}
      >
        {areaCovered} Covered:
      </span>{" "}
      <span
        style={{
          color: "#5A5A5A",
          fontFamily: "Avenir Roman",
        }}
      >
        {coveredArea?.replace(/,/g, ", ")}
      </span>
    </div>
  );
};

const CompanyLogosDisplay = ({ companyLogos }) => {
  if (!companyLogos || !companyLogos.length) return <></>;

  const getImageGridStyle = (index) => {
    const baseStyle = {
      flex: "1",
      overflow: "hidden",
    };

    switch (companyLogos.length) {
      case 1:
        return {
          ...baseStyle,
          flexGrow: "1",
        };
      case 2:
        return {
          ...baseStyle,
          flexGrow: "1",
          flexBasis: "50%",
        };
      case 3:
        return {
          ...baseStyle,
          flexGrow: index < 2 ? "1" : "2",
          flexBasis: index < 2 ? "50%" : "100%",
        };
      default:
        return {
          ...baseStyle,
          flexGrow: "1",
          flexBasis: "50%",
        };
    }
  };

  return (
    <div id="section-one_2">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "150px",
          maxHeight: "150px",
          margin: "5px",
        }}
      >
        {companyLogos.map((item, index) => (
          <div key={index} style={getImageGridStyle(index)}>
            {item ? (
              <img
                key={index}
                style={{
                  borderRadius: "6.67px",
                  margin: "5px",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  // aspectRatio: "3/4"
                }}
                src={item}
                alt={"logo"}
              />
            ) : (
              <div
                style={{
                  borderRadius: "6.67px",
                  margin: "5px",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
