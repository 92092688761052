import React, { useRef, useState, useCallback } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import "../../styles/datasheetDetails.css";
import { FaFileExcel } from "react-icons/fa6";
import saveAs from "file-saver";
import DownloadAccessChecker from "../Common/DownloadOptionCheck/DownloadAccessChecker";
import { logFileDownloadClick } from "../../utils/ga";

// Memoized functional component
const DatasheetDetailsTab = React.memo(({ reportExcel }) => {
  const iframeRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(
    reportExcel[reportExcel.length - 1]
  );

  // Use useCallback to memoize the handleFileChange function
  const handleFileChange = useCallback(
    (event) => {
      const fileName = event.target.value;
      const selectedFile = reportExcel.find(
        (file) => file.file_name === fileName
      );
      setSelectedFile(selectedFile);
    },
    [reportExcel]
  );

  // Use useCallback to memoize the cleanFileName function
  const cleanFileName = useCallback((input) => {
    let cleaned = input.replace(/\d{1,2}\/\d{1,2}\/\d{4}$/, "").trim();
    cleaned = cleaned.replace(/\s+/g, " ");
    return cleaned.replace(/\s/g, "_");
  }, []);

  // Use useCallback to memoize the downloadGoogleSheet function
  const downloadGoogleSheet = useCallback(() => {
    if (selectedFile && selectedFile.url) {
      saveAs(selectedFile.url, cleanFileName(selectedFile.fileName));
    }
  }, [selectedFile, cleanFileName]);

  const hasAccess = DownloadAccessChecker("report");

  const excelUI = encodeURIComponent(selectedFile?.url || "");
  const iframeUrl = `https://view.officeapps.live.com/op/view.aspx?src=${excelUI}&wdOrigin=BROWSELINK`;

  return (
    <div className="datasheet-container">
      <div className="excel-container">
        <div
          className={`rect-hide ${
            reportExcel.length === 1 ? "single-file" : ""
          }`}
        >
          <div className="title-download-container">
            <div className="rect-title">{selectedFile.title}</div>
          </div>
          {hasAccess && (
            <Button
              variant="outlined"
              startIcon={<FaFileExcel />}
              onClick={() => { 
                logFileDownloadClick({ fileName: selectedFile.title, fileType: "excel" });
                downloadGoogleSheet(); 
              }}
              sx={{
                height: "40px",
                padding: "8px 24px",
                fontWeight: "500",
                borderRadius: "8px",
                position: "absolute",
                top: "15.5%",
                left: "20px",
                textTransform: "capitalize",
                borderColor: "#002F75",
                border: "0.8px solid",
                "&:hover": {
                  backgroundColor: "#446ba6",
                  color: "#fff",
                },
              }}
            >
              Download Sheet
            </Button>
          )}
          {reportExcel.length > 1 && (
            <FormControl
              variant="outlined"
              className="file-dropdown"
              sx={{
                minWidth: 100,
                position: "absolute",
                top: "15.5%",
                right: "20px",
                marginRight: 0,
                color: "#189cde",
              }}
            >
              <InputLabel id="file-select-label">Select File</InputLabel>
              <Select
                labelId="file-select-label"
                value={selectedFile.file_name}
                onChange={handleFileChange}
                label="Select File"
              >
                {reportExcel.map((file) => (
                  <MenuItem key={file.file_name} value={file.file_name}>
                    {file.fileName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <iframe
          ref={iframeRef}
          src={iframeUrl}
          title="Excel-Like Viewer"
        ></iframe>
      </div>
    </div>
  );
});

export default DatasheetDetailsTab;
