import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import { Avatar, Box, CircularProgress, TextField } from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import { useRef } from "react";
import API from "../../api/Api";
import { capitalizeFirstLetter } from "../../pages/ReportDetails/ReportBriefDetail";
import { useSearchParams } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontSize: "18px",
        color: "#000",
        fontWeight: "600",
      }}
      {...other}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>{children}</div>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// Main Function

export default function ChatBox({
  open,
  setOpen,
  title,
  loading,
  data,
  report_id,
  getAllMessages,
  setLoading,
  reportLibrary = false,
}) {
  // const [newData, setNewData] = useState(data);

  const [message, setMessage] = useState("");
  const { config } = useAuth();
  const { Base_URL } = API;
  const buttonRef = useRef(null);
  const [searchParams,] = useSearchParams();

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      buttonRef.current.click();
    }
  }
  const handleClose = () => {
    setOpen(false);
  };

  // fetching the data

  function sendMessage() {
    setLoading(true);
    const bodyParameters = {
      reportId: report_id,
      message: message,
      report_library: (searchParams.get("tab") && searchParams.get("tab") === "REPORTS_LIBRARY") || reportLibrary,
      url: window.location.href,
    };

    axios
      .post(`${Base_URL}/api/hubs/chat`, bodyParameters, config)
      .then((res) => {
        getAllMessages();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const submitMessage = () => {
    sendMessage();
    getAllMessages();
    setMessage("");
  };

  function MakeDate(myDate) {
    // const time = new Date(myDate).toLocaleTimeString("en", {
    //   timeStyle: "short",
    //   hour12: false,
    //   timeZone: "UTC",
    // });
    return new Date(myDate).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });
    // return b;
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-end",
          height: "100vh",
          width: "100vw",
          margin: "0",
          "& .MuiDialog-paper": {
            margin: "0",
            height: "100vh",
            minHeight: "100vh",
            width: "460px",
          },
        }}
      >
        <BootstrapDialogTitle
          sx={{
            maxWidth: "510px",
            fontSize: "16px",
            borderBottom: "1px solid #E4E4E4",
            fontFamily: "Avenir Roman",
            fontWeight: "600",
          }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {capitalizeFirstLetter(title)}
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            minHeight: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          {loading ? (
            <Box
              flex={5}
              sx={{
                // width: "450px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            data?.map((item) => (
              <Box
                key={item.id}
                width="100%"
                display="flex"
                gap={2}
                alignItems="center"
              >
                {item.sender !== "User" ? (
                  <>
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>
                      {item.name.charAt(0)}
                    </Avatar>
                    <Box flex={1} sx={{ py: 2 }}>
                      <Typography
                        sx={{
                          color: "#a9a9a9",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {MakeDate(item.time)}
                      </Typography>
                      <Typography
                        sx={{
                          background: "#ff57224a",
                          borderRadius: " 2px 25px 25px 25px ",
                          padding: "12px 18px",
                          display: "inline-block",
                        }}
                      >
                        {item.chat_message}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box flex={1} textAlign="end" sx={{ py: 2 }}>
                      <Typography
                        sx={{
                          background: "#189CDE",
                          borderRadius: "8px 0px 8px 8px",
                          padding: "12px 18px",
                          display: "inline-block",
                          color: "white",
                          fontFamily: "Avenir Roman",
                        }}
                      >
                        {item.chat_message}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#a9a9a9",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {MakeDate(item.time)}
                      </Typography>
                    </Box>
                    <Avatar sx={{ bgcolor: deepPurple[500] }}>
                      {item.name.charAt(0)}
                    </Avatar>
                  </>
                )}
              </Box>
            ))
          )}
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            width="100%"
            gap={2}
            style={{ borderTop: "1px solid #E4E4E4", paddingTop: "10px" }}
          >
            <Button
              disabled={message.trim().length > 0 ? false : true}
              ref={buttonRef}
              onClick={submitMessage}
              endIcon={<SendIcon />}
            ></Button>
            <TextField
              sx={{
                flex: "1",
                borderRadius: "4px",
                backgroundColor: "#F5F5F5",
              }}
              label="Enter Message Here"
              size="small"
              value={message}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />

          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
