import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  Popover,
} from "@mui/material";
import { Modal, Button as ButtonX } from "antd";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import React, { useState } from "react";
import ChatBox from "../Common/ChatBox";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { BookmarkBorder } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import RenewDrawer from "../Common/RenewDrawer";
import API from "../../api/Api";
import DownloadAccessChecker from "../Common/DownloadOptionCheck/DownloadAccessChecker";
import { SET_LIBRARY_CREDITS } from "../Redux/action";
import { authenticateProdgain } from "../../utils/common";
import { logFileDownloadClick } from "../../utils/ga";

const SynapseReportsCard = ({
  currentTab,
  report_id,
  title,
  favourite,
  subscribed,
  slug,
  getReportData,
  subscribed_on,
  report_expiry,
  report_url,
  forecast_year,
  published,
  is_requested_library_report = false,
  custom,
  page_number,
  alias_accessed_report = null,
  delivery_format,
  regions = [],
  countries = [],
  canPurchase = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setPdfLoading] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [requestStatus, setRequestStatus] = useState("uninitialise");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { config, setNotify, setCurrentTab } = useAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { remaining_library_credit } = useSelector((state) => state);

  const { Base_URL } = API;

  const currentDate = new Date();
  const upcoming30Days = new Date();
  upcoming30Days.setDate(currentDate.getDate() + 30);

  const isExpiringWithin30Days =
    new Date(report_expiry) > currentDate &&
    new Date(report_expiry) <= upcoming30Days;

  const hasAccess =
    DownloadAccessChecker("report", report_id) && subscribed === "yes";

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRequestStatus("uninitialise");
    setRequestMessage("");
  };

  async function getAllMessages() {
    setLoading(true);
    setMessageData([]);

    axios
      .get(`${Base_URL}/api/hubs/showChat?reportId=${report_id}`, config)
      .then((res) => {
        if (typeof res.data.data === "object") {
          setMessageData(res.data?.data);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // Send messages from here

  async function sendMessage(msg) {
    const bodyParameters = {
      message: msg,
      reportId: report_id,
      url: window.location.href,
    };

    axios
      .post(`${Base_URL}/api/hubs/chat`, bodyParameters, config)
      .then((res) => {
        getAllMessages();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
    getAllMessages();
  };

  async function forceDownload({ report_url }, pdf_name) {
    const urlToDownload = report_url;
    setPdfLoading(true);
    setNotify({
      isOpen: true,
      message: "Download in Progress",
      type: "success",
      position: "right",
    });
    return new Promise(function (resolve, reject) {
      var x = new XMLHttpRequest();
      x.open("GET", urlToDownload, true);
      x.responseType = "blob";
      x.onload = function (e) {
        resolve(x.response);
      };
      x.onerror = function () {
        reject(new Error("Error occurred while downloading PDF."));
      };
      x.send();
    })
      .then(async function (pdf_blob) {
        saveAs(pdf_blob, pdf_name, "application/pdf");
        setNotify({
          isOpen: true,
          message: "Download Completed",
          type: "success",
          position: "right",
        });
        setPdfLoading(false);
      })
      .catch(function (error) {
        setNotify({
          isOpen: true,
          message: "Download Failed",
          type: "error",
          position: "right",
        });

        setPdfLoading(false);
        console.log(error);
      });
  }

  // for bookmark or make favorite your report id
  const handleReportRedirect = (slug, clickedAt) => {
    if (clickedAt === "view_sample") {
      setCurrentTab(0);
    }
    return navigate(`reports-library/${slug}`, {
      state: { cameFrom: currentTab },
    });
  };

  const isDownloadDisabled = (sample_url, subscribed) => {
    if (!sample_url && !report_url) return true;
    else if (subscribed === "yes" && !report_url) return true;
    else if (subscribed === "no" && !sample_url) return true;
    else return false;
  };

  // Function to format date
  function formatDate(stringDate) {
    const date = new Date(stringDate);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();
    return `${day} ${months[monthIndex]}, ${year}`;
  }

  const buyReport = (
    subscribed,
    remaining_library_credit,
    is_requested_library_report,
    report_id
  ) => {
    if (subscribed === "yes" || currentDate < new Date(report_expiry)) {
      return null;
    } else if (remaining_library_credit != 0) {
      return null;
    } else if (is_requested_library_report) {
      return null;
    }

    return (
      <Tooltip title="Buy This Report" placement="top-start">
        <Button
          disabled={canPurchase === false}
          sx={{
            backgroundColor: "#189CDE",
            textTransform: "capitalize",
          }}
          onClick={() => {
            setDrawerOpen(true);
            setTypeModal("buy");
          }}
          variant="contained"
        >
          Buy Now
        </Button>
      </Tooltip>
    );
  };

  const getAccess = (
    subscribed,
    remaining_library_credit,
    is_requested_library_report,
    report_id
  ) => {
    if (subscribed === "yes" || currentDate < new Date(report_expiry)) {
      return null;
    } else if (remaining_library_credit == 0) {
      return null;
    } else if (remaining_library_credit != 0 && !is_requested_library_report) {
      return (
        <Tooltip title="Access This Report" placement="top-start">
          <Button
            disabled={canPurchase === false}
            sx={{
              backgroundColor: "#189CDE",
              textTransform: "capitalize",
            }}
            onClick={() => {
              showModal();
            }}
            variant="contained"
          >
            Get Access
          </Button>
        </Tooltip>
      );
    }
  };

  async function makeThisFavorite(reportId, fav) {
    setLoading(true);
    const bodyParameters = {
      report_id: reportId,
    };
    const url = `${Base_URL}/api/hubs/${
      fav ? "favourite" : "unFavourite"
    }?type=synapse_report`;
    axios
      .post(url, bodyParameters, config)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data?.message,
          type: "success",
          position: "right",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getReportData();
        setLoading(false);
      });
  }

  const sendRequest = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    axios({
      url: `${Base_URL}/api/users/decrease-library-credit`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: report_id,
        message: requestMessage,
      },
    })
      .then(async (res) => {
        setRequestStatus("success");
        dispatch({
          type: SET_LIBRARY_CREDITS,
          payload: {
            total_credit: res.data.data.credit.total,
            remaining_credit: res.data.data.credit.remaining,
          },
        });
        await authenticateProdgain()
        getReportData();
      })
      .catch((error) => {
        setRequestStatus("failure");
        console.error(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  const handleDownloadModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadModalClose = (event) => {
    setAnchorEl(null);
  };

  const Open = Boolean(anchorEl);
  const id = Open ? "popover" : undefined;

  return (
    <>
      <Card
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid transparent",
          marginBottom: "2rem",
          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
          "&:hover": {
            border: "1px solid  #189CDE",
          },
        }}
      >
        <ChatBox
          setOpen={setOpen}
          open={open}
          title={title}
          data={messageData}
          loading={loading}
          report_id={report_id}
          getAllMessages={getAllMessages}
          sendMessage={sendMessage}
          setLoading={setLoading}
          reportLibrary={true}
        />
        <CardContent
          sx={{
            padding: "0px",
            //Remove padding from last child
            "&:last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          <Box
            component="div"
            sx={{ background: "#E0EFFA", padding: "0.3rem" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {favourite === "yes" ? (
                <Tooltip title="Unbookmark" placement="top-start">
                  <BookmarkIcon
                    onClick={() => {
                      makeThisFavorite(report_id, false);
                    }}
                    sx={{
                      marginLeft: "1.2rem",
                      color: "#002F75",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Bookmark" placement="top-start">
                  <BookmarkBorder
                    onClick={() => {
                      makeThisFavorite(report_id, true);
                    }}
                    sx={{
                      marginLeft: "1.2rem",
                      color: "#002F75",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              )}
              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  fontFamily: "Avenir Roman !important",
                }}
                style={{ marginLeft: "0.8rem", marginTop: "0.2rem" }}
                className="title-16"
                onClick={() => {
                  setCurrentTab(0);
                  handleReportRedirect(slug, "title");
                }}
              >
                {title.toUpperCase()}
              </Typography>
            </div>
          </Box>
          <div id="card_body" className="card_body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E4E4E4",
              }}
              id="section-one"
            >
              <div id="section-one_1">
                {forecast_year && !forecast_year.includes("null") && (
                  <div>
                    <span
                      style={{
                        color: "#2D2D2D",
                        fontFamily: "Avenir Heavy",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Forecast Period:
                    </span>{" "}
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {forecast_year}
                    </span>
                  </div>
                )}
                <div>
                  <span
                    style={{
                      color: "#2D2D2D",
                      fontFamily: "Avenir Heavy",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Format:
                  </span>{" "}
                  {/* {forecast_year && ( */}
                  <span
                    style={{
                      color: "#5A5A5A",
                      fontFamily: "Avenir Roman",
                    }}
                  >
                    {delivery_format}
                  </span>
                </div>
                <div>
                  {published && (
                    <>
                      <span
                        style={{
                          color: "#2D2D2D",
                          fontFamily: "Avenir Heavy",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Published Year:{" "}
                      </span>
                      <span
                        style={{
                          color: "#5A5A5A",
                          fontFamily: "Avenir Roman",
                        }}
                      >
                        {new Date(published).getFullYear() || ""}
                      </span>
                    </>
                  )}
                </div>
                {/* <div>
                  {countries && countries.length > 0 && (
                    <>
                      <span
                        style={{
                          color: "#2D2D2D",
                          fontFamily: "Avenir Heavy",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Countries Covered:{" "}
                      </span>
                      <span
                        style={{
                          color: "#5A5A5A",
                          fontFamily: "Avenir Roman",
                        }}
                      >
                        {countries.join(", ")}
                      </span>
                    </>
                  )}
                </div>
                <div>
                  {regions && regions.length > 0 && (
                  <>
                    <span
                      style={{
                        color: "#2D2D2D",
                        fontFamily: "Avenir Heavy",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                     Regions:{" "}
                    </span>
                    <span
                      style={{
                        color: "#5A5A5A",
                        fontFamily: "Avenir Roman",
                      }}
                    >
                      {regions.join(", ")}
                    </span>
                  </>
                  )}
                </div> */}
                <div>
                  {page_number ? (
                    <>
                      <span
                        style={{
                          color: "#2D2D2D",
                          fontFamily: "Avenir Heavy",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Number of Pages:{" "}
                      </span>
                      <span
                        style={{
                          color: "#5A5A5A",
                          fontFamily: "Avenir Roman",
                        }}
                      >
                        {page_number}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0rem 2.6rem",
            }}
            id="section-two"
          >
            {/* <div id="section-two-text"> */}
            <div style={{ paddingTop: "0.8rem" }}>
              {subscribed === "yes" || report_expiry ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>{`Purchased On: ${formatDate(subscribed_on)}`}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "0.2rem",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "4px",
                        borderRadius: "50%",
                        backgroundColor: "#5A5A5A",
                        margin: "0px 0.5rem",
                      }}
                    ></div>
                  </div>
                  <div>{`Renewal On: ${formatDate(report_expiry)}`}</div>
                  {alias_accessed_report && (
                    <div>
                      <Chip
                        label={`Purchased by Alias: ${alias_accessed_report.alias_name}`}
                        sx={{
                          backgroundColor: "#FFEDC7",
                          color: "#A94D19",
                          fontFamily: "Avenir Roman",
                          height: "26px",
                          marginLeft: "1rem",
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
                // <strong>* Instant access</strong>
              )}
            </div>

            <div id="section-two-button">
              {/* Add Icon Button */}
              {subscribed === "yes" && (
                <Tooltip
                  title={
                    subscribed === "no" || currentDate > new Date(report_expiry)
                      ? "Show Sample Report"
                      : "Show Report"
                  }
                  placement="top-start"
                >
                  <IconButton
                    sx={{
                      //onHover showing pointer
                      cursor: "pointer",
                    }}
                    aria-label="delete"
                    size="large"
                    onClick={() => {
                      setCurrentTab(2);
                      handleReportRedirect(slug, "eye-icon");
                    }}
                  >
                    <RemoveRedEyeIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}

              {hasAccess && (
                <div style={{ display: "inline-block" }}>
                  <Tooltip title={"Download Report"} placement="top-start">
                    <IconButton
                      onClick={(e) => {
                        handleDownloadModalOpen(e);
                        logFileDownloadClick({ fileName: title });
                      }}
                      size="large"
                      // disabled={isDownloadDisabled(
                      //   sample_info.sample_url,
                      //   subscribed
                      // )}
                    >
                      <DownloadIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    id={id}
                    open={Open}
                    anchorEl={anchorEl}
                    onClose={handleDownloadModalClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {!custom?.report_pdfs?.length ? (
                      <p style={{ padding: "10px" }}>No reports present</p>
                    ) : (
                      custom?.report_pdfs.map((pdf) => {
                        let pdfUrl = pdf?.url;
                        let pdfTitle = pdf.file_name || pdf.fileName;
                        return (
                          <p>
                            <Button
                              sx={{ width: "100%" }}
                              onClick={() => {
                                forceDownload({ report_url: pdfUrl }, pdfTitle);
                              }}
                              disabled={false}
                            >
                              {pdfTitle}
                            </Button>
                          </p>
                        );
                      })
                    )}
                  </Popover>
                </div>
              )}

              <Tooltip title="Ask Query" placement="top-start">
                <IconButton
                  onClick={handleClickOpen}
                  aria-label="delete"
                  size="large"
                >
                  <ChatBubbleOutlineIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

              {buyReport(
                subscribed,
                remaining_library_credit,
                is_requested_library_report,
                report_id
              )}
              {getAccess(
                subscribed,
                remaining_library_credit,
                is_requested_library_report,
                report_id
              )}
              {subscribed !== "no" && isExpiringWithin30Days && (
                <Tooltip title="Renew This Report" placement="top-start">
                  <Button
                    disabled={canPurchase === false}
                    sx={{
                      backgroundColor: "#189CDE",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (remaining_library_credit > 0) {
                        showModal();
                      } else {
                        setDrawerOpen(true);
                        setTypeModal("renewal");
                      }
                    }}
                  >
                    Renew
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          {/* </div> */}
        </CardContent>
      </Card>

      <RenewDrawer
        report_id={report_id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={typeModal}
      />

      <Modal
        title={`${title.toUpperCase()}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"800px"}
        styles={{
          width: "800px",
        }}
        footer={null}
      >
        <div className="request-modal">
          {requestStatus === "success" && (
            <div>
              <h1
                style={{
                  color: "#1BB1F0",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                Report Unlocked!
              </h1>
              <p className="description">Close this window to start reading.</p>
              <p className="remaining-credits">
                Remaining Library Credits :{" "}
                <span> {`${remaining_library_credit}`} </span>
              </p>
            </div>
          )}

          {requestStatus === "uninitialise" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                Viewing this report will cost 1 credit.
              </h3>
              <p
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                {" "}
                Current Library Credits :
                <span style={{ color: "#1BB1F0" }}>
                  {remaining_library_credit}
                </span>
              </p>

              <ButtonX onClick={sendRequest} disabled={isButtonDisabled}>
                Get Access
              </ButtonX>
            </div>
          )}

          {requestStatus === "failure" && (
            <div>
              <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                Something Went Wrong!
              </h1>
              <p className="description">Please try in some time.</p>
              <p className="remaining-credits">Your credits do not decrease.</p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SynapseReportsCard;
